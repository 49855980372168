.profile {
  .h1 {
    font-weight: bold !important;
    font-size: 30px !important;
    line-height: 35px !important;
    color: @text-color  !important;
    margin-bottom: 32px !important;
  }

  &__wrapper {display: flex;}

  &__content {width: 100%;}

  &__content-item {
    display: none;
    width: 100%;

    &:first-child {max-width: 552px;}
    &:last-child {max-width: 840px;}
    &.content-active {display: block;}
  }

  .card {
    box-shadow: none;
    &:hover {box-shadow: none;}
  }

  .quick-view-land__nav-pagination-list-item-count {margin-bottom: 0;}

  .quick-view-land__nav-pagination-list-item-count {
    color: @text-color-grey;
    height: 18px;
  }

  .quick-view-land__nav {border-bottom: 1px solid @pagination-bullets;}

  .popup__content-left,
  .popup__content-right {
    width: auto;
  }
}
