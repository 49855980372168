.content-tabs {
  &__content {
    padding-top: 30px
  }


  &__header {
    background-color: @bg-white;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  }

  &__image {
    height: 352px;
    background-color: transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
  }

  &__image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 790px;
    padding: 0px 20px;
    margin: 0 auto;
    height: 100%;
    color: @bg-white;
  }

  &__image-title {
    font-weight: bold;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 9px;
  }

  &__image-des {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  &__table {
    max-width: 784px;
    padding: 0px 20px;
    margin: 0 auto;
    padding-top: 12px;
    padding-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
  }

  &__table-item {
    display: flex;
    width: 48%;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #f6f6f6;
    &:nth-child(even) {
      margin-left: 12px;
    }
    &:nth-child(odd) {
      margin-right: 12px;
    }
  }

  &__table-title {
    font-size: 14px;
    color: @date-color;
    width: 29%;
    margin-bottom: 0;
    line-height: 14px;
  }

  &__table-des {
    font-size: 14px;
    color: @text-color-grey;
    width: 71%;
    margin-bottom: 0;
    line-height: 14px;
  }
  &__desc {
    margin-top: 24px;
    padding-top: 29px;
    padding-bottom: 32px;
    margin-bottom: 0px;
    background-color: @bg-white;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  }
  &__desc-container {
    max-width: 784px;
    padding: 0px 20px;
    margin: 0 auto;
  }
  &__desc-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: @text-color-grey;
    padding-bottom: 18px;
    margin-bottom: 0;
  }
  &__text {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    color: @text-color-grey;
  }
}
