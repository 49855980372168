.vue-filter {
  &__datepicker {
    &.no-select {
      margin-top: 28px;
      display: block !important;
      .vue-filter {
        &__datepicker-wrapper {
          height: auto !important;
          overflow: hidden !important;
        }
      }
    }
  }

  &__datepicker-wrapper {
    overflow: auto;
    margin-right: 4px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: @bg-white;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: @bg-scroll;
      border-radius: 4px;
      outline: none;
    }
  }

  &__datepicker-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
  }

  &__datepicker-input {
    text-align: center;
    width: 84px;
    border: none;
    outline: none;
    font-weight: @font-normal;
    font-size: 13px;
    line-height: 16px;
    color: @text-color-grey;
    position: relative;
    padding-bottom: 12px;
    border-bottom: 1px solid @border-color-new;

    &:placeholder {
      color: @border-color;
    }

    &:focus {
      border-bottom: 1px solid @accent-color;
    }
  }

  &__datepicker-separator {
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
    color: @text-color-grey;
  }

  &__datepicker-calendar {
    font-size: 14px !important;
    line-height: 16px !important;

    .PAheader {
      height: 32px !important;
    }

    .PAHprev,
    .PAHnext {
      position: relative;
      font-size: 0;
      border-radius: 50% !important;

      &:hover {
        color: @accent-color  !important;
        background: @bg-light-yellow  !important;
      }

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        width: 6px;
        height: 8px;
        background-color: @text-color-grey;
        border-radius: 0.5px;
        transform: matrix(0, -1, -1, 0, 0, 0);
        clip-path: polygon(0 0, 0% 100%, 100% 50%);
      }
    }

    .PAHnext {
      &:before {
        transform: rotate(0deg) translate(-50%, -45%);
      }
    }

    .PAHprev {
      &:before {
        transform: rotate(-180deg) translate(50%, 45%);
      }
    }

    .PAHmonth {
      font-weight: @font-bold  !important;
      color: @text-color-grey  !important;
    }

    .PAfrom {
      span {
        font-weight: @font-normal  !important;
        color: @border-color  !important;
        border-radius: 50% !important;
        width: 32px !important;
        height: 32px !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .PAactive {
        color: @text-color-grey  !important;

        &.PAselected,
        &.PAbetween,
        &:hover {
          color: @accent-color  !important;
          background: @bg-light-yellow  !important;
        }
      }
    }

    .PAweek {
      span {
        font-weight: @font-medium  !important;
        color: @date-color  !important;
      }
    }

  }
}
