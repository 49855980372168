.user {
  &__popup {
    position: absolute;
    top: calc(100% + 10px);
    right: 50%;
    width: 280px;
    transform: translateX(50%);
    z-index: 10;
    display: none;

    &.authorized {
      width: 151px;

      .user__popup-wrapper {
        padding: 16px;
      }
    }

    &.open-user-popup {
      display: block;
    }
  }

  &__popup-wrapper {
    width: 100%;
    background: @bg-white;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14),
      0px 3px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 22px 24px;
  }

  &__form-item {
    &_input {
      margin-bottom: 22px;

      &:last-child {
        margin-bottom: 12px;
      }
    }

    &_recover {
      margin-bottom: 17px;
    }

    &_checkbox {
      display: inline-flex;
      flex-direction: column;
    }

    &_repeatedly {
      .user__form-item-label {
        cursor: pointer;

        &:hover {
          .user__form-item-input {
            border-color: @accent-color !important;
          }
        }
      }

      .user__form-item-title {
        color: @text-color-grey !important;
      }

      .user__form-item-input {
        border-color: @date-color !important;
        cursor: pointer;
      }
    }
  }

  &__popup-registration,
  &__popup-recover,
  &__popup-login,
  &__popup-check-email,
  &__popup-repeatedly,
  &__popup-email-send {
    display: none;
    &._active {
      display: block;
    }
  }

  &__form-item-title {
    font-size: 12px;
    line-height: 100%;
    font-weight: 400;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    order: 1;
    color: @date-color;
  }

  &__form-item-title-name {
    margin-bottom: 0;
  }

  &__form-item-title-error,
  &__form-item-title-format,
  &__form-item-title-response {
    color: @font-error;
    margin-bottom: 0;
    display: none;
  }

  &__form-item-input {
    background: @bg-white;
    border: 1px solid @border-color-new;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    padding: 12px 14px 11px;
    outline: none;
    font-size: 14px;
    line-height: 100%;
    font-weight: 400;
    color: @text-color-grey;
    order: 2;

    &:focus,
    &:valid {
      border: 1px solid @text-color-grey;
    }
  }

  &__btn-show-password {
    cursor: pointer;
    position: absolute;
    right: 12px;
    top: 28px;
    height: 24px;
    width: 24px;
    display: none;
  }

  &__form-item-label {
    width: 100%;
    margin-bottom: 0;

    input {
      &:valid,
      &:focus {
        & + .user__form-item-title {
          color: @text-color-grey !important;
        }
      }

      &:valid {
        & + .user__form-item-title {
          .user__btn-show-password {
            display: block !important;
          }
        }
      }
    }

    &_input {
      display: flex;
      flex-direction: column;
      position: relative;

      .icon-close-eye-password,
      .icon-eye-password {
        width: 24px;
        height: 24px;
      }

      .icon-close-eye-password {
        display: none;
      }

      &.error {
        .user__form-item-title-error {
          display: block;
        }
        .user__form-item-input {
          border: 1px solid @font-error;
        }
      }

      &.format-error {
        .user__form-item-title-format {
          display: block;
        }
        .user__form-item-input {
          border: 1px solid @font-error;
        }
      }

      &.response {
        .user__form-item-title-response {
          display: block;
        }
        .user__form-item-input {
          border: 1px solid @font-error;
        }
      }

      &.success {
        .user__form-item-input {
          border: 1px solid @accent-color;
        }
      }
    }

    &_checkbox {
      position: relative;
      padding-left: 26px;
      font-size: 12px;
      line-height: 14px;
      font-weight: 400;
      color: @text-color-grey;
      height: 16px;
      display: inline-flex;
      align-items: center;
      cursor: pointer;

      input {
        visibility: hidden;
        width: 0;
        height: 0;
        opacity: 0;
        overflow: hidden;

        &:checked + .user__form-item-checkbox {
          border: 2px solid @accent-color;
          background-color: @accent-color;

          &:after,
          &:before {
            display: block;
          }
        }
      }
    }
  }

  &__form-item-checkbox {
    position: absolute;
    left: 0;
    top: 0;
    height: 16px;
    width: 16px;
    border: 2px solid @text-color-grey;
    border-radius: 4px;

    &:after {
      content: "";
      position: absolute;
      top: 5px;
      left: 2px;
      width: 10px;
      height: 2px;
      border-radius: 6px;
      background-color: @bg-white;
      transform: rotate(-55deg);
      display: none;
    }

    &:before {
      content: "";
      position: absolute;
      top: 6px;
      left: 0px;
      width: 6px;
      height: 2px;
      border-radius: 2px;
      background-color: @bg-white;
      transform: rotate(55deg);
      display: none;
    }
  }

  &__form-item-des {
    font-size: 12px;
    line-height: 16px;
    color: @text-color-grey;
    margin-bottom: 20px;
  }

  &__form-item-submite {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: @accent-color;
    border-radius: 4px;
    margin-top: 12px;
    outline: none;
    border: none;

    &.load {
      .icon-loading {
        display: block;
      }
      .user__form-item-submite-text {
        display: none;
      }
    }

    &_repeatedly {
      background: @bg-white;
      border: 1px solid @accent-color;

      p {
        color: @accent-color;
      }

      &.loaded {
        background: @accent-color;
        p {
          color: @color-white;
        }
      }
    }
  }

  &__form-item-submite-text {
    margin-bottom: 0;
    letter-spacing: 0.0025em;
    color: @bg-white;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
  }

  .user__popup-bottom {
    margin-top: 24px;
    padding-top: 22px;
    border-top: 1px solid @border-color-new;
  }

  .user__popup-bottom-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.0025em;
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .user__form-item-label-link {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: @accent-color;
    margin-left: 26px;
    margin-top: 1px;
  }

  .user__popup-bottom-title {
    margin-bottom: 0;
    color: @date-color;
  }

  .user__popup-bottom-link {
    color: @accent-color;
    margin-bottom: 0;
    cursor: pointer;
  }

  &__check-email-wrapper {
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.0025em;
    color: @text-color-grey;
  }

  &__check-email-title {
    margin-bottom: 5px;
    font-weight: bold;
    letter-spacing: 0.0025em;
  }

  &__check-email-text {
    margin-bottom: 0;

    &_email {
      margin: 5px 0px;
      color: @accent-color;
    }
  }

  &__check-email-link {
    display: block;
    margin-top: 20px;
    color: @accent-color;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.0025em;
    cursor: pointer;
  }

  &__repeatedly-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.0025em;
    color: @text-color-grey;
    margin-bottom: 24px;
  }

  &__repeatedly-edit {
    position: absolute;
    top: 28px;
    right: 12px;

    .icon-new-edit {
      width: 24px;
      height: 24px;
    }
  }

  &__recover-des {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 16px;
    color: @text-color-grey;
  }

  .user__email-send-wrapper {
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.0025em;
  }

  .user__email-send-title {
    margin-bottom: 29px;
    color: @text-color-grey;
  }

  .user__email-send-span {
    color: @accent-color;
  }

  .user__popup-authorized-item {
    padding-bottom: 16px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .user__popup-authorized-link {
    display: inline-flex;
    align-items: center;
    color: @text-color-grey;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;

    svg {
      fill: @text-color-grey;
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    &:hover {
      color: @accent-color;

      svg {
        fill: @accent-color;
      }
    }
  }

  .user__popup-authorized-title {
    margin-bottom: 0;
  }
}

.icon-loading {
  width: 24px;
  height: 24px;
  animation: circle 1s linear infinite;
  display: none;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.user-register-confirmed {
	&._active {
		.user-register-confirmed__overlay {
			display: block;
		}
		.user-register-confirmed__popup {
			display: block;
		}
	}
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    display: none;
  }
  &__popup {
		display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 400px;
    background-color: @bg-white;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14),
      0px 3px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 22px 24px;
    z-index: 12;
    color: @text-color;
  }
  &__popup-close {
    cursor: pointer;
    margin: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: @bg-accent;
    border-radius: 4px;
    margin-top: 12px;
    outline: none;
    border: none;
    color: @color-white;
    letter-spacing: 0.0025em;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
  }
  &__popup-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
  }
  &__popup-description {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
  }
}
