.new-filter-search {
	.checkbox {
    font-size: 14px;
    line-height: 16px;
    height: 24px;
    padding-left: 38px;
    display: flex;
    flex-direction: row;
    align-items: center;

    .checkmark {
      width: 18px;
      height: 18px;
      left: 12px;
      border-radius: 2px;
      border: 2px solid #616161;
      top: 50%;
      transform: translateY(-50%)
    }
  }
}