.document-gallery {
  &__left-panel {
    position: absolute;
    top: 0;
    left: -228px;
    width: 228px;
    background: @addable-menu-link;
    box-shadow: 4px 0px 12px rgba(238, 238, 238, 0.25);
    transition: 0.3s ease;

    &.show-panel {
      left: 0;
    }
  }

  &__left-panel-wrapper {
    padding-right: 4px;
  }

  &__left-panel-count {
    padding-top: 32px;
  }

  &__left-panel-count-number {
    font-size: 13px;
    line-height: 16px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #616161;
  }

  &__left-panel-count-span {
    margin: 0px 4px;
  }

  &__left-panel-container {
    height: calc(100vh - 74px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #FFFFFF;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #E5E5E5;
      border-radius: 4px;
      outline: none;
    }

  }

  &__left-panel-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;


  }

  &__left-panel-list-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 16px;
    cursor: pointer;

    &.active {
      .document-gallery__left-panel-list-img {
        border: 2px solid @accent-color;
      }
    }

    &.book {
      display: block;
      width: 82px;
      height: 146px;

      &:first-child {
        margin-left: 82px;
      }

      &:last-child {
        &:nth-child(2n) {
          margin-right: 82px;
        }
      }

      .document-gallery__left-panel-list-img {
        width: 82px;
        height: 120px;
      }

      .document-gallery__left-panel-list-img-number {
        max-width: 82px;
      }
    }

    &.delete-margin {
      &:first-child {
        margin-left: 0px;
      }

      &:last-child {
        &:nth-child(2n) {
          margin-right: 0px;
        }
      }
    }
  }

  &__left-panel-list-img {
    width: 164px;
    height: 240px;
    object-fit: cover;
  }

  &__left-panel-list-img-number {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    max-width: 164px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: @accent-color;
  }

  &__left-panel-button {
    position: absolute;
    top: calc(50% - 18px);
    right: -24px;
    background: #FFFFFF;
    width: 24px;
    height: 36px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 0px 4px 4px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

}
