.content-tabs {
  &__menu-link-default {
    color: #616161;
    cursor: pointer;
    margin-bottom: 0;

    &:hover {
      color: @accent-color;

      .content-tabs__menu-link-icon-defaul,
      .content-tabs__menu-link-icon-path {
        fill: @accent-color  !important;
      }

      .content-tabs__menu-link-icon-line {
        stroke: @accent-color  !important;
      }
      .content-tabs__menu-link-icon-img {
        display: none;
      }
      .content-tabs__menu-link-icon-img-fill {
        display: block;
      }
    }

    &.disabled {
      color: @date-color !important;
      cursor: default !important;
      .content-tabs__menu-link-icon-defaul,
      .content-tabs__menu-link-icon-path {
        fill: @date-color  !important;
      }

      .content-tabs__menu-link-icon-line {
        stroke: @date-color  !important;
      }
    }

    &.active {
      color: @accent-color;
      z-index: 10;
      pointer-events: none;

      .content-tabs__menu-link-icon-defaul,
      .content-tabs__menu-link-icon-path {
        fill: @accent-color  !important;
      }

      .content-tabs__menu-link-icon-line {
        stroke: @accent-color  !important;
      }

      .content-tabs__menu-link-icon-img {
        display: none;
      }
      .content-tabs__menu-link-icon-img-fill {
        display: block;
      }

    }

		&.content-tabs__show-hint {
			&:hover {
				.content-tabs__menu-link-icon-defaul,
				.content-tabs__menu-link-icon-path {
					fill: @text-color-grey  !important;
				}
			}
		}
  }

  &__menu-link-icon-defaul {
    width: 24px;
    height: 24px;
    fill: @text-color-grey;
  }

  &__menu-link-span-default {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    margin-left: 8px;
    margin-right: 5px;
  }
}
