.vue-filter {
	&__item {
		background-color: @bg-white;

		&.border {
			border-bottom: 1px solid @border-color-new;
		}

		&.active {
			.vue-filter__item-title {
				color: @accent-color;
			}
			.vue-filter__item-arrow {
				fill: @accent-color;
			}
		}

		&:last-child {
			border-radius: 0px 0px 4px 4px;
		}
	}

	&__item-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 8px 12px 8px 24px;
		cursor: pointer;
		position: relative;
		&.tags {
			padding: 8px 12px 8px 24px;
			//.vue-filter__item-count {
			//	right: 22px;
			//}
		}
		&._disabled {
			cursor: default;
			.vue-filter__item-title {
				color: @date-color;
			}
			.vue-filter__item-title {
				color: @date-color;
			}
			.vue-filter__item-arrow {
				fill: @date-color;
			}
		}
		&.border {
			border-top: 1px solid @line;
		}
	}

	&__item-title,
	&__item-count {
		font-weight: @font-normal;
		font-size: 13px;
		line-height: 20px;
		color: @text-color-grey;
		margin-bottom: 0;
	}

	&__item-count {
		font-weight: @font-bold;
		color: @accent-color;
		position: absolute;
		right: 28px;
		width: 32px;
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__item-arrow-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: @default-width;
		height: @default-height;
	}

	&__item-arrow {
		width: 10px;
		height: 6px;
		fill: @text-color-grey;
		transform: rotate(-90deg);
	}

}
