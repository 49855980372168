.interviews {
	padding-bottom: 100px;
}

.interview-map {
  &.page__map-container {
    .container {
      padding: 0px !important;
      max-width: 1128px;
      margin: 0 auto;
    }
  }
}

.interview-view {
	.new-super-filter__container {
		position: sticky;
  	top: 78px;
	}

  .make-starred {
    padding-right: 12px;

    &__v {
      &~svg {color: @bg-white  !important;}

      &:hover {
        &~svg {
          stroke: @accent-color;
          stroke-width: 1.5;
        }
      }

    }
  }
	.page__content--interview {
		padding-top: 0;
	}
	.new-filter-search {
		position: sticky;
    top: 78px;
		padding-top: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
	}
}

.interview {
  width: 100%;
  padding-bottom: 100px;

  .new-table {
    &__container {
      max-width: 918px;
      overflow: visible;
			height: 0%;
    }
    .table__thead {
      a {
        color: @text-color-grey !important;
				font-size: 13px;
      }
    }
  }

  .grid-view {width: 100%;}

  .pagination__item {
    .new-angle-left,
    .new-angle-right {
      width: 7px;
      height: 11px;
    }

    &:first-child,
    &:last-child {
      box-shadow: none !important;
      width: 24px !important;
      height: 24px !important;
    }
  }

  .map {
    padding-top: 0;

    &__header {display: none;}
  }

  .filter-search__input-wrapper {
    padding: 0 24px !important;
    max-width: 918px;
    margin: 0 auto;

    input {padding-left: 16px;}

    .filter-search__submit {
      right: 24px;
      padding-right: 12px;
    }
  }

  .cemetery-tabs {
    &__header {
			padding-bottom: 24px;
			z-index: 11;
		}
  }

  .slider__inner {
    border-top: none;
    border-bottom: none;
  }

  .page__audio-player {
    width: 100%;
    z-index: 10;
    background-color: #ffffff;
  }

  .page__sidebar {max-width: 192px;}

  .page__content-row {display: none;}

  .page__content-row--spec {display: none;}

  .h3 {display: none !important;}

  .page__content-timeline-keywords {display: none;}

  .jump {
    height: 24px;
    display: flex;

    &:hover {
      .jump__link-speaker {display: block;}
      &__link-speaker_disabled {display:block;}
      .jump__link {display: none;}
    }

    &__link-speaker {
      cursor: pointer;
      display: none;
      width: 24px;
      height: 24px;
      fill: @accent-color;
    }

    &__link-speaker_disabled {
      cursor: pointer;
      display: none;
      width: 24px;
      height: 24px;
      fill: #A7A7A7;
    }

    &__current-speaker {
      width: 24px;
      height: 24px;
      fill: @accent-color;
    }

    &__container {
      display: flex;
      padding: 0 32px;

      &.find-result {
        .jump__link {
					color: @accent-color;
				}
      }

      &._active {
				background: #FEFCF8;
			}

      .page__item {
        position: relative;
        margin-top: 0;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #363636;
        display: flex;
        margin-bottom: 10px;

        &.current-speaker {
          .jump__link-speaker {
            display: block;
          }

          .jump__link {
            display: none;
          }
        }
      }
    }

    &__text-translate {
      padding-left: 24px;
      margin-top: 0;
    }

    &__link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: @date-color;
      min-width: 24px;
      max-width: 24px;

      margin-right: 18px;

      &:first-child {
        margin-right: 0;
        width: auto;
      }
    }
  }

  .audo-translate-pagination {

    &__wrapper {
      display: flex;
      justify-content: flex-end;
      padding-right: 32px;
      padding-left: 32px;
      padding-top: 10px;
    }

    &__list {
      padding-left: 0;
      display: flex;
      align-items: center;
      margin-bottom: 0;
      margin-top: 0;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: @text-color-grey;
    }

    &__item {
      list-style-type: none;
      &:first-child {margin-right: 8px;}
      &:last-child {margin-left: 8px;}
    }

    &__arrow {
      cursor: pointer;
      margin-bottom: 0;
      width: 24px;
      height: 24px;
      position: relative;

      &.disabled {
        cursor: default;

        &:before,
        &:after {
          background-color: @date-color;
        }
      }

      &:before,
      &:after {
        content: "";
        position: absolute;
        right: 7px;
        width: 8px;
        height: 2px;
        border-radius: 2px;
        background-color: @text-color-grey;
      }

      &:after {
        top: 9px;
        transform: rotate(45deg);
      }

      &:before {
        top: 13px;
        transform: rotate(-45deg);
      }

      &_prev {transform: rotate(180deg);}
    }

    &__number {
      margin-bottom: 0;
      &_current { margin-right: 2px;}
      &_max {margin-left: 2px;}
    }
  }

  .filter-search__input {
    background: @bg-white;
    border: 1px solid #F6F6F6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0px;

    input {
      font-size: 13px;
      line-height: 16px;

      &::placeholder {color: @date-color;}
    }
  }

  .content-tabs {
    &__info-wrapper {
      height: 94px;
      flex-direction: column;
      flex-wrap: wrap;

      .content-tabs {
        &__info-item {
          padding-top: 15px;
          width: auto;

          &:first-child,
          &:nth-child(2),
          &:nth-child(3) {
            padding-right: 64px;
          }

          &:first-child {padding-top: 0px;}

          &:nth-child(4) {padding-top: 0px;}
        }

        &__info-des {margin-left: 5px;}
      }

    }

    &__informant {
      .content-tabs {
        &__info-title {
          cursor: pointer;
          display: flex;
          font-weight: bold;
          color: @accent-color;

          .new-icon-women {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            fill: @accent-color;
            stroke: @accent-color;
          }
        }

        &__info-des {
          color: @text-color-grey;
          font-weight: normal;
          margin-left: 50px;
          font-size: 14px;
          line-height: 16px;
        }

        &__info-item {
          width: 100%;
          justify-content: space-between;
          margin-bottom: 8px;

          &:last-child {margin-bottom: 0;}
        }
      }
    }
  }

  .make-starred {
    // margin-right: 12px;

    &__control {
      display: flex;
      align-items: center;
      justify-content: center;

    }

    &__v {
      &~svg {
        color: @border-color;
        fill: @border-color;
        stroke: @border-color;
        stroke-width: 1.5;
      }

      &~span {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: @accent-color;
        margin-right: 10px;
        display: none;
      }

      &:hover {
        &~span {display: block;}
      }

      &:checked {
        &~svg {
          stroke: @accent-color;
          fill: @accent-color  !important;
        }
      }
    }
  }
}
.pagination-no-after {
  .pagination {
    &__item {
      &_number {
        cursor: default;
        display: block;
        min-width: 71px;
      }

      &_hide-next,
      &_hide-prev {
        min-width: 71px;
        display: none;

        &.show {display: block;}
      }

      span {color: @text-color-grey;}

      a {
        color: @accent-color;

        &:hover {color: @accent-color;}
      }

      &.active {
        &:after {content: none;}
      }
    }
  }
}
