.special-card {
  padding-bottom: 10px;

  @media (min-width: @tablet) {
    padding-bottom: 40px;
  }

  &__header {
    padding: 10px;
    color: @addable-menu-link;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: @accent-bg;
    border-radius: 4px 4px 0px 0px;
    overflow: hidden;
    position: relative;

    &.bg-center {
      background-position: center;
    }

    &--dark-back div {
      position: relative;
    }

    &--dark-back::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }


    @media (min-width: @tablet) {
      padding: 40px;
    }

    @media (min-width: @desktop) {
      padding: 148px 180px 96px;
    }
  }

  &__name {
    font-size: 30px;
    line-height: 35px;
    letter-spacing: 0.02em;
    margin: 0;
    max-width: 550px;
    text-transform: uppercase;

    &--support {
      font-weight: bold;
      line-height: 28px;
      font-size: 24px;
      text-transform: none;
      margin: 0;
      padding: 0 170px;
    }
  }

  &__description {
    margin: 14px 0 0;
    font-size: 16px;
    line-height: 24px;
    max-width: 550px;
    height: 73px;
    &_min {
      max-width: 492px;
    }
  }

  &__content {
    padding: 22px 10px 0;
    max-width: 764px;
    margin: 0 auto;

    & .special-card__quote-description {
      font-size: 16px;
      line-height: 28px;
    }

  }

  &__content {
    p {
      a {
        text-transform: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
      }
    }
  }

  &__content>p {
    font-size: 16px;
    line-height: 28px;
    margin: 15px 0;
  }

  &__content h3 {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin: 39px 0 13px;
  }

  &__content>ul {
    list-style: none;
    padding: 0;
    margin: 1em 0;

    li {
      font-size: 1.6rem;
      line-height: 2.4rem;
      padding: 0 0 0 20px;
      margin: 0 0 1em;
      position: relative;

      &::before {
        content: '';
        width: 10px;
        height: 2px;
        background-color: @accent-color;
        position: absolute;
        top: 1.2rem;
        left: 0;
        transform: translateY(-50%);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__content>b {
    display: block;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    padding: 10px 10px 11px 27px;
    margin: 15px 0;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background-color: @accent-color;
    }
  }

  &__content a {
    color: @accent-color;
    text-decoration: none;
    transition: @transition-time color;
    height: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    letter-spacing: 0.0025em;
    text-transform: uppercase;
    color: #DBAE63;

    &:hover {
      color: #cf9d30;
    }

    &:active {
      color: #b3882a;
    }
  }

  &__link-wrapper {
    margin: 42px 0 0;
  }

  &__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: @accent-color;
    text-transform: uppercase;
  }

  &__link-icon {
    margin: 0 0 0 5px;
  }
}
