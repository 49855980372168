.copyright {
  grid-area: copyright;
  text-align: right;
  background-color: transparent;
  padding: 14px 0 0;
}

.copyright__text {
  color: @accent-color-dark;
  margin: 0;
  font-size: 1.3rem;
  line-height: 1.8rem;
  float: none;
}
