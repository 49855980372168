.site-navigation {
  margin-right: auto;
}

.site-navigation__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
}

.site-navigation__item {
  margin: 0 32px 0 0;

  &:last-child {
    margin-right: 0;
  }
}

.site-navigation__link {
  color: @accent-color;
  display: block;
  line-height: 24px;
}
