.vue-filter {
  &__dropdown {
    position: absolute;
    background-color: @bg-white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 0px 4px 4px 0px;
    top: 0;
    left: calc(100% + 4px);
    width: 452px;
    //height: 100%;
		height: auto;
    z-index: 11;

    &.daterange {
      width: 248px;
			height: 100%;
    }
  }

	&__dropdown-wrapper {
    //background-color: @bg-white;
    //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    //border-radius: 0px 4px 4px 0px;
    //width: 452px;
    //z-index: 11;
	}

  &__dropdown-list {
    margin-right: 5px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: @bg-white;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: @bg-scroll;
      border-radius: 4px;
      outline: none;
    }

		&.tags {
			height: auto !important;
			max-height: 540px;
		}
  }

  &__dropdown-date {
    margin-bottom: 18px;
  }

  &__dropdown-date-tab {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }

  &__dropdown-date-tab-item {
    margin-bottom: 0;
    cursor: pointer;
    width: 50%;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @bg-light-gray;
    color: @date-color;

    &.active {
      background-color: @bg-white;
      color: @accent-color;
    }
  }
}
