.map-bottom {
  height: 56px;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
	transition: width 0.2s;
  &.show-pdf {
    z-index: 11;
    .map-bottom__wrapper {
      box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.2);
    }
  }
  &.active,
  &.active-second {
		width: calc(100% - 360px);
    //.map-bottom__wrapper {
    //  width: calc(100% - 360px);
    //}
  }
  @media (max-width: @desktop) {
    justify-content: center;
  }
  &__container {
    display: flex;
    align-items: center;
  }
  &__wrapper {
    padding-left: 24px;
    padding-right: 24px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: @bg-white;
    transition: width 0.2s;
		box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.2);
    //@media (max-width: @desktop) {
    //  width: calc(100% - 56px);
    //}
  }
  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__download-svg {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
  &__download-pdf {
    display: flex;
    align-items: center;
  }
  &__download-link {
    display: flex;
    align-items: center;
  }
  &__download-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: @accent-color;
  }
  &__title {
    margin-bottom: 0 !important;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: @text-color-grey;
  }
  &__icon {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    &_img {
      width: 14px;
      height: 16px;
    }
  }
}
