.name-card {
  &__td-cipher {
    margin-bottom: 0;
    color: @date-color;
    font-weight: normal;
    line-height: 20px;
    font-size: 13px;
    text-align: center;
  }

  &__title-name {
    font-weight: bold;
    font-size: 13px !important;
    line-height: 18px !important;
  }

  &__subtitle-name {
    font-size: 14px !important;
    line-height: 16px !important;
    color: @text-color-grey !important;
  }

  &__title .link {
    color: @text-color-grey;

  }

  &__country {
    margin-bottom: 0;
    color: @date-color;
  }

  &__tag {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 0;
    font-weight: normal;
  }
}

.name-card__title {
  font-size: 13px;

  div.name-card--lead &,
  .card .table div.name-card--lead & {
    font-size: 18px;
  }

  .epigraphic & {
    font-size: 13px;
  }

  .card .table & {
    font-size: 14px;
  }
}

.name-card__subtitle {
  &._xl {
    font-size: 16px;

    .name-card--lead &,
    .card .table .name-card--lead & {
      font-size: 18px;
    }
  }

  &._md {
    font-size: 16px;
  }

  .card .table & {
    font-size: 14px;
  }
}

.name-card-fast-see {
  width: 32px;
  height: 100%;
  margin-bottom: 0;
  position: absolute;
  align-items: center;
  left: 100%;
  top: 0;
  justify-content: center;
  background: @bg-white;
  box-shadow: 2px 2px 6px rgba(128, 95, 23, 0.1);
  border-radius: 0px 4px 4px 0px;
  display: none;
  &:hover {
    .content-tabs__link-icon {
      fill: @accent-color !important;
    }
  }
}
