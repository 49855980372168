.title-style {
	[title] {
    position: relative;

  }

  [title]:after {
    content: attr(value);
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    padding: 6px;
    position: absolute;
    left: -9999px;
    opacity: 0;
    bottom: 120%;
    white-space: nowrap;
    transition: 0.1s linear opacity;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
  }

  [title]:hover:after {
    left: 0%;
    opacity: 1;
  }
}