.footer-contacts {
  margin: 10px 0;

  @media (min-width: @tablet) {
    grid-area: contacts;
    padding: 0 0 0 12px;
    margin: 0;
  }
}

.footer-contacts__header {
  padding: 0;
  margin: 0 0 16px;
  font-weight: 500;
  color: @accent-color-dark;
}

.footer-contacts__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-contacts__item {
  margin: 0 0 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.footer-contacts__value {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  font-size: 13px;
  line-height: 14px;
}

.footer-contacts__icon {
  flex-shrink: 0;
  margin: 0 6px 0 0;

  .footer-contacts__item--address & {
    margin-top: 2px;
    margin-right: 9px;
    align-self: flex-start;
  }
}
