.cards {
  margin: 60px 0 0;
}

.cards__wrapper {
  .cards--no-js & {
    width: 100%;
    padding: 0;
  }
}

.cards__header {
  margin: 0 0 15px;

  @media (min-width: @tablet) {
    margin: 0 0 32px;
  }
}

.cards__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  .cards--no-js & {
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: @tablet) {
      width: calc(~"100% + 24px");
      margin: 0 -12px;
    }
  }
}

.cards__item {
  flex-shrink: 0;
  height: 400px;
  max-width: 360px;

  @media (max-width: @desktop) {
    max-width: 305px;
  }

  .cards--no-js & {
    margin: 0 12px 24px;
    width: 100%;

    @media (min-width: @tablet) {
      width: calc(~"50% - 24px");
    }
    @media (min-width: @desktop) {
      width: calc(~"33.333333% - 24px");
    }

  }
}
