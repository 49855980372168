.content-tabs {
  &__content {
    padding-top: 30px
  }

  &__map {
    max-width: 744px;
    margin: 0 auto;
    padding-top: 40px;
  }

  &__map-container {
    position: relative;
    overflow: hidden;
    max-height: 432px;
    max-width: 744px;

    &.full-map {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      z-index: 9999;
      max-height: 100%;
      max-width: 100%;
    }
  }

  &__map-container-item {
    width: calc(100% + 6px);
    height: 438px;
    transform: translate(-2px, -5px);

    div {
      border: none !important;
    }

    &.full-map {
      height: 100%;
    }
  }

  &__header {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  }

  &__image {
    height: 352px;
    background-color: #000000;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 784px;
    padding: 0px 20px;
    margin: 0 auto;
    height: 100%;
    color: #ffffff;
  }

  &__image-title {
    font-weight: bold;
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 9px;
  }

  &__image-des {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  &__table {
    max-width: 784px;
    padding: 0px 20px;
    margin: 0 auto;
    padding-top: 12px;
    padding-bottom: 32px;
    display: flex;
    flex-wrap: wrap;
  }

  &__map-table-container {
    max-width: 744px;
    margin: 0 auto;
    padding-top: 31px;
    padding-bottom: 9px;
  }

  &__map-table-item-title {
    font-size: 16px;
    line-height: 24px;
    color: @date-color;
    padding-right: 32px;
    font-weight: normal;
    padding-bottom: 15px;
    padding-top: 0;
    vertical-align: top;
  }

  &__map-table-item-des {
    display: flex;
    align-items: center;
    padding-bottom: 15px;
    padding-top: 0;
    font-size: 16px;
    line-height: 24px;
    color: @text-color-grey;

    [title] {
      position: relative;
    }

    [title]:after {
      content: attr(value);
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
      border-radius: 4px;
      padding: 6px;
      position: absolute;
      left: -9999px;
      opacity: 0;
      bottom: 120%;
      white-space: nowrap;
      transition: 0.25s linear opacity;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #616161;
    }

    [title]:hover:after {
      left: -200%;
      opacity: 1;
    }

    .icon-jewish-gen {
      width: 19px;
      height: 21px;
      margin-left: 8px;
      margin-right: 11px;
    }
  }

  &__map-table-item-des-title {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: @text-color-grey;
  }

  &__map-table-item-des-link {
    display: flex;
    align-items: center;

  }

  &__map-table-item-cord {
    // border-bottom: 1px dashed @accent-color;
    // padding-bottom: 5px;
    // cursor: pointer;
    // span {
    //   text-transform: uppercase;
    //   color: @date-color;
    // }
  }

  &__table-item {
    display: flex;
    width: 48%;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #d8d8d8;

    &:nth-child(even) {
      margin-left: 12px;
    }

    &:nth-child(odd) {
      margin-right: 12px;
    }
  }

  &__table-title {
    font-size: 14px;
    line-height: 20px;
    color: @date-color;
    width: 29%;
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
  }

  &__table-des {
    font-size: 14px;
    color: #616161;
    width: 71%;
    margin-bottom: 0;
  }

  &__desc {
    margin-top: 24px;
    padding-top: 29px;
    padding-bottom: 32px;
    margin-bottom: 0px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  }

  &__desc-container {
    max-width: 784px;
    padding: 0px 20px;
    margin: 0 auto;
  }

  &__desc-title {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #616161;
    padding-bottom: 18px;
    margin-bottom: 0;
  }

  &__text {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    color: #616161;
  }
}
