.PACalendar {
  .PAHmonth {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: @text-color-grey  !important;
  }

  .PAweek {
    display: flex;
    justify-content: center;

    span {
      width: 32px;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: @date-color  !important;
    }
  }

  .PAfrom {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.filters__date-range-input {
  text-align: center;
  width: 84px;
  height: 29px;
  margin: 0;
  border: none;
  padding-bottom: 12px;
  outline: none;
  border-bottom: 1px solid @border-color-new;
  background-color: @bg-white;
  transition: .12s
}

.filters__date-range {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px
}

.filters__date-range:after {
  content: "";
  position: absolute;
  top: 26px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 2px;
  background-color: @text-color-grey;
}

.filters__date-range input[type=number]::-webkit-inner-spin-button,
.filters__date-range input[type=number]::-webkit-outer-spin-button {
  margin: 0
}


.filters__date-range-input {

  &:focus,
  &:valid {
    border-color: @accent-color;
  }
}



.filters__result--calendar.PACalendar .PAheader .PAHnext,
.filters__result--calendar.PACalendar .PAheader .PAHprev {
  border-radius: 1px;
  height: 6px;
  width: 10px;
  display: inline-block;
  color: transparent;
  user-select: none;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background: @text-color-grey;
}

.filters__result--calendar.PACalendar .PAheader .PAHnext:hover,
.filters__result--calendar.PACalendar .PAheader .PAHprev:hover {
  background: @text-color-grey;
}

.filters__result--calendar.PACalendar .PAheader .PAHprev {
  transform: rotate(275deg)
}

.filters__result--calendar.PACalendar .PAheader .PAHnext {
  transform: rotate(-275deg)
}
