.page__audio-player {
  background: #ffffff;
  box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
  margin-bottom: -100px;
  z-index: 10;
  position: relative;

  button {
    outline: none;
  }

  .audio-player__container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px -4px 15px rgba(0, 0, 0, 0.1);
  }

  &._active-anim {
		z-index: 9;
    .audio-player__container {
      position: static !important;
      box-shadow: none;
    }

    .audio-player {
      padding: 0px;
    }
  }
}

.audio-player {
  z-index: 1;
  position: relative;
  width: 100%;
  user-select: none;
  max-width: 1240px;
  padding: 0 56px;

  &.unwork {
    pointer-events: none;
  }

  &--md {
    width: auto;
    height: 100%;
    margin-right: 16px;
    padding: 8px 4px 4px 16px;
    background: #fff;

    &:after {
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      width: 32px;
      background: linear-gradient(90deg, #fff 0, hsla(0, 0%, 100%, 0));
      content: "";
    }
  }

  &+.audio-player {
    margin-top: 20px
  }

  &--not-logged {
    border: none;
    display: none
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: @broadcrumbs-link;

    &--play {
      width: 7px;
      height: 12px;
      left: 50%;
      fill: #fff
    }
  }

  &__play-btn {
    display: flex;
    align-items: center;

    &__play-btn--disable {
      display: none
    }

    .btn--play-audio {
      display: flex
    }

    .btn--pause-audio {
      padding: 0;
      display: none;

      &:active,
      &:hover {
        color: #cf9d30;
      }

      &:after,
      &:before {
        position: absolute;
        top: 4px;
        content: "";
        width: 2px;
        height: 10px;
        background-color: #fff;
        border-radius: 2px
      }

      &:before {
        left: 11px;
      }

      &:after {
        left: 5px;
      }
    }

    .btn--play-audio,
    .btn--pause-audio {
      min-width: 20px;
      height: 20px;
      background: #231F20;
      border-radius: 50%;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      font-size: 0;
    }
  }

  &__name-track {
    &--disable {
      display: none
    }
  }

  &__inner {
    flex: 0 1;
    flex-basis: calc(100% - 30px);
    padding: 24px 11px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: space-between;

    @media (min-width: 769px) {
      flex-basis: calc(100% - 50px);
    }

    &--not-logged {
      flex: none;
      min-width: 150px;
      padding: 0;
      z-index: 10;
      background-color: #fff
    }

    &-info {
      padding-top: 16px;
      flex: 1 1 100%;
      flex-flow: row wrap;
      justify-content: space-between;
      display: flex;
      align-items: center;
      align-content: flex-start
    }
  }

  &__inner-pagination {
    display: flex;
    align-items: center;
    color: #A39884;
    font-size: 12px;
    line-height: 24px;
    padding-bottom: 10px;

    &-title {
      margin-bottom: 0;
			margin-right: 8px;
    }
  }

	&__inner-pagination-list {
    padding-left: 0;
		margin-bottom: 0;
		display: flex;
		flex-wrap: wrap;
  }

	&__inner-pagination-list-item {
		position: relative;
    list-style-type: none;
		margin-right: 8px;
		&:last-child {
			margin-right: 0;
		}

		&.active {
			.audio-player__inner-pagination-list-item-link {
				background-color: @accent-color;
				color: @color-white;
			}
		}

		&:hover {
			.audio-player__hover {
				display: block;
			}
		}
  }

	&__hover {
		display: none;
		position: absolute;
		top: -200%;
		left: 50%;
		margin-bottom: 0;
		padding: 6px 9px;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
		border-radius: 4px;
		background-color: @bg-white;
		white-space: nowrap;
		transform: translateX(-50%);
	}

	&__inner-pagination-list-item-link {
		cursor: pointer;
		margin-bottom: 0;
		width: 24px;
		height: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		font-size: 12px;
		line-height: 24px;
		&:hover {
			border: 1px solid #A39884;
			box-sizing: border-box;
		}
		&.current-speaker {
			background-color: @accent-color;
			color: @color-white;
		}
	}

  &__timeline {
    flex: 1 1;
    margin: 0px 22px;
  }

  &__info {
    display: flex;
    align-items: center;
    align-content: flex-start;
    flex: 0 1 auto;
    flex-flow: row wrap;
    justify-content: flex-start;
    color: @broadcrumbs-link;
    font-weight: 500;
    line-height: 20px;
    font-size: 14px;
  }

  &__timer {
    margin-right: 10px;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #616161;
  }

  &__volume {
    flex: 0 1 70px;
    font-size: 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding-left: 27px;

    &-control {
      position: relative;
      cursor: pointer;
      flex: 0 1 50px;
      z-index: 2
    }
  }

  &__toggle-volume {
    flex: 0 1 auto;
    position: relative;

    &--none {
      &:before {
        content: "";
        position: absolute;
        width: 120%;
        height: 1px;
        top: 50%;
        left: -10%;
        transform: translateY(-50%) rotate(-45deg);
        background-color: #333;
      }
    }

    button {
      outline: none;
    }
  }

  .btn--toggle-volume {
    padding: 0;
    margin: 0;
    font-size: 0;
    border: 0;
    position: relative;

    &.muted {
      .icon-speaker-mute {
        display: block;
      }
    }

    .icon-speaker {
      width: 24px;
      height: 24px;
      display: block;
      fill: transparent;
    }

    .icon-speaker-mute {
      width: 14px;
      height: 20px;
      background: @bg-white;
      position: absolute;
      right: -4px;
      top: 2px;
      display: none;

      &:before,
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 8px;
        width: 14px;
        height: 2px;
        border-radius: 2px;
        background: #231F20;

      }
      &:after {
        transform: rotate(-45deg);
      }
      &:before {
        transform: rotate(45deg);
      }
    }
  }
}

.progress {
  height: 8px;
  overflow: hidden;
  font-size: .75rem;
  background: #F3F3F3;
  border-radius: 8px;
  cursor: pointer;
  display: flex;

  &-bar {
    display: flex;
    width: 0;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: @accent-color;
  }

  &--volume-control {
    height: 5px
  }

  &--volume {
    position: relative;
    z-index: 0
  }
}

.btn--pause-active,
.btn--play-audio {
  padding: 0;
  outline: none !important;
  position: relative
}
