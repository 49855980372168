/* CHECKBOX ------------------------------------------------- */


.checkbox {
  display: block;
  position: relative;
  margin: 0;
  padding-left: 21px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #616161;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked+.checkmark {
      border: 2px solid #616161;

      &:after {
        display: block;
        z-index: 10;
        opacity: 1
      }
    }
  }

  .checkmark {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 2px solid #616161;
    box-sizing: border-box;
    border-radius: 3px;
    pointer-events: none;

    &:after {
      content: "";
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 12px;
      height: 10px;
      display: none;
      z-index: 10;
      opacity: 1;
      background: url(../images/svg/check_box_24px.svg) no-repeat 50%
    }
  }

}

.filters {
  &__checkbox-result {
    &:hover {
      color: #DBAE63;
      .checkmark {
        border-color: #DBAE63
      }
    }
  }
  &__checkbox-result--active {
    color: #DBAE63;
    font-weight: 700
  }
}



/*END CHECKBOX----------------------------------------------------------*/

.make-starred {
  user-select: none;

  &__control {
    display: block;
    margin: 0;
    position: relative;
    cursor: pointer;
  }

  &__v {
    opacity: 0;
    position: absolute;
    z-index: -1;
    width: 1px;
    height: 1px;
    clip: rect(0, 0, 0, 0);

    &:checked {
      &~svg {
        color: @broadcrumbs-link
      }
    }

    &~svg {
      fill: currentColor !important;
      color: #d8d8d8;
      width: 24px;
      height: 24px;
    }
  }

  &__v--border-bottom1 {
    border-bottom: 1px solid #d8d8d8
  }
}

.table-tile {
  &__v {
    &:checked {
      &~svg {
        color: @broadcrumbs-link !important;
        stroke: @broadcrumbs-link;
      }
    }

    &~svg {
      stroke: #ffffff;
      color: transparent;
      width: 24px;
      height: 24px;
    }
  }
  &__star-checkbox-contro {
    width: 24px;
    height: 24px;
  }
  &__star-checkbox {
    position: absolute;
    right: 32px;
    top: 32px;
  }
}

.card-header {
  &__v {
    &:checked {
      &~svg {
        color: @broadcrumbs-link !important;
        stroke: @broadcrumbs-link;
        fill: @accent-color !important;
      }
    }
    &~svg {
      fill: transparent !important;
      stroke: #616161;
      width: 20px;
      height: 19px;
    }
  }
  &__star-checkbox {

  }
}
