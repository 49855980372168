.keywords {
	button {
		outline: none;
		border: none;
		background: none;
		display: flex;
		align-items: center;
		text-align: right;
		letter-spacing: 0.0225em;
		text-transform: uppercase;
		color: #FFFFFF;
		height: 16px;
		margin-left: 4px;
	}
}