.table-tile {
  padding-top: 2px;
  
  &__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    color: @text-color-grey;
    &.three-column-table {
      width: 33.333%;
    }
    &.four-column-table {
      width: 25%;
    }
    &:hover {
      box-shadow: 0px 0px 5px 0px rgb(219, 174, 99, 55%);
    }
  }

  &__item-wrap {
    padding: 24px;
    position: relative;
    cursor: pointer;
  }

  &__item-img {
    width: 100%;
    height: 176px;
    background: @border-color-new;
    border-radius: 2px;
    position: relative;

    .table-tile__item-no-img-text {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: @border-color;
      position: absolute;
      top: 122px;
      left: 50%;
      transform: translateX(-50%);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 2px;
    }
    .icon-no-photo-document {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 39px;
      left: 50%;
      transform: translateX(-50%);
    }
    .icon-no_img_cemetery {
      width: 72px;
      height: 72px;
      position: absolute;
      top: 39px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__item-img-nophoto {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: @border-color;
    position: absolute;
    bottom: 38px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__item-cipher {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: @date-color;
    position: absolute;
    top: 0;
    right: 50%;
    transform: translateX(50%);
    margin-bottom: 0;
    height: 24px;
  }

  &__item-title {
    margin-top: 17px;
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    min-height: 39px;
    span {
      color: @accent-color;
    }
    &.collection {
      height: 63px;
      max-width: 234px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  &__item-description {
    padding-top: 17px;
  }
  &__item-description-container {
    display: flex;
    align-items: center;
  }
  &__item-description-icon {
    width: 16px;
    height: 16px;
    fill: @date-color !important;
  }
  &__item-description-text {
    margin-left: 4px;
    margin-bottom: 0;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: @text-color-grey;
  }
  &__item-country {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 12px;
  }

  &__table-line {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__table-title {
    font-size: 13px;
    line-height: 24px;
    color: @date-color;
    padding-right: 16px;
  }

  &__table-des {
    font-size: 13px;
    line-height: 24px;
  }

  &__btn {
    padding-top: 24px;
    display: flex;

    button {
      outline: none;
    }
  }

  &__btn-item {
    height: 40px;
    border: 1px solid @accent-color;
    box-sizing: border-box;
    border-radius: 2px;
    background: @bg-white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: static !important;

    &:hover {
      background: @accent-color;

      .table-tile__btn-item-span,
      .table-tile__btn-item-icon,
      .table-tile__btn-item-icon-stroke-path {
        fill: @addable-menu-link !important;
        color: @addable-menu-link !important;
      }

      .table-tile__btn-item-icon-stroke-line {
        stroke: @addable-menu-link;
      }
    }

    &[disabled] {
      border: 1px solid @border-color;
      background: @bg-white;
      pointer-events:none;
      cursor: default;

      .table-tile__btn-item-span {
        color: @border-color;
      }

      .table-tile__btn-item-icon,
      .table-tile__btn-item-icon-stroke-path {
        fill: @border-color !important;
      }

      .table-tile__btn-item-icon-stroke-line {
        stroke: @border-color;
      }

      &:hover {
        .table-tile__btn-item-span {
          color: @border-color !important;
        }
      }
    }



    &.one-btn {
      width: 100%;
    }

    &.two-btn {
      width: 50%;

      &:first-child {
        margin-right: 4px;
      }

      &:last-child {
        margin-left: 4px;
      }
    }
  }

  &__btn-item-icon {
    height: 24px;
    width: 24px;
    fill: @accent-color;
  }

  &__btn-item-icon-stroke {
    height: 24px;
    width: 24px;
  }

  &__btn-item-span {
    color: @accent-color;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-left: 6px;
  }
}
