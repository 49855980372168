.clipboard-target-span-container {
	border-bottom: 1px dashed @accent-color;
  padding-bottom: 5px;
  cursor: pointer;
  position: relative;

  .clipboard-target-span {
  	text-transform: uppercase;
    color: @date-color;
  }
  &:hover {
  	color: @accent-color;
  	.clipboard-target-span {
  		color: @accent-color;
  	}
  	.success-clipboard {
  		color: @date-color;
  	}
  }

}
.success-clipboard {
	position: absolute;
	bottom: -18px;
	left: calc(50% - 44px);
	display: none;
	color: @date-color;
	font-size: 12px;
	line-height: 16px;

}

.new-search-copy {
  position: relative;
}

.new-search-copy-success {
  position: absolute;
  width: 140px;
  left: 40px;
  top: 0;
  display: none;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #616161;
  padding: 6px 8px;
}