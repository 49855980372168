.dropdown-result-header {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  &__block {
    display: flex;
    font-size: 13px;
    line-height: 16px;
  }

	&__result-found {
		display: flex;
		align-items: center;
		font-size: 13px;
		line-height: 16px;
		font-weight: @font-medium;
		color: @text-color-grey;
		margin-bottom: 0;
	}

  &__total-found {
    margin-bottom: 0;
    color: @text-color-grey;
  }

  &__clear {
    margin-bottom: 0;
    cursor: pointer;
    font-weight: @font-medium;
    color: @text-color-grey;
    position: relative;
    margin-right: 27px;
    padding-right: 26px;
    transition:0.3s all ease;

    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 7px;
      right: 6px;
      width: 10px;
      height: 2px;
      border-radius: 2px;
      background-color: @bg-black-light;
      transition:0.3s all ease;
    }

    &:after {
      transform: rotate(45deg);
    }
    &:hover {
      color:#DBAE63;

      &:after,
      &:before {
        background-color: #DBAE63;
      }
    }

    &:before {
      transform: rotate(-45deg);
    }
  }

  &__open-content {
    margin-bottom: 0;
    cursor: pointer;
    font-weight: @font-medium;
    color: @accent-color;
    position: relative;
    padding-right: 26px;

    &.active {
      &:after {
      transform: rotate(-45deg);
      }
      &:before {
        transform: rotate(45deg);
      }
    }

    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 7px;
      width: 8px;
      height: 2px;
      border-radius: 2px;
      background-color: @bg-accent;
    }

    &:after {
      transform: rotate(45deg);
      right: 9px;
    }

    &:before {
      transform: rotate(-45deg);
      right: 4px;
    }
  }

	&__result-found-arrow {
		position: relative;
		width: 24px;
		height: 24px;
		cursor: pointer;
		&:first-child {
			margin-left: 5px;
		}
		&-bottom {
			margin-left: 2px;
			transform: rotate(180deg) translateY(2px);
		}
	}
}
