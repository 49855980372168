.tags {
  &__wrapper {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    width: 100%;
    max-width: 192px;
  }

  &__title {
    padding: 15px 12px 14px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #616161;
    margin-bottom: 0;
  }

  &__tab-title-icon {}

  &__list-wrapper {}

  &__list {
    position: relative;
  }

  &__list-item {
    border-bottom: 1px solid #F9F9F9;

    &:first-child {
      border-top: 1px solid #F9F9F9;
    }

    &:last-child {
      border-bottom: none;
    }

    .selector-active+.tags__dropdown {
      display: block;
    }
  }

  &__list-item-title {
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.03em;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 40px 10px 24px;
    cursor: pointer;

    &.selector-active {
      color: #DBAE63;

      .tags__list-wrapper-title-svg {

        &:before,
        &:after {
          background: #DBAE63;
        }
      }
    }
  }

  &__list-wrapper-title-text {
    margin-bottom: 0;
  }

  &__list-wrapper-title-count {
    margin-left: 3px;
  }

  &__list-wrapper-title-svg {
    margin-bottom: 0;
    height: 20px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      right: -18px;
      top: 7px;
      width: 7px;
      height: 2px;
      background: #616161;
      border-radius: 4px;
      transform: rotate(45deg);
    }

    &:after {
      content: "";
      position: absolute;
      right: -18px;
      top: 11px;
      width: 7px;
      height: 2px;
      background: #616161;
      border-radius: 4px;
      transform: rotate(-45deg);
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    left: 100%;
    top: -53px;
    background: #ffffff;
    border-radius: 4px;
    width: 452px;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.14));
    padding-right: 4px;
    z-index: 11;
  }

  &__dropdown-search {
    position: relative;
  }

  &__dropdown-search-input {
    width: 100%;
    background: #FDFDFD;
    padding: 15px 77px 13px 16px;
    border: none;
    outline: none;

    &::placeholder {
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #A7A7A7;
    }
  }

  &__dropdown-search-svg {
    position: absolute;
    right: 16px;
    top: 13px;
  }

  &__dropdown-list-wrapper {
    position: relative;
    height: 340px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: @border-color;
      border-radius: 4px;
    }

    &.hide {
      display: none;
    }
  }

  &__dropdown-list {
    margin-bottom: 0;
    padding-left: 0;
  }

  &__dropdown-list-item {
    list-style-type: none;
    padding: 16px;
    padding-right: 48px;
  }

  &__checkbox-label {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #616161;
    font-weight: normal;
    width: 100%;
    justify-content: space-between;

    &.cheked {
      
    }

    input:checked+.tags__checkbox {

      &:after,
      &:before {
        display: block;
      }

    }
    &:hover {
      color: #DBAE63;

      .tags__checkbox {
        border: 2px solid #DBAE63;
      }

      input:checked+.tags__checkbox {
        border: 2px solid #616161;
      }
    }

  }

  &__checkbox-input {
    margin-bottom: 0;
    opacity: 0;
    z-index: 1;
    pointer-events: none;
    clip: rect(0, 0, 0, 0);
  }

  &__checkbox {
    display: block;
    position: absolute;
    top: 49%;
    transform: translateY(-50%);
    left: 0;
    width: 18px;
    height: 18px;
    background: #fff;
    border: 2px solid #616161;
    box-sizing: border-box;
    border-radius: 3px;
    pointer-events: none;

    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: 4px;
      width: 10px;
      height: 2px;
      background: #616161;
      transform: rotate(-48deg);
      display: none;
    }

    &:after {
      content: "";
      position: absolute;
      top: calc(50%);
      left: 2px;
      width: 5px;
      height: 2px;
      background: #616161;
      transform: rotate(47deg);
      display: none;
    }
  }

  &__checkbox-time {
    margin-bottom: 0;
  }
  &__dropdown-list-sub-category-wrapper {
    padding-left: 16px;
  }
  &__checkbox-label {
    &.checked {
      .tags__checkbox {

        &:after,
        &:before {
          display: block;
        }

      }
    }
  }
  &__dropdown-list-sub-category {
    padding-left: 0;
    margin-bottom: 0;
    position: relative;
    height: 49px;
    overflow: hidden;
  }
  &__dropdown-list-search-wrapper {
    &.active {
      .tags__dropdown-list-sub-category-item {
        padding-right: 20px;
        &:nth-of-type(1) {
          padding-left: 20px !important;
        }
      }
    }
  }
  &__dropdown-list-sub-category-item {
    list-style-type: none;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 20px;

    &.no-top-padding {
      padding-top: 0;
      padding-bottom: 0;
    }

    &:nth-of-type(1) {
      padding-right: 48px;
      padding-left: 0 !important;
    }
  }
  &__dropdown-list-sub-category-arrow {
    width: 12px;
    height: 8px;
    position: absolute;
    right: 19px;
    top: 22px;
    z-index: 3;
    margin-bottom: 0;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      ;
      right: -1px;
      width: 8px;
      height: 2px;
      background: #616161;
      transform: rotate(-45deg);
      border-radius: 4px;
    }

    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      right: 4px;
      width: 8px;
      height: 2px;
      background: #616161;
      transform: rotate(45deg);
      border-radius: 4px;
    }

    &.active {
      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
  &__dropdown-list-search-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #ffffff;
    z-index: 4;
    width: 100%;
    height: 100%;
    display: none;

    &.active {
      display: block;
    }
  }
}

