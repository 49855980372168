.map-view {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  @media (min-width: @desktop) {
    display: none;
  }
}
.map-view__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
.map-view__item {
  width: 50%;
}
.map-view__button {
  display: block;
  width: 100%;
  background-color: #f9f9f9;
  border: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #a7a7a7;
  text-transform: uppercase;
  transition: @transition-time all;
  padding: 12px;
  &:focus {
    outline: none;
  }
  &--active {
    background-color: #fff;
    color: @accent-color;
  }
  .map-view__item:first-child & {
    border-radius: 4px 0 0 0;
    &--active {
      box-shadow: 2px 0px 4px rgba(193, 193, 193, 0.25);
    }
  }
  .map-view__item:nth-child(2) & {
    border-radius: 0 4px 0 0;
    &--active {
      box-shadow: -2px 0px 4px rgba(193, 193, 193, 0.25);
    }
  }
}
