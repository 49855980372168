.profile {
	&__data {
		padding: 24px;
		background: @bg-white;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
		border-radius: 4px;
	}

	&__data-item {
		display: flex;
		align-items: center;
		margin-bottom: 24px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__data-title {
		width: 53px;
		margin-right: 19px;
		font-size: 14px;
		line-height: 100%;
		display: flex;
		align-items: center;
		letter-spacing: 0.0025em;
		color: @date-color;
		margin-bottom: 0;
	}

	&__data-input {
		width: 100%;
		max-width: 264px;
		height: 40px;
		padding: 12px 16px 14px;
		background: @bg-white;
		border: 1px solid @border-color-new;
		box-sizing: border-box;
		border-radius: 4px;
		margin-right: 32px;
		outline: none;
	}
	&__data-change {
		margin-bottom: 0;
		font-size: 14px;
		line-height: 100%;
		display: flex;
		align-items: center;
		letter-spacing: 0.0025em;
		color: @accent-color;
		cursor: pointer;
		width: 100%;
		max-width: 110px;
	}
}
