.vue-filter {

  &__checkbox-wrapper {
    font-weight: @font-normal;
    font-size: 13px;
    line-height: 16px;
    color: @text-color-grey;
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 16px;
  }

  &__checkbox-label {
    margin-bottom: 0;
    height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    width: calc(100% - 40px);

    &:hover {

      .vue-filter__checkbox-count,
      .vue-filter__checkbox-label-text {
        color: @accent-color;
      }

      .vue-filter__checkbox-span {
        border: 2px solid @accent-color;

        &:after,
        &:before {
          background-color: @accent-color;
        }
      }
    }

    input:checked+.vue-filter__checkbox-span {

      &:after,
      &:before {
        display: block;
      }
    }
  }

  &__checkbox-label-text {
    padding-left: 32px;
    font-weight: @font-normal;
    font-size: 13px;
    line-height: 16px;
    color: @text-color-grey;
    margin-bottom: 0;
		//text-transform: capitalize;
  }

  &__checkbox-input {
    visibility: hidden;
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
  }

  &__checkbox-span {
    position: absolute;
    left: 19px;
    top: 11px;
    width: 18px;
    height: 18px;
    border: 2px solid @text-color-grey;
    border-radius: 2px;

    &:after {
      display: none;
      content: '';
      position: absolute;
      left: 2px;
      top: 7px;
      background-color: @bg-black-light;
      width: 5px;
      height: 2px;
      transform: rotate(45deg);
    }

    &:before {
      display: none;
      content: '';
      position: absolute;
      left: 4px;
      top: 6px;
      background-color: @bg-black-light;
      width: 10px;
      height: 2px;
      transform: rotate(-55deg);
    }
  }

  &__checkbox-count {
    font-weight: @font-normal  !important;
    color: @date-color;
    position: absolute;
    right: 48px;
    margin-bottom: 0;
  }

  &__checkbox-arrow-wrapper {
    position: absolute;
    right: 16px;
    top: 8px;
    width: @default-width;
    height: @default-height;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.active {
      transform: rotate(180deg);
    }
  }

  &__checkbox-arrow {
    width: 10px;
    height: 6px;
    fill: @bg-black-light;
  }

  &__checkbox-sub-list {
    margin-left: 20px;
  }
}
