.profile {

  &__favorites-filter {
    padding-top: 24px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__favorites-filter-item {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    border: 1px solid #F9F9F9;
		cursor: pointer;
  }

  &__favorites-filter-link {
    display: flex;
    align-items: center;
		justify-content: center;
		outline: none;
		background: none;
		border: none;
		width: 100%;
		height: 100%;

    &:hover {
      .profile__favorites-filter-link-icon {
        fill: @broadcrumbs-link;
      }

      .profile__favorites-filter-link-title {
        color: @broadcrumbs-link;
      }
    }

    &.selected {
      .profile__favorites-filter-link-icon {
        fill: @accent-color;
      }

      .profile__favorites-filter-link-title {
        color: @accent-color;
      }
    }
  }

  &__favorites-filter-link-title {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: @text-color-grey;
    margin-left: 8px;
  }

  &__favorites-filter-link-icon {
    fill: @text-color-grey;
  }
}

;
