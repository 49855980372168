.slider {
  position: relative;

  button {
    outline: none !important;
  }

  &__inner {
    // padding: 20px 0;
    // border-top: 1px solid #ccc;
    // border-bottom: 1px solid #ccc;
  }

  &__item {
    position: relative;
    display: block;

    &--video {
      .slider {
        &__play-nav {
          background-color: linear-gradient(0deg, rgba(61, 61, 61, 0.6), rgba(61, 61, 61, 0.6));
        }

        &__serial-number {
          display: none;
        }
      }

      .btn--play-video {
        display: flex;

        .icon-play-video {
          margin-left: 3px;
          display: block;
          fill: #fff;
          width: 20px;
          height: 25px
        }
      }

      &:hover {
        .btn--play-video {
          border: 1.5px solid @broadcrumbs-link;

          .icon-play-video {
            fill: @broadcrumbs-link;
          }
        }
      }
    }

    &:hover {
      .slider {
        &__play-nav {
          display: block;
        }
      }
    }

    img {
      border-radius: 4px;
    }
  }

  &.slider-video {
    .gallery-container__item {
      &:after {
         content: url("../images/svg/play_circle_filled_24px_rounded.svg");
      }
    }
  }
	.owl-stage-outer {
		overflow: visible !important;
	}
  .gallery-container__item {
    display: block;
    cursor: pointer;
    position: relative;
    &:hover {
      p {
        display: flex;
      }
    }

    img {
      max-height: 96px;
      object-fit: cover;
    }
    p {
      display: none;
      margin-bottom: 0;
      position: absolute;
      //top: -30px;
			top: 100%;
      left: 50%;
      min-width: 127px;
      justify-content: center;
      text-align: center;
      transform: translateX(-50%);
      background: @bg-white;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
      border-radius: 4px;
      padding: 6px 8px;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      align-items: center;
      text-align: center;
      color: @text-color-grey;
      z-index: 5;
      width: 100%;
    }


    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0deg, rgba(61, 61, 61, 0.6), rgba(61, 61, 61, 0.6));
      opacity: 0;
      transition: .2s;
      z-index: 2;
      border-radius: 4px;
    }

    &:after {
      content: url("../images/svg/maximize.svg");
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      fill: #ffffff;
      transform: translate(-50%, -50%);
      z-index: 3;
      transition: .2s;
      opacity: 0;
    }
    &:hover {
      &:before,
      &:after {
          opacity: 1;
      }
      &.item-video {
       &:after {
        opacity: 1;
       }
     }
    }
    &.item-video {
       &:after {
        content: url("../images/svg/button_play_video.svg");
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        fill: #ffffff;
        transform: translate(-50%, -50%);
        z-index: 3;
        transition: .2s;
        opacity: 0;
      }
    }
  }
  &__play-nav {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(0deg, rgba(61, 61, 61, 0.6), rgba(61, 61, 61, 0.6));
      opacity: 0;
      transition: .2s;
      z-index: 2;
    }

    &:after {
      content: url("../images/svg/maximize.svg");
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
      fill: #ffffff;
      transform: translate(-50%, -50%);
      z-index: 3;
      transition: .2s;
      opacity: 0;
    }
  }

  &__serial-number {
    position: absolute;
    padding: 3px 5px 1px;
    bottom: 0;
    left: 0;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    background: hsla(0, 0%, 100%, .5);
    border-radius: 0 3px 0 0;
  }

  .owl-item {
    &:hover {
      .slider__play-nav {

        &:before,
        &:after {
          opacity: 1;
        }
      }
    }
  }

  .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
  }

  .owl-next {
    position: absolute;
    right: 0;
  }
}


.btn--play-video {
  position: absolute;
  display: none;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  border: 1.5px solid #fff;
  border-radius: 50%
}


.slider-cemetery {
  position: relative
}


.popup-photo {
  background-color: hsla(0, 100%, 0%, 0.3) !important;

  .mfp-content {
    position: static !important;
  }

  .mfp-bottom-bar {
    position: static !important;
  }

  button {
    &.mfp-arrow-left {
      left: 32px !important;
    }

    &.mfp-arrow-right {
      right: 32px !important;
    }

    &.mfp-arrow {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: none;
      background-color: #363636;
      opacity: 1;
      margin-top: auto;
      transform: translateY(-50%)
    }

    &.mfp-arrow {

      .icon-arrow-left,
      .icon-arrow-right {
        stroke: @date-color;
        width: 10px;
        height: 10px;
      }
    }
  }

  .mfp-close {
    background-color: #363636;
    width: 32px !important;
    height: 32px;
    right: 32px;
    top: 83px;
    border-radius: 50%;
    padding-right: 0;

    &:before {
      content: "";
      width: 14px;
      height: 2px;
      border-radius: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #ffffff;
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      content: "";
      width: 14px;
      height: 2px;
      border-radius: 4px;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #ffffff;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  .icon-close-popup {
    display: none;
  }

  img {
    &.mfp-img {
      padding-top: 0 !important;
      width: 100%;
      border-radius: 4px;
    }
  }

  .mfp-bottom-bar {
    border-radius: 0px 0px 4px 4px;
    opacity: 0.8;
  }
}
