.archive-index {
  .new-table__container {
    overflow: visible !important;
		height: 0%;
  }
  .pagination__item {

    .new-angle-left,
    .new-angle-right {
      width: 7px;
      height: 11px;
    }

    &:first-child,
    &:last-child {
      box-shadow: none !important;
      width: 24px !important;
      height: 24px !important;
    }
  }
  .new-filter-search .filters__dropdown {
    max-width: 452px !important;

    &_datapicker {
      max-width: 248px !important;

      .filters__result-inner {
        height: 350px !important;
        max-height: 350px !important;
        overflow-y: hidden !important;
      }
    }
  }

  .new-filter-search .checkbox {
    height: auto !important;
    min-height: 46px !important;
  }

  .map {
    padding-top: 0;

    .container {
      padding: 0px;
      max-width: 1128px;
    }

    &__header {
      display: none;
    }
  }

  .page__table-wrapper {
    margin-bottom: 0;
  }
}
