div.page__content-player {
  @media (min-width: @desktop) {
    flex-wrap: nowrap;
  }
}

.page {
  padding-bottom: 0 !important;
	&__content-text {

		padding-top: 27px;
		&::-webkit-scrollbar {
			width: 4px;
		}

		&::-webkit-scrollbar-track {
			background: @bg-white;
			border-radius: 4px;
		}

		&::-webkit-scrollbar-thumb {
			background: @bg-scroll;
			border-radius: 4px;
			outline: none;
		}
	}
  &__content {
    display: flex;

    &--interview {
      flex-direction: column;
      /*background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);*/
      border-radius: 4px;
      margin-left: 20px;
      // padding: 20px 24px 40px;
      padding-top: 20px;
      padding-bottom: 40px;
    }
    &-slider {
      margin-bottom: 0px;
      width: 100%;
      // overflow: hidden;
      background-color: #fff
    }
  }

  &__filter-search {
    margin-right: 20px;
    &.fixed-sidebar {
      .filter-search {
        width: 192px;
        height: 100%;
      }
      .filters {
        width: 192px;
        top: 66px;
        position: fixed;
        z-index: 1;
      }
      .filter-button__container {
        position: fixed;
        transform: translateY(-35px);
      }
    }
    &.bottom-sidebar {
      .filter-search {
        width: 192px;
        height: 100%;
      }
      .filters-form {
        height: 100%;
      }
      .filter-search__tab {
        height: 100%;
      }
      .filters {
        width: 192px;
        bottom: 0;
        position: absolute;
        z-index: 1;
      }
    }
  }
  &__search-result {

    width: 100%;
    &.new-filter-search-page {
      display: flex;
      justify-content: space-between;
    }
  }
}



.container-switch-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 32px;
}
