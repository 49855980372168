.user {
  display: flex;
  flex-shrink: 0;
  margin: 10px 0;
  position: relative;

  @media (min-width: @tablet) {
    padding: 0;
    margin: 0 32px;
    display: block;
  }
}

.user__login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: @accent-color;
	margin-bottom: 0;
	cursor: pointer;
}

.user__button {
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  transition: color @transition-time;
  padding: 14px 0 10px;

  &:hover {
    color: #FFE092;
  }

  &:focus {
    outline: none;
  }

  &:active {
    color: #A77F36;
  }
}

.user__icon {
  color: @accent-color;
}

.user__list-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;

  .user__button:hover ~ &,
  &:hover {
    display: block;
  }

  @media (min-width: @tablet) {
    padding: 9px 0 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.user__list {
  margin: 0;
  padding: 0;
  background-color: @accent-bg;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
  list-style: none;
  position: relative;

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 8px 7px;
    border-color: transparent transparent @accent-bg transparent;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
  }
}

.user__item {
  border-bottom: 1px solid @accent-color;
  overflow: hidden;

  &:first-child {
    border-radius: 4px 4px 0 0;
  }

  &:last-child {
    border-radius: 0 0 4px 4px;
    border-bottom: none;
  }
}

.user__link {
  color: @accent-color;
  font-size: 1.4rem;
  line-height: 2.4rem;
  display: block;
  padding: 8px 16px;
}
