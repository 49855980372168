.map-filters {
  &__dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: @bg-white;
    z-index: 2;
		transition: all 0.2s ease;
		&._active {
			width: calc(100% + 360px);
			left: -360px;
		}
  }

  &__dropdown-filters-wrapper {
    display: flex;
    overflow: auto;
  }

  &__dropdown-filters-item {
    width: 256px;
		min-width: 256px;
  }

  &__dropdown-filters-item-title {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0px 24px;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: @text-color-grey;
  }

  &__dropdown-filters-list-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__dropdown-filters-list {
    padding-left: 0;
    margin-bottom: 0;
    height: calc(100% - 40px);
    border: 1px solid @border-color-new;
    max-height: 302px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: @bg-white;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: @bg-scroll;
      border-radius: 4px;
      outline: none;
    }

    &_sub {
      border: none;
      padding-top: 8px;
			width: 100%;
			height: 100% !important;
			max-height: 100% !important;

			.map-filters__dropdown-filters-list-item {
				display: block !important;
				position: relative;
				padding-right: 22px;
			}
			.map-filters__dropdown-filters-arrow-dropdown {
				position: absolute;
				right: 0;
				top: 14px;
			}
			.map-filters__dropdown-filters-count {
				margin-right: 20px;
			}
			.map-filters__dropdown-filters-list {
				height: 100% !important;
				max-height: 100% !important;
			}
    }
  }

  &__dropdown-filters-list-item {
    list-style-type: none;
    padding: 8px 16px;
    display: flex;
    align-items: center;

    &.sublist {
      flex-direction: column;
      align-items: flex-start;

      .map-filters__dropdown-filters-list-item {
        padding-right: 0;
      }
    }
  }

  &__dropdown-filters-arrow-dropdown {
    width: 12px;
    height: 12px;
    position: relative;
    margin-left: 10px;
    cursor: pointer;

    &.active {
      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: 4px;
      right: 4px;
      width: 8px;
      height: 2px;
      border-radius: 2px;
      background-color: @text-color-grey;
      transform: rotate(45deg);
    }

    &:after {
      content: "";
      position: absolute;
      top: 4px;
      right: 0px;
      width: 8px;
      height: 2px;
      border-radius: 2px;
      background-color: @text-color-grey;
      transform: rotate(-45deg);
    }
  }

  &__dropdown-filters-label {
    cursor: pointer;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    line-height: 16px;
    font-weight: normal;
    display: flex;
    align-items: center;
    color: @text-color-grey;
    position: relative;
    padding-left: 26px;
    margin-bottom: 0;
    width: 100%;

    input:checked + .map-filters__dropdown-filters-checkbox {
      &:after,
      &:before {
        display: block;
      }
    }
  }

  &__dropdown-filters-input {
    visibility: hidden;
  }

  &__dropdown-filters-checkbox {
    width: 18px;
    height: 18px;
    position: absolute;
    left: 0;
    top: 3px;
    border: 2px solid @text-color-grey;
    border-radius: 4px;

    &:after {
      display: none;
      content: "";
      position: absolute;
      top: 6px;
      left: 3px;
      width: 10px;
      height: 2px;
      background-color: @text-color-grey;
      transform: rotate(310deg);
    }

    &:before {
      display: none;
      content: "";
      position: absolute;
      top: 7px;
      left: 1px;
      width: 5px;
      height: 2px;
      background-color: @text-color-grey;
      transform: rotate(45deg);
    }
  }

  &__dropdown-filters-count {
    font-size: 13px;
    line-height: 16px;
    color: @date-color;
  }

  &__dropdown-keyword {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
  }

  &__dropdown-keyword-title {
    font-size: 13px;
    line-height: 24px;
    color: @border-color;
    padding-left: 5px;
    margin-bottom: 0;
    height: 28px;
    display: flex;
    align-items: center;
  }

  &__dropdown-keyword-list {
    margin-bottom: 0;
    padding-left: 0;
    display: flex;
    max-height: 76px;
    overflow: auto;
    margin-right: 2px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: @bg-white;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: @bg-scroll;
      border-radius: 4px;
      outline: none;
    }

    &_hide {
      padding: 0px 16px;
      flex-wrap: wrap;

      .map-filters__dropdown-keyword-list-item {
        margin-bottom: 10px;
      }
    }
  }

  &__dropdown-keyword-list-item {
    list-style-type: none;
    background: @accent-color;
    border-radius: 20px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    padding: 6px 12px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__dropdown-keyword-word {
    font-size: 13px;
    line-height: 100%;
    color: @bg-white;
    margin-bottom: 0;
    margin-right: 8px;
  }

  &__dropdown-keyword-word-del {
    margin-bottom: 0;
    width: 16px;
    height: 16px;
    position: relative;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 4px);
      right: calc(50% - 2px);
      height: 10px;
      width: 2px;
      border-radius: 2px;
      background-color: @bg-white;
      transform: rotate(45deg);
    }

    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 4px);
      right: calc(50% - 2px);
      height: 10px;
      width: 2px;
      border-radius: 2px;
      background-color: @bg-white;
      transform: rotate(-45deg);
    }
  }

  &__dropdown-keyword-more {
    display: flex;
    align-items: center;
  }

  &__dropdown-keyword-more-word {
    color: @accent-color;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    padding-right: 21px;
    cursor: pointer;
    position: relative;

    &.active {
      &:after {
        transform: rotate(-45deg);
      }

      &:before {
        transform: rotate(45deg);
      }
    }

    &:after {
      content: "";
      position: absolute;
      top: 8px;
      right: 5px;
      width: 8px;
      height: 2px;
      border-radius: 2px;
      background: @accent-color;
      transform: rotate(45deg);
    }

    &:before {
      content: "";
      position: absolute;
      top: 8px;
      right: 0;
      width: 8px;
      height: 2px;
      border-radius: 2px;
      background: @accent-color;
      transform: rotate(-45deg);
    }
  }
}
