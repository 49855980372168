.map-search-dropdown {
  &__alphabet {
    padding: 12px 20px;
    border-bottom: 1px solid @border-color;
  }
  &__alphabet-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__alphabet__item {
    cursor: pointer;
    margin-left: 7px;
    margin-right: 7px;
    margin-bottom: 7px;
    color: @text-color-grey;
    font-weight: @font-bold;

		&._disabled {
			cursor: default;
			color: @date-color;
			&:hover {
				color: @date-color;
			}
		}
		
    &.active {
      color: @accent-color;
    }
    &:hover {
      color: @accent-color;
    }
  }
}
