.dropdown-date-range {
  margin-top: 13px;
  &__wrapper {
    padding: 0px 24px;
  }
  &__inputs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  &__input {
    padding-bottom: 8px;
    text-align: center;
    outline: none;
    border: none;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: @text-color-grey;
    border-bottom: 1px solid @bg-light-gray;
    &:focus {
      border-bottom: 1px solid @text-color-grey;
    }
  }
  &__btn {
    margin-top: 24px;
  }
  &__btn-item {
    cursor: pointer;
    height: 36px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    background: @accent-color;
    border-radius: 4px;
    font-weight: @font-bold;
    font-size: 13px;
    line-height: 100%;
    letter-spacing: 0.0225em;
    text-transform: uppercase;
    color: @color-white;
  }
  .noUi-handle {
    &.noUi-active {
      .noUi-touch-area {
        &:after {
          display: block;
        }
      }
    }
  }
  .noUi-touch-area {
    &:after {
      display: none;
      content: "";
      position: absolute;
      top: -50%;
      left: -50%;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: @bg-accent;
      opacity: 0.4;
      z-index: -1;
    }
  }
}
