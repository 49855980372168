.new-super-filter__container {
  width: 192px;
  min-width: 192px;
  margin-right: 19px;
	position: relative;
  z-index: 11;
}

.vue-filter {
  //position: relative;
	position: sticky;
  top: 78px;

	&.floating {
		position: fixed;
    width: 192px;
    top: 87px;
	}

	&.pinned {
		position: absolute;
		width: 192px;
		bottom: 0;
	}

  &__content {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    position: relative;

    &.border {border-radius: 4px 0px 0px 4px;}
  }

  &__loader {
    width: 100%;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
  }

  &__loader-img {
    width: 120px;
    height: 120px;
		animation: circle 1.5s infinite linear;
  }
}
@keyframes circle {
	0% {
			transform: rotate(0)
	}
	100% {
			transform: rotate(-359deg)
	}
}
