.how-to-use {
  padding-top: 45px;
  // padding-bottom: 100px;

  &__wrapper {
    color: @text-color;
    p, span {
      font-size: 16px;
      line-height: 24px;
      max-width: 696px;
      margin-bottom: 0;
      padding-bottom: 8px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &__title {
    font-weight: bold !important;
    font-size: 30px !important;
    line-height: 35px !important;
    padding-bottom: 20px !important;
    margin-bottom: 0;
    max-width: 548px !important;
  }

  &__des {
    font-size: 16px;
    line-height: 24px;
    max-width: 696px;
    margin-bottom: 0;
    padding-bottom: 10px;

    &:last-child {
      padding-bottom: 0;
    }
  }
}

.section-main {
  &__about {
    padding-top: 0 !important;
  }

  &__about-container {
    padding: 18px 24px !important;
  }

  &__about-icon {
    height: 24px !important;
    width: 24px !important;
    fill: @accent-color  !important;
    margin-right: 11px;
    &_no-fill {
      fill: none !important;
    }
    &_stroke {
      stroke: @accent-color  !important;
    }
    &_img-fill {
      display: none;
    }
  }

  &__about-span {
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #FFFFFF;
    margin-left: 10px;
  }

  &__about-wrapper {
    display: flex;
    align-items: center;
    width: 100% !important;
    height: auto !important;
  }

  &__about-stuff {
    padding: 14px 24px !important;
  }

  &__about-link {
    display: flex;
    justify-content: space-between;
    &[disabled] {
      cursor: default;
      pointer-events: none;
    	.section-main__about-name,
    	.section-main__about-quantity {
    		color: #D8D8D8;
    	}
    	.section-main__about-icon {
    		fill: #D8D8D8 !important;
    	}
      .section-main__about-icon_no-fill,
      .section-main__about-icon_stroke {
        stroke: #D8D8D8 !important;
        fill: none !important;
      }
      .section-main__about-icon_img {
        display: none;
      }
      .section-main__about-icon_img-fill {
        display: block;
      }
    }
  }

  &__about-link-container {
    display: flex;

    svg {
      margin-right:11px;
      width: 24px;
      height: 24px;
    }

  }

  &__about-inner {
    justify-content: normal !important;

    &:before {
      content: none;
    }
  }

  &__about-name {
    color: @accent-color;
  }

  &__about-quantity {
    color: @accent-color;
    padding-left: 0 !important;
  }
}
