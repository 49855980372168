.map-bubble {
  padding: 6px 8px !important;
}

.gm-style .gm-style-iw-c {
  padding: 0px !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
}
.map-bubble__city {
  background-color: @bg-white;
  text-align: center;
  width: 100%;
  color: @text-color-grey;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  padding: 0px;
}
.map-bubble__list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.map-bubble__item {
  border-bottom: 1px solid @border-color;
  &:last-child {
    border-bottom: none;
  }
}
.map-bubble__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: @bg-white;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  padding: 0px;
  color: @text-color-grey;
  &:hover {
    color: @text-color-grey;
  }
}
.map-bubble__link-icon {
  margin: 0 0 0 8px;
  color: @accent-color;
  width: 16px;
  height: 16px;
  @media (min-width: @tablet) {
    margin: 0 0 0 22px;
    width: 24px;
    height: 24px;
  }
}
.gm-ui-hover-effect {
  display: none !important;
}
.gm-style .gm-style-iw-t::after {
  display: none !important;
}
