.table {
  &__td {
    &--audioplayer {
      padding: 0
    }

    &--lang {
      width: 70px;
      text-align: center
    }

    &--catalog {
      width: 30px
    }
    &--cipher {
      width: 96px;
      font-size: 13px;
      line-height: 16px;
      text-transform: uppercase;
      color: #A7A7A7;
      padding-left: 9px !important;
    }
    &--id-informata {
      width: 381px;
      &-container {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        &.horizontally {
          .table__td--id-informata-item {
            display: inline-flex;
            margin-bottom: 0;
            &:first-child {
              span {
                width: 75px;
                display: block;
              }
            }
          }
        }
      }
      &-item {
        display: block;
        margin-bottom: 4px;
        color: #616161;
        font-size: 13px;
        line-height: 16px;
        &:first-child {
          span {
            font-weight: bold;
          }
        }
        &:last-child {
          span {
            &:first-child {
              margin-right: 5px;
            }
            &:last-child {
              margin-left: 5px;
            }
          }
        }

      }

    }
    &--search-interview,
    &--language {
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      span {
        &:first-child {
          color: #616161;
        }
        &:last-child {
          color: @date-color;
        }
      }
    }
    &--audio {
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      text-align: center;
    }
    &--search-interview {
      width: 206px;
    }
    &--more {
      width: 44px
    }

    &--catalog,
    &--more {
      padding-left: 0;
      padding-right: 0;
      text-align: center
    }

    &--tooltip {
      position: relative
    }

    &--starter {
      width: 68px;
      // height: 50px;
      padding-top: 0;
      padding-bottom: 0;
      // background: #f2f2f2;
      text-align: center;
      vertical-align: top;

      &+.table__td--starter {
        background: linear-gradient(90deg, #fff 2px, #f2f2f2 0, #f2f2f2)
      }
    }

    &--text-align-center {
      text-align: center
    }

    &--popover {
      position: relative
    }
  }
}
