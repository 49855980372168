.content-tabs {
	.list-cemetery {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      margin-bottom: 0;
      font-weight: bold;
      font-size: 30px;
      line-height: 35px;
      color: @text-color;
      margin-top: 0;
    }

    &__slider {
      height: 32px;
      width: 48%;
      background: @bg-white;
      border-radius: 32px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 50%;
      transition: all .3s ease 0s;
      filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));

      &.active {
        right: 3px;
      }
    }
  }


  &__menu-link-slider {
    cursor: pointer;
    z-index: 10;

    .icon-star {
      fill: #787878;
    }

    &.active {
      cursor: default;
      
      .icon-star {
        fill: @text-color;
      }

      .content-tabs__menu-link-span-slider {
        color: @text-color  !important;
      }

      .content-tabs__menu-link-icon-slider {
        fill: @text-color;
      }
    }
  }

  &__menu-link-icon-slider {
    fill: #787878;
  }

  &__menu-link-span-slider {
    color: #787878;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-left: 8px;
  }
}