.gallery2 {
  position: relative;
  max-width: 362px;

  &__inner {
    position: relative;
    height: 408px;

    img {
      width: 310px;
      height: 408px;
      object-fit: contain;
    }
  }

  &--text {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    color: @date-color;
  }

  &--main {
    .owl-nav {
      z-index: 2;
      position: absolute;
      bottom: -70px;
      left: -42px;
      right: -42px;
      height: 0;
      transform: translateY(-50%);

      &>button {
        border-radius: 50%;
        display: block;
        width: 28px;
        height: 28px;
        color: #616161 !important;
        outline: none !important;

        span {
          display: block;
          line-height: 24px;
          font-size: 30px;
          transform: matrix(1, 0, 0, 1, 0, -2);
        }
      }
    }

    .owl-prev {
      float: left
    }

    .owl-next {
      float: right
    }
  }

  &--thumbs {
    margin-top: 16px;

    .owl-item {
      position: relative;

      &.active {
        img {
          width: 56px;
          height: 56px;
          object-fit: cover;
        }
      }

      &.current {
        &:before {
          // box-shadow: inset 0 0 0 3px #f2f2f2;
          // background: hsla(0, 0%, 100%, .25)
        }
      }
    }
  }

  &__popup-link {
    display: block;
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    img {
      position: absolute;
      top: 0;
      left: 100%;
      z-index: 1000
    }
  }

  &__img {
    cursor: pointer;
  }

  &__list-wrapper {
    position: relative;
    overflow: hidden;
    transition: .1s;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid #f2f2f2
    }

    &:hover {
      box-shadow: 0 1px 5px rgba(0, 0, 0, .2), 0 3px 4px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .14)
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    height: 528px
  }

  &__item {
    height: 100%;
    display: inline-block;
    overflow: hidden;
    cursor: pointer;
    text-align: center;

    img {
      max-height: 100%
    }
  }

  &__action-name {
    display: inline-block;
    text-align: center;
    width: 100%;
    margin: 12px 0 24px;
    color: #616161;
    font-size: 14px;
    line-height: 20px
  }
}


.owl-item {
  &.current {
    position: relative;

    &:before {
      // box-shadow: inset 0 0 0 3px #b4891d;
      // position: absolute;
      // top: 0;
      // bottom: 0;
      // left: 0;
      // right: 0;
      // content: "";
      // z-index: 2
    }
  }
}

.interaction {
    position: fixed;
    left: 50%;
    bottom: 24px;
    transform: translate(-50%, 21px);;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
    background-color: #242424;
    border-radius: 2px
}

.interaction button {
    border: 0;
    background-color: transparent
}

.interaction__download {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    width: 36px;
    padding: 9px 0
}

.gallery-popup__overlay {
    background-color: rgba(0, 0, 0, .7)
}

.gallery-popup__content {
    position: absolute;
    top: 50%;
    left: 50%;
    // width: 100%;
    // height: 100%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1
}

.gallery-popup__list {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.gallery-popup__item {
    display: none
}


.gallery-popup__item--active {
    display: grid;
    max-width: 100vw;
    max-height: 100vh;
    overflow: auto
}

.gallery-popup__item img {
    max-width: none;
    width: auto;
    border-radius: 4px;
}

.gallery-popup__image-name {
    color: #fff;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    display: block;
    margin-bottom: 0px;
    order: 2;
    background: rgba(255, 255, 255, 0.8);
    padding: 11px 17px;
    font-size: 15px;
    line-height: 18px;
    color: #363636;
    transform: translateY(-40px);
    z-index: 1;
    border-radius: 0px 0px 4px 4px;
}

.gallery-popup__image {
    position: relative;
    left: 50%;
    transform: translateX(-50%)
}

