.filters {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

  &__checkbox-result {
    &.checkbox {
      width: 100%;
      height: 100%;
      padding: 11px 5px 11px 29px;
    }
  }

  &__search {
    flex: 0 0 39px;
    position: relative;
    padding: 13px 40px 11px 20px;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    // border-bottom: 1px solid #d8d8d8;
    background: #fff;

    & .icon-search {
      right: 20px;
      position: absolute;
      width: 18px;
      height: 18px;
      fill: #A7A7A7;
    }

    button {
      outline: none;
    }
  }

  &__search-input {
    display: block;
    width: 100%;
    border: 0;
    color: #000;
    line-height: 20px;
    font-size: 14px;
    &::placeholder {
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #A7A7A7;
    }
  }

  &__search-input:focus {
    outline: none
  }

  &__item {
    min-width: 192px;
    width: 100%;
  }

  &__dropdown {
    z-index: 999;
    margin-top: -1px;
    // width: 100%;
    width: 452px;
    position: absolute !important;
    top: 0;
    left: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;
    user-select: none;
    will-change: transform;
    transform: translateY(4px);
    transition: .15s;

    button {
      outline: none;
    }

    &_range,
    &_datapicker {
      max-width: 248px;
    }

    &:after,
    &:before {
      display: none;
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      right: 19px;
      left: auto;
      border-style: solid;
      border-width: 0 8px 8px
    }

    &:after {
      border-color: transparent transparent #fff
    }
  }

  &__select {
    overflow: hidden;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    user-select: none;
    cursor: pointer;

    &--active {
      & .filters__title-select {
        color: #DBAE63 !important;
      }

      & .filters__icon-indicator {
        & .icon-indicator {
          fill: #DBAE63 !important;
        }
      }
    }
  }

  &__title-select {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    line-height: 20px;
    color: #616161;
  }

  &__icon-indicator {
    margin-right: 0px;

    & .icon-indicator {
      width: 10px;
      height: 6px;
      display: block;
      fill: #606060 !important;
      transform: rotate(-90deg)
    }
  }

  &__result-list,
  &__result-sublist {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: 0;
    margin: 0;
    list-style-type: none;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: @border-color;
      border-radius: 4px;
    }
  }

  &__result-item--main-category {
    font-weight: 700;
    padding: 0px !important;
    svg {
      fill: #616161 !important;
    }
  }
  &__result-item {
    display: inline-block;
    width: 100%;
    padding: 8px 19px;
    list-style-type: none;
    padding-right: 7px;

    &.icon-arrow-down {
      display: block;
      width: 10px;
      height: 6px;
      margin-left: auto;
      transform: rotate(-90deg);
      fill: #DBAE63;
    }
  }

  &__inner-item {
    flex: 1 1 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    button {
      outline: none !important;
    }
  }

  &__inner-item-span-container {
    display: flex;
    justify-content: space-between;
  }

  &__inner-item--sub-list {
    padding-bottom: 0
  }
}

.filters__search .icon-new-search {
  position: absolute;
  top: 50%;
  // left: 12px;
  right: 15px;
  transform: translateY(-50%);
  fill: #b0b0b0;
}

.filters__checkbox-result.checkbox {
  width: 100%;
  height: 100%;
  // padding: 20px 10px 20px 39px;
  display: flex;
  justify-content: space-between;
  &:hover {
    .filters__inner-item-time {
      color: #e8b859;
    }
  }
}

.filters__inner-item-time {
  margin-bottom: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: right;
  color: @date-color;
  width: 17%;
}

.filters__result-item--main-category .icon-arrow-down,
.filters__result-item--main-category .icon-new-angle-down {
    display: block;
    transform: rotate(180deg)
}