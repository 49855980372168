.pagination {
  &--view-interview {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &--interview-bottom {
    padding: 20px 0 0;
  }

  [title] {
    position: relative;
  }

  [title]:after {
    content: attr(value);
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    padding: 6px;
    position: absolute;
    left: -9999px;
    opacity: 0;
    bottom: 120%;
    white-space: nowrap;
    transition: 0.25s linear opacity;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #616161;
  }

  [title]:hover:after {
    left: -200%;
    opacity: 1;
  }
}

.pagination__item {
  cursor: pointer;

  .pagination--view-interview & {
    &:first-child,
    &:last-child {
      margin-right: 0;
      margin-left: 0;
      border: none;
      border-radius: 0;
      width: 32px;
      height: 32px;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

.pagination__prev {
  .pagination--view-interview & {
    cursor: pointer;
    border: 1px solid #d8d8d8;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #e8b859;

      svg {
        fill: #fff;
        opacity: 1;
      }
    }

    &:active {
      background-color: #7f5b00;

      svg {
        fill: #fff;
        opacity: 1;
      }
    }
  }
}

.pagination__next {
  .pagination--view-interview & {
    cursor: pointer;
    border: 1px solid #d8d8d8;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex !important;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #e8b859;

      svg {
        fill: #fff;
        opacity: 1;
      }
    }

    &:active {
      background-color: #7f5b00;

      svg {
        fill: #fff;
        opacity: 1;
      }
    }
  }
}

.pagination__item {
  color: @broadcrumbs-link;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  //display: none;

	&_number span {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 0 12px;
	}

  &_arrow-next,
	&_arrow-prev {
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: 0.12s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    background-color: @bg-white;

    &:hover,
    &:active {
      border-color: transparent;
    }

    .filter-search__clear-link,
    a {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: 0.12s;
      background-color: @bg-white;
      border-radius: 50%;

      &:hover {
        background-color: @accent-color;
      }
    }

    &.disabled {
      border: none;
      background-color: @bg-white;
      cursor: not-allowed;

      .pagination__svg {
        fill: @border-color;
        opacity: 1;
      }
    }
  }

  &_hide-prev {
		margin: 0;
		padding: 0 12px;
  }

  &_hide-next {
    margin: 0;
		padding: 0 12px;
    border: none;
    &:hover {
      background-color: transparent !important;
    }
    a {
      box-shadow: none;
    }
  }

  &:nth-last-child(2) {
    display: flex;
  }

  &.filter-search__clear-link,
  a {
    // display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    // width: 32px;
    // height: 32px;
    color: @text-color-grey;
    line-height: 16px;
  }

  &.active {
    display: flex;

    &:after {
      content: "из";
      width: 25px;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: @text-color-grey;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      .filter-search__clear-link,
      a {
        color: @text-color-grey;
      }
    }

    &.filter-search__clear-link,
    a {
      color: @text-color-grey;
    }
  }
}


.card__pagination-item {
  &.active {
    &:after {
      content: "из";
      width: 20px;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      color: #616161;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
