.favorites {
  .new-table__container {
    width: 100%;
		overflow: visible;
  }

  .profile {
    &__thead-th {
      &_name {
        width: 210px;
      }
    }
  }

  .new-filter-search.filter-search {
    padding-bottom: 0;
    border-bottom: 0;
  }

  .page__table-wrapper {
    margin-bottom: 0;
  }

  .filter-search__info-result-sorting-hide {
    width: 180px;
  }

  .map {
    padding-top: 0;
    .container {
      padding: 0px;
      max-width: 1128px;
    }
    &__header {
      display: none;
    }
  }

	.profile {
    &__thead {
      .asc {
        color: @accent-color;
      }

      a {
        font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: @text-color-grey;
        text-transform: initial;
      }

      th {
        &:first-child {
          width: 107px;
          max-width: 118px;
        }
        &:nth-child(2) {
          width: 305px;
        }
        &:nth-child(3) {
          width: 171px;
        }
        &:nth-child(4) {
          width: 192px;
        }
        &:nth-child(5) {
          width: 0px;
        }
      }

      &-th {
        padding: 16px 0;
        &_name {
          padding-left: 20px;
          padding-right: 20px;
        }
        &_location {
          padding-left: 19px;
        }
      }

      &-td {
        &_location {
          padding-left: 19px;
        }
        &_delete {
          text-align: center;
					position: relative;
          p {
            cursor: pointer;
            margin: 0 auto;
            &:hover {
              color: @accent-color;
            }
          }
        }
      }
    }
    &__tbody {
      &-td {
        &_name {
          padding-left: 20px;
          padding-right: 20px;
        }
        &_location {
          padding-left: 19px;
        }
      }
      &-td-type-title {
        max-width: 65px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
