.filter-button {

	&__container {
		flex-direction: column;
		letter-spacing: 0.0225em;
		font-weight: bold;
		font-size: 13px;
		line-height: 16px;
		padding-left: 16px;
		padding-right: 16px;
		padding-top: 16px;
		display: none;
		&.active {
			display: flex;
		}
	}

	&__send,
	&__clear {
		width: 160px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
		border-radius: 4px;
		cursor: pointer;
	}
	&__send {
		background: #DBAE63;
		text-transform: uppercase;
		color: #FFFFFF;
		margin-bottom: 8px;
		border: none;
		outline: none;
	}
	&__clear {
		background: #FFFFFF;
		color: #616161;
		&:hover {
			color: #616161;
		}
	}
}