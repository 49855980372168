.myslider {
  &.active {
    transform: translate(0px, 0px);
    opacity: 1;
  }

	&__watermark {
		position: absolute;
		top: 16px;
		left: 16px;
	}

  &__wrapper {
    width: 100%;
    height: 100vh;
    background: rgba(21%, 21%, 21%, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    z-index: 999;

    &.active {
      display: flex;
    }
  }

  &__container {
    border-radius: 4px;
    overflow: hidden;
    opacity: 1;
    transition: all 0.8s ease 0s;

    &.active {
      opacity: 1;
    }
  }

  &__track {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    // transition: all 0.4s ease-in-out;
  }

  &__item {
    min-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  &__button {
    display: flex;
  }

  &__button-item {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    button {
      outline: none;
      background: none;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      width: 32px;
      height: 32px;
      background: #363636;
    }

    &_left {
      transform: translate(120%, 0px) rotate(180deg);
      left: 0;
      transition: all 0.8s ease 0s;

      &.active {
        transform: translate(0px, 0px);
        opacity: 1;
      }
    }

    &_right {
      transform: translate(-120%, 0px);
      right: 0;
      transition: all 0.8s ease 0s;
    }
  }

  &__img-wrapper {
    position: relative;
  }

  &__img {
    width: auto;
    max-width: 744px;
    border-radius: 4px;
    //min-width: 238px;
		max-height: 650px;
		display: block;
  }

  &__item-des {
    margin: 0;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: rgba(255, 255, 255, 0.8);
    transform: translateY(-100%);
    padding-left: 17px;
		padding-right: 17px;
    border-radius: 0px 0px 4px 4px;
  }

  &__tools-container {
    display: flex;
    justify-content: center;
  }

  &__tools {
    display: inline-flex;
    align-items: center;
    background: #363636;
    border-radius: 2px;
    transform: translateY(-40px);
  }

  &__tools-count {
    padding-left: 16px;
    border-right: 1px solid #787878;
  }

  &__tools-count-text {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    height: 36px;
  }

  &__tools-count-span {
    width: 36px;
    text-align: center;
  }

  &__tools-zoom {
    cursor: default;

    button {
      outline: none;
      background: none;
      border: none;
      padding: 0px;
    }
  }

  &__tools-zoom-decrease {
    cursor: pointer;
    margin: 14px;
    width: 10px;
    height: 10px;
    position: relative;

    &:disabled {
      cursor: default;

      &.myslider__tools-zoom-decrease {
        &::after {
          background-color: #787878;
        }
      }
    }

    &::after {
      content: "";
      position: absolute;
      left: 0;
      width: 10px;
      height: 2px;
      background-color: #fff;
    }
  }

  &__tools-zoom-max {
    cursor: pointer;
    position: relative;
    height: 18px;
    width: 18px;

    &:disabled {
      cursor: default;

      &.myslider__tools-zoom-max {

        &:after,
        span:after,
        span:before {
          background-color: #787878;
        }
      }

      span {
        border: 2px solid #787878;
      }

    }

    &::after {
      content: "";
      position: absolute;
      bottom: 1px;
      right: -2px;
      background-color: #fff;
      width: 7px;
      height: 3px;
      transform: rotate(45deg);
    }

    span {
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      position: absolute;
      border: 2px solid #ffffff;
      top: 0px;

      &::after {
        content: "";
        position: absolute;
        top: 5px;
        left: 2px;
        background-color: #fff;
        width: 8px;
        height: 2px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 5px;
        left: 2px;
        background-color: #fff;
        width: 8px;
        height: 2px;
        transform: rotate(90deg);
      }
    }

    &.set-min {
      &.myslider__tools-zoom-max {
        span {
          &::before {
            background-color: transparent;
          }
        }
      }
    }
  }
  &__tools-zoom-increase {
    cursor: pointer;
    margin: 14px;
    width: 10px;
    position: relative;
    width: 10px;
    height: 10px;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 10px;
      height: 2px;
      background-color: #fff;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      width: 10px;
      height: 2px;
      background-color: #fff;
      transform: rotate(90deg);
    }

    &:disabled {
      cursor: default;

      &:after,
      &:before {
        background-color: #787878;
      }
    }
  }
  &__button-top {
    position: absolute;
    top: 62px;
    right: 32px;
    display: flex;
  }
  &__button-close {
    height: 32px;
    width: 32px;
    margin-left: 12px;
    background: #363636;
    position: relative;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    border: none;
    &::after {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 8px);
      width: 16px;
      height: 2px;
      border-radius: 4px;
      background-color: #fff;
      transform: rotate(-45deg);
    }
    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 8px);
      width: 16px;
      height: 2px;
      border-radius: 4px;
      background-color: #fff;
      transform: rotate(45deg);
    }
  }
  &__button-download {
    background: #363636;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
