.content-tabs {
  &__info {
    display: none;
    padding: 16px 24px;
		//overflow: hidden;
    &-no-padding {
      padding-top: 0;
      padding-bottom: 0;
    }

    &.active {
			display: block;
		}
  }

  &__info-wrapper {
    display: none;
    flex-wrap: wrap;

    &.active {display: flex;}
  }

  &__info-item {
    display: flex;
    width: 33.333%;

    &:nth-child(1n+4) {padding-top: 16px;}
  }

  &__info-title {
    margin-bottom: 0;
    font-size: 14px;
    color: #787878;
    display: flex;
    align-items: center;
  }

  &__info-des {
    font-weight: bold;
    font-size: 14px;
    color: @text-color-grey;
    margin-bottom: 0;
    margin-left: 10px;
    display: flex;
    align-items: center;
  }

  &__slider,
  &__video,
    {
    display: none;

    &.active {display: block;}
  }

  &__informant {
    display: none;

    &.active {display: inline-block;}
  }

  &__info-item-archive {}
  &__info-title-archive {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: @text-color-grey;
    margin-bottom: 19px;
  }
  &__info-des-archive {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: @text-color-grey;
  }
  &__info-des-archive-link {
    border-bottom: 1px dashed @accent-color;
    padding-bottom: 4px;
    margin-left: 4px;
  }
}
