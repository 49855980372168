.new-table {
  &.table--separated {
    .table__tbody {
      td {
        .table__td--cipher-text {
          margin-bottom: 4px;
          max-width: 85px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &:first-child {
          padding-left: 18px !important;
          padding-right: 13px !important;
          padding-top: 18px !important;
          padding-bottom: 20px !important;
        }

        &:nth-child(2) {
          padding: 20px 0px !important;
          padding-left: 13px !important;
          padding-right: 32px !important;
        }

        &:nth-child(3) {
          padding: 20px 0px !important;
          padding-left: 0px !important;
          padding-right: 0px !important;
        }

        &:nth-child(4) {
          padding: 20px 0px !important;
          padding-left: 0px !important;
          padding-right: 16px !important;
        }

        &:nth-child(5) {
          padding: 20px 16px !important;
        }

        &:last-child {
          padding: 20px 0px !important;
          padding-left: 16px !important;
          padding-right: 24px !important;
        }
      }
    }

    .table__td--location {
      .table__td--location-text {
        margin-bottom: 5px;

        &_first {
          color: @text-color-grey !important;
        }

        &:first-child {
          color: @text-color-grey;
          margin-bottom: 5px;
        }

        &:last-child {
          color: @date-color;
        }
      }
    }
  }

  .table__tbody {
    td {
      position: relative;
      

      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: #F9F9F9;
      }

      
    }
  }

  .table {
    &__td {
      color: @text-color-grey;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      &--favorite {
        padding-left: 26px;
        padding-right: 15px;
      }

      &--cipher {
        padding-left: 15px;
        padding-right: 17px;
        color: @date-color;
      }

      &--name {
        br {
          display: none;
        }

        padding-left: 17px;
        padding-right: 18px;
      }

      &--date {
        padding-left: 17px;
        padding-right: 23px;
        font-size: 13px;
        line-height: 14px;
      }

      &--type {
        padding-left: 24px;
        padding-right: 24px;
      }

      &--lang {
        padding-right: 20px;
        padding-left: 24px;
        text-align: left;
      }
    }

    &__td--fast-see {
      position: absolute !important;
      right: -32px;

      &:after {
        content: none !important;
      }

      .storage-table__open-popup-btn {
        margin-right: 0 !important;
        padding: 0 !important;
      }
    }

    &__td--name {
      font-weight: 500;
			padding: 15px 0 !important;
    }

    &__td-link {
      color: @text-color-grey;
      transition: .12s;
      font-size: 13px;
      line-height: 20px;

      // &:hover {
      //   color: #cf9d30;
      // }
    }

    &__td--image {
      width: 38px;
      height: 38px
    }

    &__td--cipher {
      font-size: 13px;
    }
  }
}
