.vue-filter {
	&__header {
		background-color: @bg-white;
		border-radius: 4px 4px 0px 0px;
		padding: 0 12px 0 24px;
		display: flex;
		align-items: center;
		height: 48px;
		border-bottom: 1px solid @border-color-new;
		
		&.border {
			border-radius: 4px 0px 0px 0px;
		}
	}

	&__title {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__title-name {
		font-weight: @font-bold;
		font-size: @default-size;
		line-height: @default-line;
		color: @text-color-grey;
		margin-bottom: 0;
	}

	.icon-title {
		width: @default-width;
		height: @default-height;
		stroke: @text-color-grey;
	}
}
