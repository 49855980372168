.vue-filter {
  &__selected {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 0 16px 12px;
  }

  &__selected-list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
  }

  &__selected-item {
    display: flex;
    list-style-type: none;
    background: @accent-color;
    border-radius: 20px;
    padding: 6px 12px;
    margin-bottom: 5px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  &__selected-item-name {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 100%;
    color: @color-white;
    display: flex;
    align-items: center;
  }

  &__selected-delete {
    margin-left: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: relative;
    background: none;
    border: none;
    outline: none;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 7px;
      right: 4px;
      height: 2px;
      width: 10px;
      background-color: @bg-white;
      border-radius: 50%;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  &__selected-clear {
    background: none;
    border: none;
    outline: none;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: @text-color-grey;
    position: relative;
    padding-right: 24px;
    white-space: nowrap;

    &:before,
    &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 10px;
      top: 8px;
      right: 8px;
      background-color: @bg-black-light;
      border-radius: 50%;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}
