.iframe-slider-viedo {
  &__overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(54, 54, 54, 0.6);
    z-index: 999;

    &.show {
      display: block;
    }
  }

  &__loaded {
    width: 100px;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 999;
    transform: translate(-50%, -50%);
  }

  &__container {
    display: flex !important;
    flex-direction: column;
    z-index: 1001;
  }

  &__inner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }

  &__btn {
    display: none;

    &.show {
      display: block;
    }

    button {
      outline: none;
      background: none;
      border-radius: 50%;
      border: none;
      cursor: pointer;
      width: 32px;
      height: 32px;
      background: #363636;
      z-index: 1001;
    }
  }

  &__btn-next {
    position: fixed;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1001;
  }

  &__btn-prev {
    position: fixed;
    left: 32px;
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
    z-index: 1001;
  }

  &__btn-close {
    z-index: 1001;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 8px);
      width: 16px;
      height: 2px;
      border-radius: 4px;
      background-color: #fff;
      transform: rotate(45deg);
    }

    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 8px);
      width: 16px;
      height: 2px;
      border-radius: 4px;
      background-color: #fff;
      transform: rotate(-45deg);
    }
  }

  &__btn-close-wrapper {
    position: fixed;
    right: 32px;
    top: 62px;
    z-index: 1001;
  }

  &__count {
    display: inline-flex;
    justify-content: center;
    border-radius: 2px;
    z-index: 1001;
    order: 2;
  }

  &__count-text {
    padding-left: 16px;
    height: 36px;
    border-radius: 2px;
    background: @accent-bg;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @addable-menu-link;
    margin-bottom: 0;
  }

  &__count-span {
    width: 36px;
    display: flex;
    justify-content: center;
  }

  &__count-span-first {}

  &__count-span-second {}
}
