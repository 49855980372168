.materials {
  grid-area: materials;
  margin: 10px 0 0;

  @media (min-width: @tablet) {
    margin: 0;
    padding: 3px 0 0 10px;
  }
}

.materials__header {
  margin: 0 0 24px;
}

.materials__list {
  list-style: none;
  padding: 0;
  margin: 0;
  &--anchor {
    margin-bottom: 30px;
  }
}



.materials__link {
  color: @accent-color;
  font-size: 1.6rem;
  padding: 9px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  &--anchor {
    position: relative;
    padding-left: 16px;
    &::before {
      content: '';
      display: block;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background-color: @accent-bg;
      position: absolute;
      left: 1px;
    }
  }
}

.materials__icon {
  margin: 0 11px 0 0;
  color: @accent-bg;
  &_logo {
    width: 100%;
    height: 100%;
  }
}

.materials__logo {
  margin-bottom: 20px;
}
