.new-popup.popup .thumbnail {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center
}

.new-popup.popup .thumbnail__wrapper {
    width: 302px
}

.new-popup .popup {
    position: relative
}

.new-popup .popup__wrapper {
    padding: 0;
    overflow: hidden
}

.new-popup .popup__close {
    position: absolute;
    top: 0;
    right: -44px;
    width: 36px;
    height: 36px;
    background-color: #fff;
    box-shadow: 0 1px 5px rgba(0, 0, 0, .2), 0 3px 4px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .14);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    border: 0;
    border-radius: 50%
}
