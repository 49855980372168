.sidebar {
    width: 100%;
    max-width: 192px;
    display: block
}

.sidebar.is-fixed {
    padding-top: 55px;
    z-index: 11;
}

.sidebar__content {
    position: relative
}

.sidebar__filter {
    margin-bottom: 20px
}

.sidebar__load-audio {
    position: absolute;
    right: 0
}