.filter-search {

	&__find {
		position: relative;
		&.hide {
			display: none;
		}
	}

  &__find-header {
    padding: 17px 22px 15px;
  }

  &__find-header-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  &__find-header-col {
    &:last-child {
      display: flex;
      align-items: center;
    }
  }

  &__find-header-title {
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: @text-color-grey;
    margin-bottom: 0;
  }

  &__find-header-clear {
    margin-bottom: 0;
    color: @text-color-grey;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    position: relative;
    padding-right: 24px;
    margin-right: 27px;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      right: 5px;
      top: 7px;
      width: 10px;
      height: 2px;
      border-radius: 4px;
      background: @text-color-grey;
      transform: rotate(45deg);
    }

    &:before {
      content: "";
      position: absolute;
      right: 5px;
      top: 7px;
      width: 10px;
      height: 2px;
      border-radius: 4px;
      background: @text-color-grey;
      transform: rotate(-45deg);
    }
  }

  &__find-header-choice {
    margin-bottom: 0;
    padding-right: 24px;
    position: relative;
    color: @accent-color;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      right: 10px;
      top: 8px;
      width: 9px;
      height: 2px;
      border-radius: 4px;
      background: @accent-color;
      transform: rotate(45deg);
    }

    &:before {
      content: "";
      position: absolute;
      right: 4px;
      top: 8px;
      width: 9px;
      height: 2px;
      border-radius: 4px;
      background: @accent-color;
      transform: rotate(-45deg);
    }
  }

  &__find-dropdown {
  	position: absolute;
  	top: 100%;
  	left: 0;
    width: 100%;
    background: @bg-white;
    z-index: 2;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 14%);
    padding: 16px 24px;
    display: none;

    &.active {
    	display: block;
    }

  }

  &__find-dropdown-item {
    display: flex;

    &:last-child {
      .filter-search__find-dropdown-title {
        margin-bottom: 16px;
      }
    }
  }

  &__find-dropdown-title {
    margin-bottom: 32px;
    min-width: 124px;
    margin-right: 26px;
    height: 24px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: @date-color;
  }

  &__find-dropdown-list {
    display: flex;
    padding-left: 0;
    margin-bottom: 27px;
    flex-wrap: wrap;
  }

  &__find-dropdown-list-item {
    list-style-type: none;
    background: @accent-color;
    border-radius: 20px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 12px;
    margin-right: 8px;
    margin-bottom: 5px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__find-dropdown-list-item-name {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 100%;
    color: @bg-white;
  }

  &__find-dropdown-list-item-close {
    margin-bottom: 0;
    cursor: pointer;
    width: 16px;
    height: 16px;
    margin-left: 8px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      right: calc(50% - 5px);
      width: 10px;
      height: 2px;
      border-radius: 2px;
      background: @bg-white;
      transform: rotate(45deg);
    }

    &:before {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      right: calc(50% - 5px);
      width: 10px;
      height: 2px;
      border-radius: 2px;
      background: @bg-white;
      transform: rotate(-45deg);
    }
  }
}
