.profile {
  &__data-popup {
  	position: fixed;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100vh;
  	background: transparent;
  	z-index: 5;
  	display: none;

  	&.open-popup {
    	display: block;
    }
  }

  &__data-popup-wrapper {
  	z-index: 6;
    position: fixed;
    width: 280px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: @bg-white;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 22px 24px;
    
  }

  &__data-popup-form {}

  &__data-popup-form-item {
    &_input {
      margin-bottom: 22px;

      &:nth-child(2) {
        margin-bottom: 36px;
      }
    }
  }

  &__data-popup-form-label {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    position: relative;

    &.error {
      .profile__data-popup-form-title-error {
        display: block;
      }
      .profile__data-popup-form-input {
        border-color: @font-error;
      }
    }

    input {
    	&:valid,
      &:focus {
        &+.profile__data-popup-form-title {
          color: @text-color-grey  !important;
        }
      }
      &:valid {
        &+.profile__data-popup-form-title {
          .profile__btn-show-password {
            display: block;
          }
        }
      }
    }
  }

  &__btn-show-password {
    width: 24px;
    height: 24px;
    position: absolute;
    right: 12px;
    top: 26px;
    cursor: pointer;
    display: none;

    .icon-close-eye-password,
    .icon-eye-password {
      width: 24px;
      height: 24px;
    }

    .icon-close-eye-password {
      display: block;
    }
    .icon-eye-password {
      display: none;
    }
  }

  &__data-popup-form-input {
    order: 2;
    background: @bg-white;
    border: 1px solid @border-color-new;
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    padding: 12px 14px;
    outline: none;
    font-weight: normal;
		font-size: 14px;
		line-height: 100%;
		letter-spacing: 0.0025em;
		color: @text-color-grey;


    &:valid,
    &:focus {
      border-color: @text-color-grey;
    }
  }

  &__data-popup-form-title {
    order: 1;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 6px;
    font-size: 12px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.0025em;
    font-weight: 400;
    color: @date-color;
  }

  &__data-popup-form-title-name {
    margin-bottom: 0;
  }

  &__data-popup-form-title-error {
    margin-bottom: 0;
    color: @font-error;
    display: none;
  }

  &__data-popup-form-btn {
    outline: none;
    border: none;
    background: @accent-color;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }

  &__data-popup-form-btn-text {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.0025em;
    color: @bg-white;
  }

  &__data-popup-form-cancel-wrapper {
    padding-top: 20px;
    border-top: 1px solid @border-color-new;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__data-popup-form-cancel {
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0.0025em;
    color: @accent-color;
    margin-bottom: 0;
    cursor: pointer;
    display: inline-flex;
  }

  &__data-popup-hide {
  	display: none;
  	&.show-hide {
  		display: block;
  	}
  }
	&__data-popup-hide-title {
		margin-bottom: 13px;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		letter-spacing: 0.0025em;
		color: @text-color-grey;
	}
	&__data-popup-hide-span {
		color: @accent-color;
	}
	&__data-popup-hide-btn {
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		border: 1px solid @accent-color;
		box-sizing: border-box;
		border-radius: 4px;
		font-weight: 500;
		font-size: 14px;
		line-height: 100%;
		display: flex;
		align-items: center;
		letter-spacing: 0.0025em;
		color: @accent-color;
	}
}
