.map__wrapper {
	.leaflet-popup {
		bottom: 5px !important;
		transition: none;
	}
	.leaflet-popup-content-wrapper {
		padding: 0;
	}
	.leaflet-popup-content {
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
		border-radius: 4px;
		margin: 0;
		padding: 0;
	}
	.leaflet-popup-date,
	.leaflet-popup-name {
		margin-top: 0;
		margin-bottom: 0;
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
		color: @text-color-grey;
		text-align: center;
	}
	.leaflet-popup-name {
		padding: 6px 8px;
	}
	.leaflet-popup-date {
		padding: 0 8px 6px;
	}
	.leaflet-popup-tip-container {
		display: none;
	}
	.leaflet-popup-close-button {
		display: none;
	}
	.leaflet-popup-click {
		position: absolute;
		left: calc(50% - 16px);
		bottom: -40px;
		min-width: 32px;
		height: 32px;
		background-color: @bg-white;
		padding: 7px 9px;
		border-radius: 24px;
		display: flex;
		align-items: center;
		justify-content: center;

		&.orange {
			background-color: @accent-color;
			padding: 9px 14px;
			.leaflet-popup-click-text {
				color: @color-white;
			}
		}

		img {
			width: 14px;
			height: 16px;
		}
	}
	.leaflet-popup-click-text {
		font-family: 'Roboto' !important;
		font-weight: 500 !important;
		font-size: 12px;
		line-height: 14px;
		color: @text-color;
		margin: 0;
		padding-left: 5px;
	}
	.leaflet-marker-icon {
		z-index: 600 !important;
		&._visible {
			z-index: 9999 !important;
		}
	}
	.leaflet-overlay-pane,
	.leaflet-marker-pane {
		z-index: initial !important;
	}
	.leaflet-overlay-pane {
		svg {
			z-index: 602;
		}
	}
	.leaflet-control-zoom {
		display: none;
	}
	.leaflet-control-scale {
		display: none;
	}
}