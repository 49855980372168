.btn {

  outline: none !important;

  &:hover {

    .btn--next-slider-video,
    .btn--next-slider-video {

      .icon-arrow-right,
      .icon-arrow-left {
        stroke: @addable-menu-link;
      }
    }
  }

  &--catalog {
    white-space: nowrap;
    padding: 8px;
    border: 1px solid @broadcrumbs-link;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    border-radius: 2px;
    display: flex;
    align-items: center;

    &:active,
    &:hover {
      color: @addable-menu-link;
      background-color: #cf9d30
    }

    &.icon:not(last-child) {
      margin-right: .45em
    }

    svg {
      fill: @addable-menu-link !important;
      height: 20px;
      width: 20px;
    }

    span {
      margin-left: 10px;
    }
  }

  &--next-slider-video,
  &--prev-slider-video {
    padding: 0;
    position: absolute;
    top: 50%;
    left: -14px;
    transform: translateY(-50%);
    font-size: 0;
    width: 28px;
    height: 28px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: @addable-menu-link;
    z-index: 10;

    button {
      outline: none;
    }

    .icon-arrow-left,
    .icon-arrow-right {
      flex: 0 1 12px;
      width: 10px;
      height: 10px;
      stroke: @date-color;
      fill: transparent
    }

    &:hover {
      border: 2px solid @addable-menu-link;

      .icon-arrow-left,
      .icon-arrow-right {
        stroke: @text-color;
      }
    }
  }

  &--next-slider-video {
    right: -14px;
    left: auto
  }

  &--none {
    // display: none
  }

  &--open-submenu-filters {
    display: none;
    outline: none;
    justify-content: center;
    align-items: center;
    padding: 3px 10px;
    transition: .12s;

    svg {
      fill: #757575;
      width: 10px;
      height: 6px;
    }

    &:hover {
      svg {
        fill: #7f5b00;
      }
    }
  }

  &--close-input-search-keywords {
    display: none;
    position: absolute;
    padding: 10px;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    background-color: @bg-white;
    outline: none !important;
    border: none !important;

    &.btn--active {
      display: block
    }
  }
}
