.document-gallery {
  &__tools {
    width: 100%;
    padding: 0px 156px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.3s ease;
  }

  .tools-item-wrapper {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
    background: @addable-menu-link;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }

    &.disabled {
      cursor: default;
    }
  }

  &__tools-item {
    display: flex;
  }

  &__tools-rotate {
    display: flex;
  }

  &__tools-rotate-item {
  	margin-right: 16px;
  }

  &__tools-book {
  	.icon-new-list {
  		display: none;
  	}
  	&.open-book {
  		.icon-new-book {
  			display: none;
  		}
  		.icon-new-list {
	  		display: block;
	  	}
  	}
  }

  &__tools-increase {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 97px;
    height: 32px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
    background: #FFFFFF;
    border-radius: 32px;
  }

  &__tools-increase-min {
  	position: relative;
    height: 32px;
    width: 32px;
    cursor: pointer;
  	&:before {
  		content: "";
  		position: absolute;
  		top: calc(50% - 1px);
  		left: calc(50% - 5px);
  		width: 10px;
  		height: 2px;
  		background: @text-color-grey;
  	}
    &.disabled {
      cursor: default;
      &:before {
        background: @border-color;
      }
    }
  }

  &__tools-increase-loop {
  	display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  	svg {
  		width: 18px !important;
  		height: 18px !important;
      fill: @text-color-grey;
  	}
    &:after {
      content: "";
      position: absolute;
      top: 7px;
      left: 5px;
      width: 6px;
      height: 2px;
      transform: rotate(90deg);
      background: @text-color-grey;
    }
    &.active {
      &:after {
        display: none;
      }
    }

  }

  &__tools-increase-max {
    width: 32px;
    height: 32px;
  	position: relative;
    cursor: pointer;

    &.disabled {
      cursor: default;
      &:before,
      &:after {
        background: @border-color;
      }
    }

  	&:before {
  		content: "";
  		position: absolute;
  		top: calc(50% - 1px);
  		left: calc(50% - 5px);
  		width: 10px;
  		height: 2px;
  		background: @text-color-grey;
  	}
  	&:after {
  		content: "";
  		position: absolute;
  		top: calc(50% - 1px);
  		left: calc(50% - 5px);
  		width: 10px;
  		height: 2px;
  		background: @text-color-grey;
  		transform: rotate(90deg);
  	}
  }

  &__tools-favorites {
  	margin-right: 16px;
  }

  &__tools-favorites-label {
    width: 32px;
    height: 32px;

    input:checked +.document-gallery__tools-favorites-svg {
    	fill: @accent-color;
    	stroke: @accent-color;
    }
  }

  &__tools-favorites-input {
  	height: 0;
  	width: 0;
  	opacity: 0;
  	overflow: hidden;
  }

  &__tools-favorites-svg {
    stroke: #616161;
  }

  &__tools-print {
  	margin-right: 16px;
  }


  &__tools-download {
  	margin-right: 16px;
  }


  &__tools-close {
  	svg {
  		width: 12px !important;
  		height: 12px !important;
  	}
  }

}
