.new-table {

	.icon-star {
    display: inline-block;
    width: 22px;
    height: 20px;
    fill: #616161;
	}

  .icon-melody {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 20px
  }

  .icon-pause,
  .icon-play {
    display: block;
    width: 24px;
    height: 25px;
    fill: transparent
  }

  .icon-pause {
    fill: @broadcrumbs-link;
  }

  .icon-angle-down {
    width: 6px;
    height: 10px;
    fill: @broadcrumbs-link;
    display: none;
  }

  .icon-eye {
    display: inline-block;
    width: 22px;
    height: 15px;
    fill: currentColor
  }

  .icon-camera {
    display: block;
    margin: 0 auto;
    width: 26px;
    height: 21px;
    fill: @broadcrumbs-link;
  }

  .icon-info {
    display: block;
    margin: 0 auto;
    width: 24px;
    height: 24px;
    fill: transparent
  }

  .icon-book {
    display: block;
    margin: 0 auto;
    width: 18px;
    height: 24px
  }

  .icon-add-image {
    display: block;
    margin: 0 auto;
    width: 26px;
    height: 21px
  }
}
