.footer-navigation {
  width: calc(~"50% - 12px");
}

.footer-navigation__header {
  padding: 0;
  margin: 0 0 12px;
  font-weight: 500;
  color: @accent-color-dark;
}

.footer-navigation__list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer-navigation__item {
  margin: 0 0 13px;

  &:last-child {
    margin-bottom: 0;
  }
}

.footer-navigation__link {
  font-size: 13px;
}
