.language {
  color: @accent-color;
  display: block;
  font-weight: 400;
  line-height: 24px;

  @media (min-width: @tablet) {
    margin: 0 0 0 32px;
  }
}
