.profile {

  &__type {
    padding-top: 24px;
  }

  &__type-wrapper {
    background: @bg-white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    padding: 15px 24px;
  }

  &__type-des {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__type-title {
    display: flex;
    align-items: center;
  }

  &__type-title-text {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 16px;
    color: @date-color;
    margin-right: 15px;
  }

  &__type-title-name {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: @text-color-grey;
  }

  &__type-info {
    display: flex;
    align-items: center;
  }

  &__type-info-title {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: @accent-color;
    margin-right: 6px;
  }

  &__type-info-dropdown {
    position: relative;

    &:hover {
      .profile__type-info-dropdown-container {
        display: block;
      }
    }
  }

  &__type-info-dropdown-title {
    margin-bottom: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid @accent-color;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @accent-color;
    cursor: pointer;
  }

  &__type-info-dropdown-container {
    position: absolute;
    bottom: calc(100% + 5px);
    left: calc(50% - 132px);
    width: 262px;
    background: @bg-white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    padding: 12px;
    display: none;
  }

  &__type-info-dropdown-des {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: @text-color-grey;
    margin-bottom: 0;
  }

  &__type-accept {
    padding-top: 15px;
  }

  &__type-accept-wrapper {
    font-size: 14px;
    line-height: 24px;
    color: @text-color-grey;
  }

  &__type-accept-title {
    font-weight: bold;
    margin-bottom: 15px;
  }

  &__type-accept-des {
    margin-bottom: 0;
    max-width: 504px;
  }

  &__type-accept-email {
    color: @accent-color;
  }
}
