.tombstone-detail {
	h4 {
		text-transform: none !important;
	}
	.thumbnail__list {
		max-width: 235px;
	}
}
.tombstone-find {
	.new-table__container {
		overflow: visible;
		width: 100%;
	}
	.pagination__item {

    .new-angle-left,
    .new-angle-right {
      width: 7px;
      height: 11px;
    }

    &:first-child,
    &:last-child {
      box-shadow: none !important;
      width: 24px !important;
      height: 24px !important;
    }
  }
	.quick-view-land {
		&__nav-pagination-list-item {
			&:nth-child(2) {
				color: @text-color-grey;
			}
		}
		&__nav-pagination-list-item-count {
			margin-bottom: 0;
		}
	}
}
