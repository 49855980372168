.map-search-dropdown {
  &__filter-dropdown {
    position: absolute;
    left: -100%;
    top: 56px;
    height: calc(100% - 56px);
    width: 360px;
    background: @bg-white;
    z-index: 6;
    transition: .3s ease;

    &.show {
      left: 0;
    }
  }

  &__filter-dropdown-wrapper {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: @bg-white;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: @bg-scroll;
      border-radius: 4px;
      outline: none;
    }
  }

  &__filter-dropdown-title {
    padding: 16px 24px;
    border-bottom: 1px solid @border-color-new;
    display: flex;
    align-items: center;
    justify-content: space-between;
		position: relative;

    &.cursor {
      cursor: pointer;
    }
  }

	&__filter-dropdown-title-count {
    color: #787878;
		margin-bottom: 0;
		position: absolute;
		right: 50px;
		top: calc(50% - 6px);
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;

		&.active {
      color: @accent-color;
    }
  }

  &__filter-dropdown-title-text {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #787878;
    margin-bottom: 0;

    &.active {
      color: @accent-color;
    }
  }

  &__filter-dropdown-title-arrow {
    position: relative;
    cursor: pointer;

    &:after,
    &:before {
      content: "";
      position: absolute;
      right: 0px;
      width: 10px;
      height: 2px;
      border-radius: 2px;
      background: @text-color-grey;
    }

    &:before {
      top: 2px;
      transform: rotate(45deg);
    }

    &:after {
      top: 8px;
      transform: rotate(-45deg);
    }

    &.active {

      &:after,
      &:before {
        background: @accent-color;
      }
    }
  }

  &__filter-dropdown-count-choice {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    color: @text-color-grey;
    margin-bottom: 0;
    margin-right: 20px;
  }

  &__filter-dropdown-name {
    padding: 10px 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    justify-content: space-between;

    &.choice {

      .map-search-dropdown__filter-dropdown-name-text,
      .map-search-dropdown__filter-dropdown-count-choice {
        color: @accent-color;
      }

      &:after,
      &:before {
        background: @accent-color;
      }
    }

    &:after,
    &:before {
      content: "";
      position: absolute;
      right: 20px;
      width: 10px;
      height: 2px;
      border-radius: 2px;
      background: @text-color-grey;
    }

    &:before {
      top: 17px;
      transform: rotate(45deg);
    }

    &:after {
      top: 23px;
      transform: rotate(-45deg);
    }
  }

  &__filter-dropdown-name-text {
    margin-bottom: 0;
    height: 20px;
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: @text-color-grey;
  }

  &__filter {
    position: absolute;
    left: -100%;
    top: 56px;
    height: calc(100% - 112px);
    width: 452px;
    background-color: @bg-white;
    z-index: 4;
    border-left: 1px solid @border-color-new;
    border-top: 1px solid @border-color-new;

    &.show-filters {
      left: 360px;
    }
  }

  &__filter-search {
    position: relative;
    width: 100%;
    padding: 8px 8px 0px;
    margin-bottom: 16px;

    .icon_search {
      position: absolute;
      top: 13px;
      right: 19px;
      color: @date-color;
    }
  }

  &__filter-search-input {
    width: 100%;
    padding: 8px 69px 8px 8px;
    border: none;
    background: #FDFDFD;
    border-radius: 4px;
    outline: none;

    &::placeholder {
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: @date-color;
    }
  }

  &__filter-list {
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
    overflow: auto;
    height: 508px;
    margin-right: 4px;

    &.alphabet {
      height: 426px;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: @bg-white;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: @bg-scroll;
      border-radius: 4px;
      outline: none;
    }
  }

  &__filter-dropdown-container {
    overflow: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 4px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: @bg-white;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: @bg-scroll;
      border-radius: 4px;
      outline: none;
    }
  }

  &__filter-list-wrapper {
    padding-left: 0;
    margin-bottom: 0;
    padding-left: 16px;
    padding-right: 16px;

    &_sub {
      //display: none;
      padding-right: 0;
      // padding-right: 32px;

      // .map-search-dropdown__filter-list-label {
      //   margin-right: 0;
      // }
    }

    &_sub-sub {
      //display: none;

      &.open {
        display: block;
      }
    }
  }

  &__filter-list-item {
    list-style-type: none;
    position: relative;

    &.open {
      // .map-search-dropdown__filter-list-arrow {
      //   &:before {
      //     transform: rotate(-45deg);
      //   }

      //   &:after {
      //     transform: rotate(45deg);
      //   }
      // }

      // .map-search-dropdown__filter-list-wrapper_sub {
      //   display: block;
      // }
    }
  }


  &__filter-list-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    margin-right: 32px;
    height: 40px;
  }

  &__filter-list-label-wrapper {
    position: relative;
    height: 24px;
    cursor: pointer;

    input:checked+.map-search-dropdown__filter-list-checkbox {

      &:after,
      &:before {
        display: block;
      }
    }
  }

  &__filter-list-name {
    height: 24px;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: @text-color-grey;
    margin-bottom: 0;
    padding-left: 32px;
  }

  &__filter-list-input {
    visibility: hidden;
    height: 0;
    width: 0;
    overflow: hidden;
  }

  &__filter-list-checkbox {
    position: absolute;
    left: 3px;
    top: 3px;
    width: 18px;
    height: 18px;
    border: 2px solid @text-color-grey;
    border-radius: 4px;

    &:before,
    &:after {
      display: none;
      content: "";
      position: absolute;
      height: 2px;
      background-color: @text-color-grey;
    }

    &:after {
      transform: rotate(45deg);
      top: 7px;
      left: 2px;
      width: 5px;
    }

    &:before {
      transform: rotate(-55deg);
      top: 6px;
      left: 4px;
      width: 10px;
    }
  }


  &__filter-list-arrow {
    position: absolute;
    width: 24px;
    height: 24px;
    right: 0px;
    top: 9px;
    margin-bottom: 0;
    cursor: pointer;

    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 10px;
      width: 8px;
      height: 2px;
      border-radius: 4px;
      background-color: @text-color-grey;
    }

    &:before {
      transform: rotate(45deg);
      left: 6px;
    }

    &:after {
      transform: rotate(-45deg);
      left: 10px;
    }

    &.active {
      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }

  }

  &__filter-list-label-count {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: right;
    color: @date-color;
    margin-bottom: 0;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px;
  }

  &__btn-clear {
    margin-bottom: 0;
    max-width: 148px;
    width: 100%;
  }

  &__btn-send {
    margin-bottom: 0;
    max-width: 148px;
    width: 100%;
  }

  &__btn-send-item,
  &__btn-clear-item {
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.0125em;
    width: 100%;
    height: 40px;
    margin-bottom: 0;
    cursor: pointer;
  }

  &__btn-send-item {
    background: @accent-color;
    color: @bg-white;
  }

  &__btn-clear-item {
    border: 1px solid @border-color;
    color: @text-color-grey;
  }
}
