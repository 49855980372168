.vue-filter {
  &__daterange {
    &.no-select {
      margin-top: 15px;
      display: block !important;
      height: auto !important;
    }
  }

	&__daterange-title {
		padding: 0;
		margin: 0px 24px 13px;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
		color: @date-color;
	}

  &__daterange-wrapper {
    padding: 0px 24px;
  }

  &__daterange-container {
    margin-bottom: 16px;
		display: flex;
    justify-content: center;
  }

  &__daterange-input {
    outline: none;
    border: none;
    width: 88px;
    text-align: center;

    &:focus {
      border-bottom: 1px solid @text-color-grey;
      height: 21px;
    }
  }

  &__daterange-btn {
    margin-top: 31px;
  }

  &__daterange-btn-item {
    background-color: @accent-color;
    border-radius: 4px;
    width: 100%;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: @font-bold;
    font-size: 13px;
    line-height: 100%;
    letter-spacing: 0.0225em;
    text-transform: uppercase;
    color: @color-white;
    cursor: pointer;
  }

  .noUi-handle {
    &.noUi-active {
      .noUi-touch-area {
        &:after {
          display: block;
        }
      }
    }
  }

  .noUi-touch-area {
    &:after {
      display: none;
      content: '';
      position: absolute;
      top: -50%;
      left: -50%;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: @bg-accent;
      opacity: 0.4;
      z-index: -1;
    }
  }
}
