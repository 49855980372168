.sharing-seal {
  position: absolute;
  top: 578px;
  z-index: 1;

  &__button {
    height: 36px;
    width: 36px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 0px 4px 4px 0px;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &[disabled] {
      cursor:  default;
      & svg {
        fill: #D8D8D8;
      }

      .new-search__submit-icon-path {
        fill: #D8D8D8 !important;
      }

      &:hover {

        & svg {
          .new-search__submit-icon-path {
            fill: #D8D8D8 !important;
          }

          fill: #D8D8D8 !important;
        }
      }

    }



    & svg {
      height: 24px;
      width: 24px;
      fill: @accent-color;
    }

    &:hover {
      // background-color: #FFE092;

      & svg {
        .new-search__submit-icon-path {
          fill: #FFE092 !important;
        }

        fill: #FFE092 !important;
      }
    }
  }
}

.sharing-seal {
  &.sticky {
    position: fixed;
    top: 148px;
  }
}

.sharing {
  position: absolute;
  top: 630px;
  z-index: 1;

  &.sticky {
    position: fixed;
    top: 200px;
  }

  &.seal {
    top: 680px;
  }
}

.sharing__button {
  height: 36px;
  width: 36px;
  padding: 8px 9px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 0px 4px 4px 0px;
  border: none;
  outline: none;

  & svg {
    height: 100%;
    width: 100%;
    fill: @accent-color;
  }

  &:hover {
    // background-color: #FFE092;

    & svg {
      fill: #FFE092;
    }
  }

  .sharing--active & {
    background-color: #ffffff;

    & svg {
      fill: @accent-color;
    }
  }
}

.sharing__list {
  list-style: none;
  padding: 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  margin-top: 8px;
  border-radius: 0px 4px 4px 0px;
  display: none;

  .sharing--active & {
    display: block;
  }
}

.sharing__link {
  display: flex;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;

    svg {
      fill: @accent-color  !important;
    }
  }

  & svg {
    fill: #616161 !important;
  }
}


.sharing__item {
  position: relative;

  button {
    outline: none;
    background: #ffffff;
    border: none;
  }
}
