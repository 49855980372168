.map-places {
  background: @bg-white;
  padding-right: 4px;

  .map_button_city {
    display:flex;
    justify-content: space-between;
    width:100%;

    .map_city_name {
      font-size:14px;
    }

    .map_city_district {
      font-size:12px;
      color: #616161;
      font-weight:400;
    }

  }


  @media (min-width: @desktop) {
    position: absolute;
    top: 56px;
    left: 0;
    width: 360px;
    height: calc(~"100% - 56px");
    z-index: 4;
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    transition: transform @transition-time;

    &--show {
      transform: translateX(0%);
    }
  }
}

.map-places__toggle {
  display: none;

  @media (min-width: @desktop) {
    border: none;
    display: block;
    width: 24px;
    height: 36px;
    border-radius: 0 4px 4px 0;
    background-color: @bg-white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    position: absolute;
    top: 45%;
    left: 100%;
    transform: translateY(-50%);

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 0 6px 6px;
      border-color: transparent transparent transparent @accent-color;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .map-places--show & {
      &::after {
        border-width: 6px 6px 6px 0;
        border-color: transparent @accent-color transparent transparent;
      }
    }

    &:focus {
      outline: none;
    }
  }
}

.map-places__list-wrapper {
  flex-grow: 1;
  position: relative;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: @bg-white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @bg-scroll;
    border-radius: 4px;
    outline: none;
  }
}

.map-places__list {
  width: 100%;
	height: 575px;
	//height: 724px;
  //height: 336px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  background-color: @bg-white;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: @bg-white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: @bg-scroll;
    border-radius: 4px;
    outline: none;
  }

  //@media (min-width: @tablet) {
  //  height: 384px;
  //}

  //@media (min-width: @desktop) {
  //  height: 520px;
  //}
}

.map-places__item {
  width: 100%;
  border-top: 1px solid @border-color-new;

  &--empty {
    padding: 6px 24px;
  }
}

.map-places__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border: none;
  background-color: @bg-white;
  text-align: left;
  padding: 6px 41px 6px 24px;
  position: relative;
  transition: background-color @transition-time;
  flex-shrink: 0;
  min-height: 47px;

  &--link {
    display: flex;
    align-items: center;



  }

  &:focus {
    outline: none;
  }

  &--back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 15px 24px;
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: @text-color-grey;

    &:hover {
      color: @accent-color;
    }

    .map-places__button-icon {
      fill: @text-color-grey;
      margin-right: 4px !important;
    }
  }

	&--unit {
		flex-direction: column;
		align-items: flex-start;
	}
}

.map-places__name {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 2px;
  color: @text-color-grey;

  .map-places__button:hover & {
    color: @accent-color;
  }
  span {
    font-size:12px;
    ont-weight: 400;
  }
}

.map-places__location {
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: @text-color-grey;

  .map-places__button:hover & {
    color: @accent-color;
  }
}

.map-places__info {
  color: @slide-desc-color;
  font-size: 1.2rem;
  line-height: 1.4rem;

  .map-places__button:hover & {
    color: @accent-color;
  }
}

.map-places__button-icon {
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);

  .map-places__button:hover & {
    fill: @accent-color;
  }

  .map-places__button--back & {
    position: static;
    transform: rotate(-180deg);
    margin: 0 8px 0 0;
  }
}

.map-places__tombstones-btn {
	padding-left: 0;
	padding-right: 0;
	border-bottom: 1px solid @border-color-new;
}

.map-places__cipher {
	margin-bottom: 6px;
	color: @date-color;
	display: block;
}

.map-places__date {
	margin-top: 13px;
	font-size: 13px;
	line-height: 14px;
	color: @text-color-grey;
	display: block;
}

.map-places__tombstones {
	display: flex;
	width: 100%;
	padding-top: 17px;
	padding-bottom: 24px;
	&:hover {
		.map-places__tombstones-name,
		.map-places__tombstones-date {
			color: @accent-color !important;
		}
	}
}
.map-places__tombstones-row {
	&:first-child {
		width: 100%;
		max-width: 72px;
		margin-right: 24px;
		display: flex;
		flex-direction: column;
    justify-content: center;
    align-items: center;
	}
}
.map-places__tombstones-gender {
	width: 15px;
	height: 15px;
	margin-bottom: 6px;
}
.map-places__tombstones-cipher {
	color: @date-color;
	font-size: 13px;
	line-height: 15px;
	text-transform: uppercase;
	margin-bottom: 0;
}
.map-places__tombstones-name {
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 11px;
	color: @text-color;
}
.map-places__tombstones-date {
	color: @text-color-grey;
	font-size: 14px;
  line-height: 16px;
	margin-bottom: 0;
}
.map-places__list-container {
	position: relative;
}
.map-places__list-tombstones {
	position: absolute;
	left: -100%;
	top: -48px;
	width: 360px;
	height: calc(100% + 48px);
	background-color: @bg-white;
	transition: all .3s ease;
	&._active {
		left: 0;
	}
}
.map-places__list-tombstones-wrapper {
	position: relative;
	padding: 20px 24px;
}
.map-places__list-tombstones-close {
	cursor: pointer;
	position: absolute;
	width: 12px;
	height: 12px;
	top: 22px;
	right: 26px;
	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 4px;
		left: 0;
		width: 12px;
		height: 2px;
		background-color: @bg-black-light;

	}
	&:after {
		transform: rotate(45deg);
	}
	&:before {
		transform: rotate(-45deg);
	}
}
.map-places__list-tombstones-cipher {
	font-size: 14px;
	line-height: 100%;
	margin-bottom: 22px;
	color: @text-color-grey;
	text-transform: uppercase;
}
.map-places__list-tombstones-img {
	width: 312px;
	height: 256px;
	margin-bottom: 17px;
	border-radius: 2px;
}
.map-places__list-tombstones-name {
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	margin-bottom: 5px;
	color: @text-color;
}
.map-places__list-tombstones-translate {
	margin-bottom: 25px;
	font-size: 16px;
  line-height: 16px;
  color: @date-color;
}
.map-places__list-tombstones-date {
	display: flex;
	margin-bottom: 15px;
}
.map-places__list-tombstones-date-item {
	display: flex;
	align-items: center;
	&:first-child {
		margin-right: 24px;
	}
}
.map-places__list-tombstones-date-text {
	margin-bottom: 0;
	font-size: 14px;
	line-height: 18px;
	color: @text-color-grey;
}
.new-sun,
.new-moon {
	width: 18px;
	height: 18px;
	margin-right: 6px;
}


.map-places__list-tombstones {
	.card__row {
		margin-top: 25px;
		width: 100%;
	}
	.make-starred__control {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50%;
		max-width: 148px;
		border: 1px solid @border-color;
		box-sizing: border-box;
		border-radius: 4px;
		margin-right: 8px;
	}
	.make-starred__v ~ svg {
		stroke: @text-color-grey;
		stroke-width: 2px;
		fill: @bg-white !important;
	}
	.make-starred__v:checked ~ svg {
		fill: @broadcrumbs-link !important;
		stroke: @broadcrumbs-link;
	}
	.make-starred__text {
		margin-bottom: 0;
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 14px;
		line-height: 100%;
		margin-left: 6px;
		color: @text-color-grey;
	}
	.quick-view-land__link-more {
		width: 50%;
		max-width: 148px;
		margin-left: 8px;
		display: flex;
    justify-content: center;
	}

}

.map-places__change-type-list {
	outline: none;
	background: @bg-white;
	border: none;
	border-top: 1px solid @border-color-new;
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: @accent-color;
}
