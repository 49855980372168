.map-sorting {
  padding: 16px 24px;
  border-right: 1px solid #f9f9f9;
  &__wrapper {
    display: flex;
    align-items: center;
  }
  &__title {
    margin-right: 8px;
    margin-bottom: 0;
    font-size: 13px;
    line-height: 16px;
    color: @date-color;
  }
  &__filter {
    position: relative;
  }
  &__filter-container {
    cursor: pointer;
    display: flex;
  }
  &__filter-title {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: @text-color-grey;
    &.page {
      color: @text-color-grey;
    }
    &:hover {
      color: @accent-color;
    }
  }
  &__filter-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background: @bg-white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 14%);
    padding: 16px 15px;
    border-radius: 4px;
    z-index: 20;
    margin-top: 12px;
  }
  &__filter-dropdown-item {
    font-size: 13px;
    line-height: 16px;
    color: @text-color-grey;
    margin-bottom: 15px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: @accent-color;
    }
  }
  &__filter-arrow-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 10px;
		cursor: default;
  }
  &__filter-arrow {
    width: 9px;
    height: 6px;
		fill: @border-color;
		cursor: default;
    &_first {
      transform: rotate(180deg) translateX(-1px);
      //fill: @border-color;
    }
		&._active {
			fill: @text-color-grey;
			cursor: pointer;
		}
    &._active:hover {
      fill: #DBAE63;
    }
    //&_second {
    //  fill: @text-color-grey;
    //}
  }
}

