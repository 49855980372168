.map-filters {
  position: relative;
  top: 0;
  z-index: 12;
  &.show-pdf {
    z-index: 2 !important;
  }
	&__keywords-result {
		position: absolute;
		top: 55px;
		z-index: 2;
		width: 100%;
		background-color: @bg-white;
		transition: all .2s ease;
		transform: translateY(-100%);
		&.show {
			transform: translateY(0);
		}
		&.width {
			max-width: calc(100% - 360px);
			left: 360px;
		}
		.map-filters__dropdown-keyword-list {
			flex-wrap: wrap;
		}
		.map-filters__dropdown-keyword-list-item {
			margin-bottom: 5px;
		}
		.map-filters__dropdown-keyword-more-word {
			min-width: 70px;
		}
	}
  &__toggle-count {
    position: absolute;
    right: -4px;
    top: -5px;
    width: 18px;
    height: 18px;
    background: @accent-color;
    border-radius: 50%;
    font-weight: bold;
    font-size: 9px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    letter-spacing: -0.1em;
    color: @addable-menu-link;
    border: 2px solid @addable-menu-link;
    margin-bottom: 0;
  }
  @media (min-width: @desktop) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: @bg-white;
    padding: 0;
    width: 100%;
    flex-shrink: 0;
    grid-area: filters;
  }
}
.map-filters__container {
	z-index: 2;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  width: 100%;
  padding: 0px 16px;
  background: @bg-white;
  &-item {
    display: flex;
    align-items: center;
  }
  &.active {
    justify-content: flex-end !important;
  }
}
.map-filters__count-location {
  margin-bottom: 0px;
  margin-left: 10px;
}
.map-filters__new-filters {
  display: flex;
  align-items: center;
  cursor: pointer;
  &.active {
    svg {
      //fill: @accent-color;
      stroke: @accent-color;
    }
    .map-filters__new-filters-text {
      color: @accent-color;
    }
  }
	&._fill {
		svg {
      fill: @accent-color;
      stroke: @accent-color;
    }
		.map-filters__new-filters-text {
      color: @accent-color;
    }
	}
  svg {
    stroke: @text-color-grey;
  }
  &-text {
    margin-bottom: 0px;
    margin-left: 10px;
    color: @text-color-grey;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
  }
}
.map-filters__toggle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #fff;
  border: none;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: @transition-time background-color;
  &--active {
    background-color: @accent-color;
  }
  &:focus {
    outline: none;
  }
  @media (min-width: @desktop) {
    display: none;
  }
}
.map-filters__toggle-icon {
  color: @accent-color;
  transition: @transition-time color;
  .map-filters__toggle--active & {
    color: #fff;
  }
}
.map-filters__list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: none;
  position: absolute;
  top: calc(~"100% + 8px");
  right: 0;
  z-index: 8;
  &--open {
    display: block;
  }
  @media (max-width: (@desktop - 1)) {
    background-color: #fff;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2),
      0px 6px 30px rgba(0, 0, 0, 0.12), 0px 16px 24px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    padding: 16px;
  }
  @media (min-width: @tablet) {
    top: calc(~"100% + 16px");
  }
  @media (min-width: @desktop) {
    position: static;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
  }
}
.map-filters__item {
  margin: 0 0 16px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: @desktop) {
    margin: 0 35px 0 0;
  }
}
.map-filters__name {
  width: 360px;
  flex-shrink: 0;
  position: relative;
  border-right: 1px solid @border-color;
  border-bottom: 1px solid @border-color;
}
.map-filters__input {
  width: 100%;
  height: 55px;
  display: block;
  background-color: #f5f5f5;
  border: none;
  padding: 0 64px 0 24px;
}
.map-filters__label {
  text-transform: uppercase;
  display: block;
  margin: 0;
  padding: 2px 0;
  white-space: nowrap;
  &--locality {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  }
  &--checkbox {
    padding: 0 0 0 30px;
    position: relative;
    cursor: pointer;
    line-height: 20px;
    transition: color @transition-time;
    font-weight: 500;
    &::before {
      content: "";
      width: 24px;
      height: 24px;
      display: block;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(./../images/icon_checkbox.svg);
      transition: background @transition-time;
    }
    input:checked ~ & {
      color: @accent-color;
      &::before {
        background-image: url(./../images/icon_checkbox--checked.svg);
      }
    }
  }
}
.map-filters__label-icon {
  display: block;
}
.map-filters__sorting-wrapper {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}
.map-filters__sorting-year {
	display: flex;
	align-items: center;
	margin-right: 16px;
}
.map-filters__sorting-year-title {
	margin-bottom: 0;
	margin-right: 8px;
	font-size: 13px;
	line-height: 24px;
	color: @text-color-grey;
}
.map-filters__sorting-year-input {
	width: 88px;
	height: 32px;
	border: 1px solid @border-color;
	box-sizing: border-box;
	border-radius: 4px;
	text-align: center;
	outline: none;

	&:focus {
		border: 1px solid #FFE092;
		filter: drop-shadow(0px 0px 4px rgba(179, 136, 42, 0.05));
	}

	&::placeholder {
		color: @date-color;
	}
}
.map-filters__sorting-year-span {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 24px;
	height: 24px;
	color: @text-color-grey;
}
.map-filters__sorting-year-from {
}
.map-filters__sorting-year-to {
}
