.new-filter-horizontal {
	display: flex;
	align-items: center;

	&__btn {
		margin-right: 24px;
		display: inline-flex;
		align-items: center;
		cursor: pointer;
		&.active {
			.new-filter-horizontal__btn-text {
				color: @accent-color;
			}
			.new-filter-horizontal-icon {
				stroke: @accent-color;
			}
		}
	}
	&__btn-text {
		margin-bottom: 0;
		margin-left: 10px;
		color: @text-color-grey;
	}
	&__dropdown {
		position: absolute;
		left: 0;
		top: calc(100% + 20px);
		width: 100%;
		height: 456px;
		background-color: #ffffff;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
		border-radius: 0px 0px 4px 4px;
		z-index: 10;
		display: none;
		&.dropdown-open {
			display: block;
		}
	}
	&__dropdown-row {
		display: flex;
		height: 100%;
	}
	&__dropdown-col {
		width: 282px;
	}
	&__dropdown-title {
		margin-bottom: 0;
		width: 100%;
		padding: 0px 24px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-weight: 500;
		font-size: 13px;
		line-height: 20px;
		color: @text-color-grey;
	}
	.tags__checkbox-time {
		color: @date-color;
	}
	.new-filter-horizontal-icon {
		stroke: @text-color-grey;
	}
	
}
.tags__dropdown-list-wrapper {
		height: calc(100% - 40px) !important;
		border: 1px solid @border-color-new !important;
	}