.favorites {
  position: relative;
	&.noLogin {
		.favorites__link {
			cursor: default;
		}
		.favorites__dropdown {
			display: none !important;
		}
	}
  &:hover {
    .favorites__dropdown {
      display: block;
    }
  }

	&__link {
		cursor: pointer;
	}

  &__dropdown {
    position: absolute;
    top: 40px;
    left: calc(50% - 78px);
    height: auto;
    min-width: 156px;
    padding: 20px 19px 12px;
    display: none;
    z-index: 2;

    &.add {
    	display: block;
    	left: calc(50% - 97px);
    	.favorites__dropdown-title {
    		display: none;
    		&_add {
    			display: block;
    		}
    	}
    }

  }

  &__dropdown-clippy {
    position: absolute;
    top: -14px;
    left: calc(50% - 13px);
    width: 25px;
    height: 15px;
    border-radius: 4px;
    background: @bg-white;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }

  &__dropdown-title {
    margin-bottom: 5px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: @text-color-grey;

    &_add {
    	width: 159px;
    	display: none;
    }
  }

  &__dropdown-list {
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
  }

  &__dropdown-list-item {
    display: flex;
    align-items: center;
    height: 24px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      .favorites__dropdown-list-icon {
        fill: @accent-color;
      }

      .favorites__dropdown-list-count {
        color: @accent-color;
      }
    }
  }

  &__dropdown-list-icon {
    width: 16px;
    height: 16px;
    fill: #DADADA;
  }

  &__dropdown-list-count {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.0225em;
    color: @date-color;
  }
}
