@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/roboto-v20-cyrillic_latin-regular.woff2') format('woff2'),
       url('../fonts/roboto-v20-cyrillic_latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../fonts/roboto-v20-cyrillic_latin-500.woff2') format('woff2'),
       url('../fonts/roboto-v20-cyrillic_latin-500.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Bold'), local('Roboto-Bold'),
       url('../fonts/roboto-v20-cyrillic_latin-700.woff2') format('woff2'),
       url('../fonts/roboto-v20-cyrillic_latin-700.woff') format('woff');
}
@font-face {
  font-family: 'lg';
  src: url("../fonts/lg.ttf") format("truetype"), 
  url("../fonts/lg.woff") format("woff"), 
  url("../fonts/lg.svg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
@font-face {
    font-family: 'Arial';
    src: url('../fonts/ArialMT.eot');
    src: url('../fonts/ArialMT.eot?#iefix') format('embedded-opentype'),
        url('../fonts/ArialMT.woff2') format('woff2'),
        url('../fonts/ArialMT.woff') format('woff'),
        url('../fonts/ArialMT.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

