.banner {
  height: 305px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &--text {
    background-color: @text-color;
    height: auto;
    min-height: 311px;
    border-bottom: 3px solid @accent-color;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    padding-bottom: 20px;
    position:relative;
  }
  &--text:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(30, 30, 30, 0.6), rgba(30, 30, 30, 0.6));
  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__header {
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    margin: 145px 0 5px;
    color: @addable-menu-link;
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    max-width: 580px;
    color: @addable-menu-link;

    & + & {
      margin-top: 1em;
    }
  }
}
.banner .container {
  position:relative;
  z-index:40;
}

