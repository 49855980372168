.dropdown-result-content {
  position: relative;

  &__wrapper {
    position: absolute;
    left: -22px;
    //top: 15px;
		top: 10px;
    width: calc(100% + 44px);
    background-color: @bg-white;
    z-index: 2;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
    max-height: 227px;
    overflow: auto;
    margin-right: 4px;

		&.tags {
			top: 0;
		}

    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: @bg-scroll;
      border: 4px @bg-white solid;
      border-radius: 4px;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 16px 0px 6px 24px;

    &:not(:last-child) {
      border-bottom: 1px solid @border-color-new;
    }
  }

  &__title {
    margin-bottom: 10px;
    min-width: 124px;
    margin-right: 25px;
    font-weight: @font-medium;
    font-size: 13px;
    line-height: 16px;
    color: @date-color;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
  }

  &__list-item {
    display: flex;
    align-items: center;
    height: 24px;
    margin-bottom: 10px;
    padding: 0 12px;
    background-color: @bg-accent;
    border-radius: 20px;
    position: relative;
    margin-right: 10px;
  }

  &__list-name {
    font-size: 13px;
		padding-right: 20px;
    margin-bottom: 0;
    color: @color-white;
  }

  &__list-delete {
    position: absolute;
    cursor: pointer;
    right: -5px;
    top: 0;

    &:after,
    &:before {
      content: '';
      position: absolute;
      top: 11px;
      right: 16px;
      width: 10px;
      height: 2px;
      border-radius: 2px;
      background-color: @bg-white;
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }
  }
}
