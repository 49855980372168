.document-gallery {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: @addable-menu-link;
  z-index: 9999;

  &.open-gallery {
    display: block;
  }

  &__wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }

  &__container {
    width: 100%;
    transition: 0.3s ease;

    &.active {
      width: calc(100% - 228px);

      .document-gallery__tools {
        padding: 0px 40px;
      }
    }
  }

  &__header {
    height: 72px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: @addable-menu-link;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  }

  &__header-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #616161;
    max-width: 766px;
    padding: 0px 10px;
    margin-bottom: 0;
  }

  &__content-main {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: calc(100vh - 160px);
    height: calc(100vh - 160px);
    overflow: auto;

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      display: none;
    }
  }

  &__content-main-img-wrapper {
    display: flex;
    justify-content: center;
    //overflow: auto;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      cursor: pointer;
    }

    &::-webkit-scrollbar-track {
      background: @bg-white;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #E5E5E5;
      border-radius: 4px;
      outline: none;
      cursor: pointer;
    }
  }

  &__content-main-img {
    max-width: 744px;
    max-height: 822px;
    object-fit: cover;
  }

  &__content-main-img-rotate {
    display: flex;

    // overflow: auto;
    &.open-book {
      .document-gallery__content-main-img {
        max-width: 564px;
      }
    }
  }
}
