.table {
  &__th {
    &--archive {
      width: 32px;
      padding: 0
    }

    &--cipher {
      padding-left: 9px;
    }

    &--catalog {
      width: 30px
    }

    &--fio-and-location {
      display: inline-flex;
      align-items: center;
      width: 381px;
    }

    &--monuments {
      width: 190px
    }

    &--favs {
      width: 52px;
      text-align: center
    }

    &--photo {
      width: 78px
    }

    &--fio {
      width: 380px
    }

    &--place {
      width: 220px;
    }

    &--image {
      width: 64px
    }

    &--archive {
      width: 32px;
      padding: 0
    }

    &--name {
      width: 253px
    }

    &--geography {
      width: 190px
    }

    &--cipher {
      width: 93px
    }

    &--type-material {
      width: 145px
    }

    &--lang {
      width: 70px
    }

    &--square,
    &--year-born {
      width: 110px;
      font-weight: normal;
      font-size: 13px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #616161;
    }

    &--info {
      width: 68px
    }

    &--catalog,
    &--more {
      width: 44px;
      padding-left: 0;
      padding-right: 0;
      text-align: center
    }

    &--monuments {
      width: 190px
    }

    &--favs {
      width: 52px;
      text-align: center
    }
  }
}
