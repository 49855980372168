.map-button {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  &.full-map {
    position: fixed !important;
    right: 32px;
    z-index: 10002;
  }
  &__full {
    padding-bottom: 8px;
  }
  &__full-btn {
    cursor: pointer;
    background: @bg-white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 2px;
    width: 32px;
    height: 32px;
    position: relative;
    &.active {
      .icon-fullscreen-map_small {
        display: block;
      }
      .icon-fullscreen-map_big {
        display: none;
      }
    }
    &:hover {
      background: @accent-color;
      .icon-fullscreen-map {
        fill: @bg-white;
      }
    }
    .icon-fullscreen-map {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 4px;
      left: 4px;
      fill: @text-color-grey;
      &_small {
        display: none;
      }
    }
  }
  &__scale {
    background: @bg-white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 2px;
  }
  &__scale-increase,
  &__scale-decrease {
    cursor: pointer;
    width: 32px;
    height: 32px;
    position: relative;
    &.disabled {
      cursor: default;
      &:after,
      &:before {
        background-color: @date-color;
      }
      &:hover {
        background: @bg-white;
        &:after,
        &:before {
          background-color: @date-color;
        }
      }
    }
    &:hover {
      background: @accent-color;
      &:after,
      &:before {
        background-color: @bg-white;
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 15px;
      left: 8px;
      width: 16px;
      height: 2px;
      border-radius: 2px;
      background-color: @text-color-grey;
    }
  }
  &__scale-increase {
    &:after {
      content: "";
      position: absolute;
      top: 8px;
      left: 15px;
      width: 2px;
      height: 16px;
      border-radius: 2px;
      background-color: @text-color-grey;
    }
  }
}
