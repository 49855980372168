.map-search-dropdown {
  &__result {
    position: absolute;
    top: 56px;
    right: 0;
    background: @bg-white;
    width: calc(100% - 360px);
    z-index: 2;
    border-left: 1px solid @border-color-new;
  }
  &__result-header {
    padding: 16px 24px;
    border-bottom: 1px solid @border-color-new;
  }
  &__result-header-delete {
    height: 16px;
  }
  &__result-header-delete-title {
    display: inline-flex;
    cursor: pointer;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: @text-color-grey;
    padding-right: 30px;
    position: relative;
    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 7px;
      right: 6px;
      width: 10px;
      height: 2px;
      border-radius: 6px;
      background-color: @text-color-grey;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
  &__result-content {
    max-height: 519px;
    overflow: auto;
    margin-right: 4px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      background: @bg-white;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: @bg-scroll;
      border-radius: 4px;
      outline: none;
    }
  }
  &__result-content-item {
    display: flex;
    padding: 12px 24px 4px;
		&:not(:first-child) {
			border: 1px solid @border-color-new;
		}
  }
  &__result-content-item-title {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: @date-color;
    min-width: 124px;
    padding-right: 26px;
    display: flex;
    align-items: center;
    height: 24px;
  }
  &__result-content-list {
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
  }
  &__result-content-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    padding: 5.5px 12px;
    background: @accent-color;
    border-radius: 20px;
    margin-right: 8px;
    margin-bottom: 8px;
  }
  &__result-content-list-title {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: @addable-menu-link;
		text-transform: capitalize;
    margin-right: 8px;
  }
  &__result-content-list-delete {
    margin-bottom: 0;
    cursor: pointer;
    position: relative;
    width: 17px;
    height: 17px;
    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 8px;
      right: 4px;
      width: 10px;
      height: 2px;
      border-radius: 6px;
      background-color: @addable-menu-link;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
