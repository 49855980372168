.map-search {
  height: 48px;
  flex-shrink: 0;
  position: relative;
  grid-area: search;
  position: relative;
  z-index: 10;
  display: flex;
  background-color: @bg-white;
	z-index: 13;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

  @media (min-width: @desktop) {
    height: 56px;
  }

  &__wrapper {
    width: 100%;
    display: flex;
  }

  &__input {
    display: block;
    width: calc(100% - 16px);
    height: 100%;
    padding: 16px;
    border: none;
    height: 40px;
    //background-color: #FDFDFD;
    border-radius: 4px;
    font-size:13px;

    &:focus {
      outline: none;
    }

    @media (min-width: @desktop) {
      border-right: 1px solid @border-color-new;
      padding: 9px 32px 12px 16px;
      margin: 8px;
    }
  }
  &__icon {
    position: absolute;
    top: 50%;
    right: 21px;
    transform: translateY(-50%);
    color: @color-path;
  }

  &__filter {
    display: flex;
    align-items: center;
    justify-content: center;
    background: @bg-white;
    padding-left: 20px;

    &.fill {
      .map-filters__toggle-icon {
        stroke: @accent-color;
        fill: @accent-color;
      }
    }
  }

  &__filter-item {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    .map-filters__toggle-icon {
      stroke: @text-color-grey;
    }

    &.active {
      .map-filters__toggle-icon {
        stroke: @accent-color;
      }
    }
  }
}
