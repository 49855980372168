.cemetery {
  .pagination__item {

    .new-angle-left, 
    .new-angle-right {
      width: 7px;
      height: 11px;
    }

    &:first-child,
    &:last-child {
      box-shadow: none !important;
      width: 24px !important;
      height: 24px !important;
    }
  }
  .filter-search__info-result-sorting {
    padding-left: 24px;
    border-left: 1px solid @border-color;
  }

  .map {
    padding-top: 0;

    .container {
      padding-right: 0;
      padding-left: 0;
      max-width: 1128px;
    }

    &__header {
      display: none;
    }
  }

  .table-tile__item-title {
    min-height: auto;
    font-size: 16px;
  }
}

.cemetery-detail {
	.new-table__container {
		overflow: visible !important;
	}
  .new-table {

    .storage-table__cell-name-link {
      width: 310px !important;
    }

    .table {

      &__th--name {
        width: 310px !important;
      }

      &__td--cipher {
        color: @date-color;
      }

      &__td--name {
        padding-bottom: 0 !important;
      }

      &__td--name-text {
        display: block;
        margin-top: 3px;
        font-size: 14px;
        line-height: 16px;
        color: @text-color-grey;
        font-family: 'Arial', sans-serif;
      }

      &__td-link {
        margin-bottom: 0;
      }

      &__td--date-number,
      &__td--date-translate,
      &__td--type-location,
      &__td--type-contry,
      &__td--lang-tag {
        margin-bottom: 0;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
      }

      &__td--date-translate,
      &__td--type-contry {
        color: @date-color;
      }

      &__td--date-number,
      &__td--date-translate {
        text-align: left;
        font-family: 'Arial', sans-serif;
      }

      &__td--lang-tag {
        width: 156px;
        padding-right: 24px;
      }

      &__td--lang {
        padding-right: 0;
      }
    }
  }

  .filters__dropdown {
    max-width: 452px !important;
  }

  .filters__dropdown_datapicker {
    max-width: 248px !important;
    height: 390px !important;

    .filters__result-inner {
      height: 360px !important;
      overflow: initial !important;
    }
  }

  .new-filter-search .checkbox {
    height: auto !important;
    min-height: 46px !important;
  }

  .filter-search__info-result-sorting-hide {
    min-width: 150px;
  }

  .new-table {
    &.hide {
      display: none;
    }
  }

  .new-table__container {
    width: 100%;
  }

  .table-tile {
    width: 100%;
		display: block !important;

    &.hide {
      display: none !important;
    }
  }

  .table-tile__item.four-column-table {
    width: 33.333%;
  }

  .map {
    padding-top: 0;
    .container {
      max-width: 1128px;
      padding: 0px;
    }
    &__header {
      display: none;
    }
  }
}
