.card__content-wrapper {
  padding: 27px 24px 24px 47px;
  border-top: 1px solid #F9F9F9;
}

.card__slider {
  max-width: 387px;
}

.gallery {

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
  &__list-wrapper {
  	max-width: 387px;
    margin: 0 auto;
    height: 408px;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__item {
    list-style-type: none;
    overflow: hidden;
    height: 400px;
    max-height: 408px;
    display: flex;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      max-width: 387px;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  &__action-name {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    display: block ;
    align-items: center;
    text-align: center;
    color: @date-color;
    margin-bottom: 13px;
  }
}



.thumbnail {
  position: relative;
  padding: 0 50px;
  height: 57px;
  max-width: 387px;
  margin: 0 auto;
}

.thumbnail__wrapper {
  position: relative;
  // width: 426px;
  width: 286px;
  height: 100%;
  overflow: hidden
}

.thumbnail__list {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style-type: none;
  display: flex;
  z-index: 2;
  transition: .12s ease-out;
  max-width: calc(100% - 65px);
}

.thumbnail__item {
  position: relative;
  margin-right: 20px
}

.thumbnail__item:last-child {
  margin-right: 0
}

.thumbnail__item:hover .thumbnail__count {
  // transform: scale(1.9)
}

.thumbnail__item:hover.tns-nav-active .thumbnail__count {
  transform: scale(1)
}

.thumbnail__item.tns-nav-active .thumbnail__count {
  font-size: 14px;
  line-height: 20px;
  // width: 100%;
  // height: 100%;
  background: @accent-color;
}

.thumbnail__item.tns-nav-active .thumbnail__image {
  border: 1px solid @accent-color;
}

.thumbnail__image {
  width: 56px;
  height: 56px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  cursor: pointer;
  text-align: center;
  border: 1px solid #D8D8D8;
  box-sizing: border-box;
}

.thumbnail__image img {
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail__count {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 0 4px 0 0;
  background-color: #616161;
  font-weight: 700;
  font-size: 10px;
  line-height: 20px;
  mix-blend-mode: normal;
  color: #fff;
  transform-origin: 0 100%
}

.thumbnail__nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  outline: none
}

.thumbnail__nav-gallery {
  outline: none
}

.thumbnail__button {
  border: 0;
  background-color: transparent;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  width: 24px;
  height: 24px;



}

.thumbnail__button--next,
.thumbnail__button--next-row,
.thumbnail__button--prev,
.thumbnail__button--prev-row {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

}

.thumbnail__button--next-row,
.thumbnail__button--prev-row {
  svg {

    display: none;
  }

}

.thumbnail__button--next-row:before,
.thumbnail__button--prev-row:before {
  content: url("./../images/svg/new-double-arrow.svg");
  position: absolute;
  top: 50%;
  width: 14px;
  left: 50%;
  height: 14px;
  transform: translateY(-50%);
  // background-color: #616161;
  // opacity: .54

}

.thumbnail__button--prev-row:before {
  transform: rotate(180deg) translateY(-30%);
  top: 0;

}

.thumbnail__button--prev {
  left: 20px
}

.thumbnail__button--next {
  right: 20px
}

.thumbnail__button--prev-row {
  left: 0
}

.thumbnail__button--prev-row:before {
  left: 4px
}

.thumbnail__button--next-row {
  right: 0
}

.thumbnail__button--next-row:before {
  right: 4px
}

.thumbnail__button:hover:before {
  // background-color: #e8b859
}

.thumbnail__button:hover .thumbnail__svg {
  fill: #e8b859
}

.thumbnail__button:active:before {
  background-color: #7f5b00
}

.thumbnail__button:active .thumbnail__svg {
  fill: #7f5b00
}

.thumbnail__button:disabled:before {
  // background-color: #616161;
  // opacity: .54
}

.thumbnail__button:disabled .thumbnail__svg,
.thumbnail__svg {
  fill: #616161;
  // opacity: .54
}
