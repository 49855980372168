.filter-range {
	&__wrapper {
		padding: 8px 24px 0px;
		border: 1px solid @border-color-new;
		border-bottom: none;
		height: calc(100% - 40px);
		&_main {
			max-width: 248px;
		}
	}
	&__input-wrapper {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
	}
	
	&__input {
		max-width: 105px;
		outline: none;
		border: none;
		border-bottom: 1px solid @border-color-new;
		text-align: center;
		&:focus {
			border-bottom: 1px solid @text-color-grey;
		}
	}

	&__btn {
		padding-top: 30px;
	}
	&__btn-item {
		cursor: pointer;
		background: @accent-color;
		border-radius: 4px;
		width: 100%;
		height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		outline: none;
		border: none;
		color: @addable-menu-link;
	}
}

.noUi-horizontal .noUi-handle {
  height: 16px;
  width: 16px;
  right: -8px;
  top: -8px;
  outline: none;

  &:after,
  &:before {
    content: none;
  }
}

.noUi-handle {
  border: 2px solid @bg-white;
  border-radius: 50%;
  background: @accent-color;
}

.noUi-connect {
	background: @accent-color;
}
.noUi-target {
	background: @border-color;
	border: none;
}
.noUi-horizontal {
	height: 2px;
}