.block-about {
  position: relative;
  padding: 61px 0 0px;

  &--special-project {
    background-color: transparent;
    box-shadow: none;
    padding: 0;
  }
}

.block-about__content {
  @media (min-width: @tablet) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    // display: grid;
    // grid-template-columns: 6fr 3fr;
    // grid-column-gap: 24px;
    // grid-row-gap: 24px;
    // grid-template-areas:
    //   "header header"
    //   "descriptions materials"
    //   "descriptions public-stuff";
  }
}

.block-about__header {
  margin: 0 0 32px;
}

.block-about__descriptions {
  font-size: 1.6rem;
  background-color: #fff;
  // padding: 24px 24px 16px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  grid-column-gap: 24px;
  grid-row-gap: 25px;
  position: relative;
  padding-right: 4px;


  @media (min-width: @tablet) {
    // width: calc(~"66.666666% - 12px");
    width: 100%;
    max-width: 743px;
  }

  // @media (min-width: @desktop) {
  //   width: calc(~"66.666666% - 8px");
  // }

  &>* {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.block-about__descriptions-line {
  width: 100%;
  height: 36px;
  background: linear-gradient(0deg, #FFFFFF 28.13%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
  max-width: calc(100% - 8px);
}

.block-about__descriptions-container {
  max-height: 253px;
  overflow-y: auto;
  opacity: 1;
  background-color: #fff;
  padding: 0px 12px 0px 24px;
  margin-top: 15px;
  margin-bottom: 5px;


  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  // border-radius: 4px;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;

  }

  &::-webkit-scrollbar-thumb {
    background: #F0F0F0;
    border-radius: 4px;
    outline: none;
  }
}

.block-about__descriptions p {
  margin: 0 0 9px;
  font-size: 1.6rem;
  line-height: 2.4rem;

}

.block-about__descriptions ul {
  list-style: none;
  padding: 0;
  margin: 0 0 8px;
}

.block-about__descriptions li {
  padding-left: 15px;
  position: relative;
  line-height: 2.4rem;
  margin-bottom: 8px;

  &::before {
    content: '-';
    position: absolute;
    left: 0;
  }
}

.block-about__aside {
  @media (min-width: @tablet) {
    width: calc(~"33.333333% - 16px");
  }
}

.block-about__public-stuff {
  background-color: #fff;

  @media (min-width: @tablet) {
    margin: 4px 0 0;
    padding:14px 7px 14px 24px !important;
  }
}
.public-stuff.block-about__public-stuff {
  padding:14px 7px 14px 24px !important;
}
