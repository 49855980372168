.card {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  transition: box-shadow @transition-time;
  padding-bottom: 5px;

  &:hover {
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.14);
  }

  &__img-wrapper {
    width: 100%;
    height: 233px;
    border-bottom: 1px solid @border-color-new;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__about {
    padding: 19px 24px 22px;
    max-height: 168px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }

  &__name {
    margin: 0 0 9px;
    transition: color @transition-time;
    // max-height: 71px;
    overflow: hidden;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;

    .card:hover & {
      color: @accent-color;
    }
  }

  &__additional {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: @date-color;
    margin: auto 0 0;

    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 16px;
    }
  }

  &__type {
    font-size: 14px;
    line-height: 16px;
    margin: 0 0 12px;
    color: @accent-color;
  }

  &__hint {
    text-align: right;
    text-transform: uppercase;
    color: @accent-color;
    font-weight: 500;
    opacity: 0;
    transition: opacity @transition-time;
    padding: 0;
    margin: 1em 0 0;

    .card:hover & {
      opacity: 1;
    }
  }

  //Новые стили -------------------------------------------------

  &__title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-bottom: 32px;
  }

  &__title {
    position: relative;
    margin-top: 28px;
    margin-bottom: 24px;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    color: #616161;
  }

  &__section {
    // margin-bottom: 28px;
    // background: #FFFFFF;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);


    &-special {
      padding: 20px 0px;
      background: #FFFFFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    }

    &:last-child {
      margin-bottom: 0
    }
  }

  &__section-container {
    border-radius: 4px;
    padding: 20px 24px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  }

  &__col {
    &--label {
      flex: 1 1 180px;
      max-width: 168px;
      font-size: 14px;
      line-height: 24px;
      color: #A7A7A7;
    }
    &--text {
      flex: 1 1;
      display: flex;
      align-items: center;
      p {
        display: flex;
        align-items: center;
        margin-bottom: 0;
      }
    }
    &--text-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    &--text-container-title {
      padding-bottom: 5px;
      margin-bottom: 0;
      border-bottom: 1px dashed @accent-color;
      cursor: pointer;
      font-size: 14px;
      line-height: 100%;
      color: #616161;

      span {
        color: @date-color;
      }

      &:hover {
        color: @accent-color;

        span {
          color: @accent-color;
        }
      }
    }
    &--text-container-title-link {
      font-weight: 500;
      font-size: 13px;
      line-height: 15px;
      display: flex;
      align-items: center;
      color: @accent-color;

      &:hover {
        svg {
          fill: @broadcrumbs-link  !important;
        }
      }

      svg {
        fill: @accent-color  !important;
        margin-right: 6px;
      }
    }
  }


  &__row-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 24px;
    padding-right: 18px;
    padding-bottom: 19px;
    border-bottom: 1px solid #F6F6F6;
  }

  &__row-header-item {
    display: flex;
    align-items: center;
  }

  &__row-header-number {
    display: flex;
    align-items: center;
    color: #616161;

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin-left: 12px;
    }

    svg {
      fill: #616161 !important;
    }
  }

  &__row-header-location {
    margin-left: 36px;
    display: flex;
    align-items: center;
    color: #616161;
    cursor: pointer;

    span {
      padding-bottom: 5px;
      border-bottom: 1px dashed @accent-color;
      font-weight: 500;
      font-size: 13px;
      line-height: 100%;
      margin-left: 12px;
    }

    svg {
      fill: #616161 !important;
    }

    &:hover {
      color: @accent-color;

      svg {
        fill: @accent-color  !important;
      }
    }
  }

  &__row-name {
    padding: 17px 24px 19px 24px;

    border-bottom: 1px solid #F6F6F6;

    &:last-child {
      border-bottom: none;
    }
  }

  &__row-name-title {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #363636;
    margin-bottom: 3px;
  }

  &__row-name-translate {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 16px;
    color: #A7A7A7;
    padding-bottom: 17px;
  }

  &__row-name-time {
    display: flex;
  }

  &__row-name-time-item {
    padding-right: 25px;
    display: flex;
    align-items: center;

    span {
      margin-left: 6px;
    }

    svg {
      width: 18px;
      height: 18px;
    }

    &:last-child {
      padding-right: 0;
    }
  }

}

.popup .card,
.popup .card:hover {
  box-shadow: none;
}
