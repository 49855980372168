.collection {
  .pagination {
    .pagination__item_arrow-prev {
      &:first-child,
      &:last-child {
        box-shadow: none !important;
        width: 32px !important;
        height: 32px !important;
      }
    }
  }
  .pagination__item {
    .new-angle-left,
    .new-angle-right {
      width: 7px;
      height: 11px;
    }

  }
  .page__filter-search {
    margin-right: 0;
  }

  .filter-search__info-result {
    padding-bottom: 16px;
  }

  .new-filter-horizontal__dropdown {
    top: 100%;
  }
}

.collection-main {
  .new-search-main {
    position: relative;
    box-shadow: none;
    padding-top: 0;

    .icon-search {
      top: 10px;
    }
  }
}

.collection-info {
  // .cemetery-tabs__title {
  //   margin-bottom: 10px;
  // }
  .content-tabs__image-title {
    font-size: 40px;
    line-height: 47px;
    letter-spacing: 0.02em;
    text-transform: inherit;
  }

  .card {
    box-shadow: none !important;

    &:hover {
      box-shadow: none !important;
    }
  }
  .pagination {
    .pagination__item_arrow-prev {
      &:last-child {
        margin-left: 25px;
      }
    }
  }
  .pagination__item {
    &_arrow-prev {
      margin-right: 25px;
    }
  }

  .page__search-result {
    margin-left: 0;
    width: 100%;
  }

  .content-tabs {
    &__info-item-collection {
      color: @text-color-grey;
      line-height: 16px;
    }

    &__info-title-collection {
      margin-bottom: 16px;
      font-weight: bold;
      font-size: 16px;
    }

    &__info-des-collection {
      margin-bottom: 0;
      font-size: 14px;
    }
  }

  .map {
    padding-top: 0;

    .container {
      padding-left: 0;
      padding-right: 0;
      max-width: 1130px;
    }

    &__header {
      display: none;
    }
  }

  .new-filter-search .checkbox {
    height: 46px !important;
  }

  .new-filter-search .filters__dropdown {
    max-width: 452px !important;
  }

  .new-filter-search .filters__dropdown_datapicker {
    max-width: 248px !important;
  }

  .filters__dropdown_datapicker .filters__result-inner {
    height: 350px !important;
    max-height: 350px !important;
    overflow-y: hidden !important;
  }

  .content-tabs {
    &__table {
      flex-wrap: no-wrap;
      flex-direction: column;
    }
    &__table-item {
      padding-top: 12px;
      padding-bottom: 14px;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      border-bottom: 1px solid #f6f6f6;
      &:last-child {
        border-bottom: none;
      }
    }
    &__table-title {
      width: 151px;
      padding-right: 33px;
      line-height: 14px;
    }
    &__table-des {
      line-height: 14px;
    }
  }
}
