.new-table {
	&.table--separated {
		th {
			&:first-child {
			  padding-left: 18px !important;
			  padding-right: 13px !important;
			  padding-top: 0 !important;
			  padding-bottom: 0 !important;
			}

			 &:nth-child(2) {
			  padding-left: 13px !important;
			  padding-right: 32px !important;
			  padding-top: 0 !important;
			  padding-bottom: 0 !important;
			}

			&:nth-child(3) {
			  padding-left: 0px !important;
			  padding-right: 0px !important;
			  padding-top: 0 !important;
			  padding-bottom: 0 !important;
			}

			&:nth-child(4) {
			  padding-left: 0px !important;
			  padding-right: 16px !important;
			  padding-top: 0 !important;
			  padding-bottom: 0 !important;
			  min-width: 145px;
			}

			&:nth-child(5) {
			  padding: 0 16px !important;
			}

			&:last-child {
		    padding-left: 16px !important;
			  padding-right: 24px !important;
			  padding-top: 0 !important;
			  padding-bottom: 0 !important;
			}
		}
	}
	.table {
		&__th {
	    color: @text-color-grey;
	    font-size: 13px;
	    line-height: 16px;
	    font-weight: bold;
	    margin: 0;
	    padding: 0 5px;
	    height: 48px;
	    border: 0;
	    background-color: transparent;

	    &:first-child {
			   padding-left: 26px;
			   padding-right: 15px;
			}

			 &:nth-child(2) {
			   padding-right: 17px;
			   padding-left: 15px;
			}

			&:nth-child(3) {
			   padding-right: 18px;
			   padding-left: 14px;
			}

			&:nth-child(4) {
			   padding-right: 24px;
			   padding-left: 18px;
			}

			&:nth-child(5) {
			   padding-right: 24px;
			   padding-left: 24px;
			}

			&:last-child {
		    padding-right: 20px;
		    padding-left: 24px;
			}

		}

		&__th-inner {
	    display: flex;
	    flex-direction: row;
	    justify-content: center;
	    align-items: center;
		}

		&__th-text {
		  margin-right: 0;
		}

		&__th--star {
		  width: 30px
		}

		&__th--cipher {
		  width: 90px
		}

		&__th--name {
		  width: 374px;
		  text-align: left;
		  .table__th-inner {
		  	justify-content: flex-start;
		  }
		}

		&__th--dating {
		  width: 130px
		}

		&__th--geography {
		  width: 100px
		}

		&__th--lang {
		  width: 124px
		}

		&__th--type {
		  width: 166px;
		  text-align: left !important;
		  .table__th-inner {
		  	justify-content: flex-start !important;
		  }
		}

		&__th--keywords {
		  width: 155px
		}

		&__th--more {
		  width: 40px
		}

		&__th--quantity {
		  width: 105px
		}

		&__th--type-material {
		  width: 135px
		}
	}
}