.filter-search {
  &__group {border-bottom: 1px solid #F6F6F6;}

  &__tab {
    display: block;
    margin-top: 1px;
    margin-bottom: 19px;

    .icon-search-input {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
    }

    .icon-search-absolute {
      position: absolute;
      right: 39px;
    }

    &-max-width {max-width: 192px;}
  }
  &__tab-title-icon {
    width: 24px;
    height: 24px;
  }
  &__tabs {
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: -1px;
    display: none;
  }

  &__tab-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    padding-right: 14px;
    width: 100%;
    background-color: @addable-menu-link;
  }

  &__tab-title-text {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: @text-color-grey;
    margin-bottom: 0;
  }

  &__input {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 10;
    background-color: @bg-white;

    box-sizing: border-box;
    border-radius: 4px;
    padding: 0px 24px;

    form {
      display: block;
      width: 100%
    }

    input {
      width: 100%;
      display: block;
      height: 40px;
      padding: 12px 38px 12px 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      font-size: 16px;
      color: #adadad;
      background-color: #FDFDFD;
      outline: none !important;
      border: none !important;

      &::placeholder {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: @date-color;
      }

      &:focus {color: #333}
    }
  }

  &__submit {
    display: flex;
    align-items: center
  }

  &__submit {
    text-decoration: none;
    margin-bottom: 0;
    margin-top: 16px;
    width: 100%;
    height: 40px;
    // background-color: @accent-color;
    justify-content: center;
    border-radius: 4px;
    letter-spacing: 0.0225em;
    text-transform: uppercase;
    color: @date-color;
    border: none;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);

    & .icon-apply-filter {
      width: 20px;
      height: 20px;
      margin-top: 4px;
      fill: @text-color-grey
    }

    &-fill {
      background-color: @accent-color;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
      color: @bg-white;
      display: flex;
      &.active {display: block;}
    }
  }

  &__clear-link {
    border: 0 !important;
    appearance: none;
    display: inline-block;
    vertical-align: middle;
    font-weight: 700;
    line-height: 15px;
    font-size: 11px;
    margin-top: 8px;
    padding: 0;
    color: @text-color-grey;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14) !important;
    width: 100%;
    height: 40px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    font-weight: bold !important;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.0225em;
    background: @bg-white;
    display: flex;

    &.active {display: flex;}

    &:hover {color: @text-color-grey !important;}
  }

  &__info-result {display: flex;}

  &__info-result-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px 24px;
  }

  &__info-result-sorting-text {
    font-size: 13px;
    line-height: 16px;
    position: relative;
  }
  &__info-result-sorting-span {
    color: @accent-color;
    font-weight: 500;
    cursor: pointer;
    margin-left: 5px;
    text-transform: lowercase;
  }
  &__info-result-sorting-hide {
    background: @bg-white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    padding: 16px 15px;
    border-radius: 4px;
    position: absolute;
    left: 100px;
    z-index: 20;
    margin-top: 12px;
    display: none;
    &.active {display: block;}
  }
  &__info-result-sorting-hide-text {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 16px;
    color: @text-color-grey;
    margin-bottom: 15px;
    cursor: pointer;
    display: inline-flex;
    width: 100%;
    white-space: nowrap;

    a {white-space: nowrap;}
    &:last-child {margin-bottom: 0;}
    &:hover {color: @accent-color;}
    &.active {font-weight: bold;}
  }
  &__info-result-sorting-svg {
    display: flex;
    position: relative;
    margin-left: 12px;
    height: 24px;
  }

  &__info-result-sorting-svg-second,
  &__info-result-sorting-svg-first {
    height: 5px;
    width: 9px;
    fill: @border-color;
    cursor: pointer;
    position: absolute;
    left: 0;
  }

  &__info-result-sorting-svg-second {
    bottom: 4px;
    fill: @accent-color;
    transform: translateX(-0.5px);
    &.active {fill: @text-color-grey !important;}
  }

  &__info-result-sorting-svg-first {
    top: 4px;
    transform: rotate(180deg);
  }

  &__info-result-container-item {
    display: flex;
    align-items: center;
  }

  &__summ-items {
    display: flex;
    align-items: center;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: right;
    color: @text-color-grey;
    margin-right: 8px;
  }

  &__info-result-sorting {
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
  }

  &__info-result-sorting-text {
    margin-bottom: 0;
    color: @text-color-grey;
  }

  &__info-result-sorting-link {
    margin-bottom: 0;
    color: @accent-color;
    margin-left: 5px;
    cursor: pointer;
  }

  &__dropdown {
    position: absolute;
    left: 0;
    top: calc(100% + 20px);
    width: 100%;
    background: @bg-white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 14%);
    z-index: 2;
    &.hide {display: none;}
  }
  &__dropdown-wrapper {padding: 20px 40px;}
  &__dropdown-item {
    display: flex;
    align-items: center;
  }
  &__dropdown-title {
    color: @text-color-grey;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 0;
    margin-right: 16px;
  }
  &__dropdown-country {
    font-size: 14px;
    line-height: 16px;
    color: @date-color;
    margin-bottom: 0;
  }

  &__no-result {
    position: absolute;
    //top: calc(100% + 20px);
		top: 100%;
    left: 0;
    width: 100%;
    background: @bg-white;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 14%);
    z-index: 2;
    margin-top:10px;
    &.hide {display: none;}
  }
  &__no-result-wrapper {
    padding: 79px 96px 65px;
    font-size: 14px;
    line-height: 24px;
    color: @text-color-grey;
  }
  &__no-result-title {font-weight: 500;}
  &__no-result-list {padding-left: 0;}
  &__no-result-item {margin-left: 20px;}
  &__no-result-link {
		color: @accent-color;
		display: inline-block;
		cursor: pointer;
	}
}
