.new-search-main {
	width: 100%;
	padding: 24px;
	background: #FFFFFF;
	border-radius: 4px 4px 0px 0px;
	//box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
	
	&__input {
		width: 100%;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 8px 12px 8px 16px;
		background: #FDFDFD;
		border: 1px solid #F6F6F6;
		box-sizing: border-box;
		border-radius: 4px;
		outline: none;
		&:focus {
		  border: 1px solid #FFE092;
			box-sizing: border-box;
			box-shadow: 0px 0px 4px rgba(179, 136, 42, 0.05);
			border-radius: 4px;
		}
		&::placeholder {
			font-weight: normal;
			font-size: 13px;
			line-height: 16px;
			color: @date-color;
		}
	}
	.icon-search {
		position: absolute;
		top: 33px;
    right: 47px;

		&._active {
			cursor: pointer;
		}
	}
}