.content-tabs {
  &__menu {
    display: flex;
    padding-left: 0;
    margin-bottom: 0;

    &-slider {
      height: 36px;
      background: #E8E8E8;
      border-radius: 32px;
      position: relative;
    }
  }
  &__menu-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #F9F9F9;
  }
  &__menu-default {
    background-color: @bg-white;
    box-shadow: 0px 2px 4px 0px #00000024;
    border-radius: 4px;
    border-bottom: 1px solid #F9F9F9;

    &.info-active {
      box-shadow: none;
    }
  }

  &__wrapper {
    background-color: @bg-white;
    box-shadow: 0px 2px 4px 0px #00000024;
    border-radius: 4px;
  }

  &__menu-item {
    display: flex;
    align-items: center;
    list-style-type: none;

    &[disabled] {
      pointer-events: none;
    }

    &-default {
      padding: 0px 24px;
      height: 48px;

      &[disabled] {
        opacity: .5;
      }

      &.border-bottom {
        border-bottom: 1px solid @accent-color;
      }
    }

    &-slider {
      padding: 0px 10px;
    }

		&__video {
			position: relative;
		}

  }

	&__show-hint {
		pointer-events: inherit !important;
		cursor: default;
		.content-tabs__menu-link-icon {
			fill: @text-color-grey !important;
		}
		.content-tabs__menu-link {
			color: @text-color-grey;
		}
		.content-tabs__menu-link-span {
			color: @text-color-grey;
		}
		&:hover {
			.content-tabs__hint {
				display: block;
			}
		}
	}

	&__hint {
		position: absolute;
		top: -30px;
		padding: 6px 9px;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
		border-radius: 4px;
		background: @bg-white;
		white-space: nowrap;
		display: none;
	}

  &__menu-link {
    margin-bottom: 0;
    display: flex;
    align-items: center;

    &.active {
      .icon-tab-list {
        fill: @text-color !important;
      }
    }
  }

  
  &__menu-link-icon-img-fill {
    display: none;
  }

  &__menu-link-icon {
    width: 24px;
    height: 24px;
  }

  &__menu-link-icon-line {
    stroke: @text-color-grey !important;
  }

  &__menu-link-icon-path {
    fill: @text-color-grey !important;
  }

  &__content {
    padding-top: 30px
  }

  &__content-item {
    display: none;
    position: relative;

    &--opacity {
      display: block;
      visibility: hidden;
      height: 0;
      overflow: hidden;
      
      &.active {
        visibility: visible;
        height: 100%;
      }
    }

    &.active {
      display: block;
    }
  }
}
