.profile {

  &__table {
    width: 100%;
  }

  &__thead-tr {
    height: 56px;
    vertical-align: middle;
  }

  &__thead-th {

    &_star {
      width: 30px;
      padding-left: 24px;
      padding-top: 10px;
    }
    &_type {
      text-align: center;
      width: 127px;
    }

    &_name {
      width: 272px;
    }

    &_date {
      width: 146px;
      text-align: center;
    }

    &_location {
      width: 175px;
      padding-left: 20px;
      padding-right: 20px;
    }

    &_add {
      width: 96px;
      text-align: center;
    }

    &_delete {
      text-align: center;
      width: 80px;
    }
  }

  &__thead-th-title {
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: @text-color-grey;
    margin-bottom: 0;
    width: 100%;
  }


  &__tbody-tr {
    height: 56px;
    vertical-align: middle;
    border-top: 1px solid @border-color-new;

    &:last-child {
      border-bottom: 1px solid @border-color-new;
    }

    &:hover {
      box-shadow: 0px 0px 5px 0px @accent-color;

      .profile__tbody-td-delete {
        display: block;
      }

      .profile__tbody-td-show {
        display: flex;
      }
    }
  }

  &__tbody-td {
    &_delete {
      text-align: center;
      position: relative;
    }
    &_location {
      position: relative;
    }
    &_star {
      padding-left: 24px;
    }

  }

  &__tbody-td-show {
    display: none;
    position: absolute;
    top: 0;
    left: 100%;
    margin-bottom: 0;
    width: 32px;
    height: 100%;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background: @bg-white;
    box-shadow: 2px 2px 6px rgba(128, 95, 23, 0.1);
    border-radius: 0px 4px 4px 0px;

    &:hover {
      .profile__tbody-td-show-icon {
        fill: @accent-color;
      }
    }
  }

  &__tbody-td-show-icon {
    height: 24px;
    width: 24px;
    fill: @text-color-grey;
  }

  &__tbody-td-type {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__tbody-td-type-icon {
    margin-right: 4px;
    fill: @date-color;
  }

  &__tbody-td-type-title {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 100%;
    color: @date-color;
    min-width: 52px;
  }

  &__tbody-td-name {
    font-size: 13px;
    line-height: 18px;
    color: @text-color-grey;
  }

  &__tbody-td-name-title {
    margin-bottom: 0;
    font-weight: bold;
    max-width: 317px;
  }

  &__tbody-td-name-des {
    margin-bottom: 0;
  }

  &__tbody-td-location {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: @date-color;
    margin-bottom: 0;
    padding-left: 20px;
    span {
      color: @text-color-grey;
    }
  }

  &__tbody-td-date,
  &__tbody-td-add {
    margin-bottom: 0;
    text-align: center;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    text-align: center;
    color: @text-color-grey;
  }


  &__tbody-td-delete {
    width: 72px;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    padding-right: 24px;
    text-align: center;
    color: @text-color-grey;
    position: relative;
    display: none;
    text-transform: none !important;

    &:hover {

      &:after,
      &:before {
        background: @accent-color;
      }
    }

    &:after,
    &:before {
      content: "";
      position: absolute;
      top: 9px;
      right: 5px;
      width: 10px;
      height: 2px;
      border-radius: 4px;
      background: @text-color-grey;
    }

    &:after {
      transform: rotate(45deg);
    }

    &:before {
      transform: rotate(-45deg);
    }
  }
}
