.profile {
  &__sidebar {
    min-width: 288px;
    padding-right: 24px;
  }

  &__sidebar-nav {
    padding-bottom: 20px;
  }

  &__sidebar-nav-wrapper {
    background: @bg-white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
  }

  &__sidebar-nav-item {
    display: flex;

    &_tab {
      height: 48px;
      width: 100%;
      display: flex;
      align-items: center;

      &.active {
				.profile__sidebar-nav-text {
					color: @accent-color;
					&:after,
					&:before {
						background: @accent-color;
					}
				}
      }
    }

    &_out {
      padding: 24px;
    }
  }

  &__sidebar-nav-text {
    width: 100%;
    height: 100%;
    cursor: pointer;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    padding: 0px 24px;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: @text-color-grey;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 21px;
      right: 28px;
      width: 9px;
      height: 3px;
      border-radius: 4px;
      background: @text-color-grey;
      transform: rotate(45deg);
    }

    &:after {
      content: "";
      position: absolute;
      top: 26px;
      right: 28px;
      width: 9px;
      height: 3px;
      border-radius: 4px;
      background: @text-color-grey;
      transform: rotate(-45deg);
    }
  }

  &__sidebar-nav-item-out {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: @accent-color;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.0025em;
    color: @addable-menu-link;

    &:hover {
      color: @addable-menu-link;
    }
  }

  &__sidebar-del-link {
    font-size: 14px;
    line-height: 100%;
    align-items: center;
    letter-spacing: 0.0025em;
    color: @accent-color;
    display: inline-flex;

    &:hover {
      color: @accent-color;
    }
  }
}
