.popup {

  &__content-left,
  &__content-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    form {
      width: 192px;
      height: 24px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__content-left {
    margin-right: 24px;
    width: 100%;
  }

  &__right-title {
    font-weight: 500 !important;
    font-size: 14px;
    line-height: 20px;
    color: #363636;
    padding-bottom: 12px;
    margin-bottom: 0;
  }

  &__right-span {
    font-size: 14px;
    line-height: 24px;
    color: @text-color-grey;
    border-bottom: 1px dashed @accent-color;
    padding-bottom: 3px;
    padding-top: 3px;
    font-weight: 400 !important;
    text-transform: none !important;
  }

  &__right-table {
    margin-top: 31px;
    max-height: 279px;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: @border-color;
      border-radius: 4px;
    }
  }

}

.popup-table {

  &__tr {
    border-bottom: 1px solid #F6F6F6;

    &:first-child {
      border-top: 1px solid #F6F6F6;
    }
  }

  &__th {
    font-weight: normal;
    margin-right: 16px;
    font-size: 14px;
    line-height: 100%;
    color: @date-color;
    width: 136px;
    padding-top: 11px;
    padding-bottom: 13px;
    vertical-align: top;
  }

  &__td {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: @text-color-grey;
    padding-top: 11px;
    padding-bottom: 13px;
  }
}
