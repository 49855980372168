.interview {
	.new-table {
		//max-height: 1044px;
   // overflow: auto;
    display: block;
    margin-right: 2px;
    padding-left: 2px;

    &::-webkit-scrollbar {
	    width: 4px;
	  }

	  &::-webkit-scrollbar-track {
	    background: @bg-white;
	    border-radius: 4px;
	  }

	  &::-webkit-scrollbar-thumb {
	    background:  #E5E5E5;
	    border-radius: 4px;
	    outline: none;
	  }
	}
}
