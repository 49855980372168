.archive-view {
  .new-pagination {
    position: fixed;
    width: 100%;
    max-width: 384px;
    left: 50%;
    bottom: 32px;
    transform: translateX(-50%);

    &__container {
      height: 52px;
      width: 100%;
      max-width: 384px;
      margin: 0 auto;
      position: absolute;
      left: 50%;
      bottom: -68px;
      transform: none;

      &._active-anim {
        transform: translateX(-50%);

        .new-pagination {
          position: static;
          margin: 0 auto;
          transform: translateX(0);
        }
      }
    }

    &__wrapper {
      background: @bg-white;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12);
      border-radius: 52px;
      height: 52px;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
    }

    &__btn {
      display: flex;

      &_next {
        padding-right: 16px;
      }

      &_prev {
        padding-left: 16px;
      }
    }

    &__btn-link {
      width: 24px;
      height: 24px;
      position: relative;

      &:first-child {
        margin-right: 5px;
      }

      &:before {
        content: "";
        position: absolute;
        left: calc(50% - 6px);
        top: calc(50% - 4px);
        width: 12px;
        height: 2px;
        border-radius: 4px;
        background: @bg-black-light;
        transform: rotate(-55deg);
      }

      &:after {
        content: "";
        position: absolute;
        left: calc(50% - 6px);
        top: calc(50% + 5px);
        width: 12px;
        height: 2px;
        border-radius: 4px;
        background: @bg-black-light;
        transform: rotate(55deg);
      }

      span {
        &:before {
          content: "";
          position: absolute;
          left: calc(50% - 8px);
          top: calc(50% - 4px);
          width: 12px;
          height: 2px;
          border-radius: 4px;
          background: @bg-black-light;
          transform: rotate(-55deg);
        }

        &:after {
          content: "";
          position: absolute;
          left: calc(50% - 8px);
          top: calc(50% + 5px);
          width: 12px;
          height: 2px;
          border-radius: 4px;
          background: @bg-black-light;
          transform: rotate(55deg);
        }
      }

      &_prev-prev,
      &_next-next {

        &:before,
        &:after {
          left: 50%;
        }
      }

      &_next,
      &_next-next,
      &_next-next span {
        &:after {
          transform: rotate(-55deg);
        }

        &:before {
          transform: rotate(55deg);
        }
      }
    }

    &__btn-link_prev {}

    &__list {
      display: flex;
      padding-left: 0;
      margin-bottom: 0;
    }

    &__list-item {
      list-style-type: none;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }
    }

    &__list-link {
      font-weight: normal;
      font-size: 12px;
      line-height: 24px;
      color: @text-color-grey;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.active {
        color: @color-white;
        background: @accent-color;
      }
    }
  }
}
