.result-search {
  .filter-search__info-result {
    padding-left: 24px;
    padding-right: 24px;
  }

  .filter-search__info-result-sorting {
    padding-left: 24px;
    border-left: 1px solid #d8d8d8;
  }

  .filter-search__info-result-sorting-hide {
    width: 151px;
  }

  .icon-star {
    width: 24px;
    height: 24px;
  }
	.new-search-main {
		padding-top: 0;
		.icon-search {
			top: 10px;
		}
	}

  .profile {
		&__favorites-filter {
			padding-top: 0;
		}
		&__thead {
			&-th {
				font-weight: bold;
        font-size: 13px;
        line-height: 16px;
        color: @text-color-grey;
        text-transform: initial;
				padding: 7px 0 9px;

				a {
					font-weight: bold;
					font-size: 13px;
					line-height: 16px;
					color: @text-color-grey;
					text-transform: initial;
				}

				.asc {
					color: @accent-color;
				}
					// 67 113 539 207 232
				&_star {
					width: 48px !important;
					text-align: center;
					vertical-align: middle;
				}
				&_type {
					width: 74px !important;
					text-align: start;
				}
				&_name {
					width: 375px !important;
					text-align: start;
				}
				&_date {
					width: 138px !important;
				}
				&_location {
					width: 155px !important;
				}
			}
		}
		&__tbody {
			&-td {
				&_star {
					padding-left: 0;
					text-align: center;
				}
				&_type {
					text-align: start;
				}
				&-type {
					justify-content: flex-start;
				}
				&_name {
					padding: 0;
				}
				&-name {
					padding: 12px 0;
				}
				&_location {
					padding: 0;
				}
				&-location {
					padding: 0;
				}
			}
		}
    //&__thead-th {
    //  &_type {
    //    width: 90px;
    //    padding-right: 20px;
    //  }

    //  &_name {
    //    width: 339px;
    //    max-width: 339px;
    //  }
    //}

    //&__tbody-td {
    //  &_type {
    //    padding-right: 20px;
    //  }
    //}

    //&__tbody-td-name-title {
    //  max-width: 490px;
    //}
  }

  .make-starred__control {
    height: 24px;
  }
}
