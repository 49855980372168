.vue-filter {
	
	&__button {
		margin-top: 16px;
	}

	&__button-item {
		&:not(:first-child) {
			margin-top: 8px;
		}
	}

	&__button-sumbite,
	&__button-clear {	
		cursor: pointer;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
		border-radius: 4px;
		width: 100%;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;	
		font-size: 13px;
		line-height: 16px;
		font-weight: @font-bold;
		letter-spacing: 0.0225em;			
	}

	&__button-sumbite {
		background: @accent-color;
		color: @color-white;
		text-transform: uppercase;
	}

	&__button-clear {
		background-color: @bg-white;
		color: @text-color-grey
	}
}

