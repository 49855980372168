.content-tabs {
  &__popup-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: @text-color;
    opacity: 0.4;
    z-index: 12;
    display: none;
    &.active {
      display: block;
    }
  }
  &__popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -40%);
    z-index: 13;
    display: none;
    &.active {
      display: block;
    }

    &-header {
      background-color: @accent-bg;
      padding: 18px 24px 18px 28px;
      border-radius: 4px 4px 0px 0px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-close {
        height: 24px;
        width: 24px;
        position: relative;
        cursor: pointer;

        &:before {
          content: "";
          position: absolute;
          right: 10%;
          top: 50%;
          width: 18px;
          height: 2px;
          border-radius: 4px;
          background-color: #fff;
          transform: rotate(-45deg);
        }

        &:after {
          content: "";
          position: absolute;
          right: 10%;
          top: 50%;
          width: 18px;
          height: 2px;
          border-radius: 4px;
          background-color: #ffffff;
          transform: rotate(45deg);
        }
      }

      &-title {
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #FFFFFF;
        display: flex;
        align-items: center;

        svg {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          fill: #ffffff;
          stroke: #ffffff;
        }
      }
    }

    &-content {
      background-color: #ffffff;
      border-radius: 0px 0px 4px 4px;
      padding: 17px 32px 32px;
      color: #616161;
      font-size: 16px;
      line-height: 24px;
      max-width: 522px;

      &-name {
        font-weight: bold;
        line-height: 19px;
        padding-bottom: 11px;
        margin-bottom: 0;
      }

      &-info {
        font-size: 14px;
        line-height: 16px;
        padding-bottom: 21px;
        margin-bottom: 0;
      }

      &-img {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        &.no-image {
          border-bottom: 1px solid #D8D8D8;
        }

        &-item {
          border-radius: 4px;
          max-height: 288px;
        }
      }

      &-description {
        padding-top: 15px;
        margin-bottom: 0;
      }
    }
  }
}
