.new-filter-search {
  &.filter-search {
    //position: sticky;
    //top: 52px;
    //position: relative;
    //padding-bottom: 24px;
    border-bottom: 1px solid #F6F6F6;
    background: #ffffff;
    z-index: 9;

		//&::before {
		//	content: '';
		//	position: absolute;
		//	top: -30px;
		//	left: -2%;
		//	height: 30px;
		//	width: 104%;
		//	background-color: #f9f9f9;
		//}

    &.shadow {
      filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.05));
    }

    .icon-preloader-new {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 32px;
      top: 7px;
      z-index: 10;
      animation: circle 1s linear infinite;
      display: none;
      &.show {
        display: block;
      }
			&.padding {
				right: 95px;
			}
    }

    @keyframes circle {
      0% {
        transform: rotate(0);
      }

      100% {
        transform: rotate(-360deg);
      }
    }

    &.filters-visible {
      .filters {
        opacity: 1;
        visibility: visible;
        height: auto;
        margin: 0 20px;
      }

      .filter-search__input-wrapper {
        margin-bottom: 32px;
      }
    }

    &.keywords-all-visible {
      .filter-search__input-wrapper {
        border-bottom: 0;
        border-radius: 4px 4px 0 0;
        box-shadow: 0 1px 5px rgba(0, 0, 0, .2), 0 3px 4px rgba(0, 0, 0, .12), 0 2px 4px rgba(0, 0, 0, .14);
        border-color: #cf9d30
      }

      .filter-search__submit {
        // background-color: #cf9d30;
      }
    }

  }

  .filter-search {
    &__wrapper {
      display: flex;
      margin-bottom: 24px;
      flex-wrap: wrap;
      width: 212px;
    }

    &__header {
      position: relative;
      // width: calc(100% - 56px);
    }

    &__input-wrapper {
      position: relative;
      display: flex;
      // border: 1px solid #D8D8D8;
      box-sizing: border-box;
      // border-radius: 36px;
      overflow: hidden;
      padding: 0px 24px;

      // &:hover {
      //   border-color: #FFE092;
      // }

      // &:active {
      //   border-color: #DBAE63;
      // }
    }
    &__container-summ-pagination {
      display: flex;
      align-items: center;
    }
    &__summ-items {
      margin-top: 4px;
    }
    &__input {
      font-family: Roboto;
      font-size: 14px;
      line-height: 20px;
      padding: 12px 16px;
      padding-right: 48px;
      width: 100%;
      height: 40px;
      // transition: .12s;
      outline: none;
      background: #FDFDFD;
      border: 1px solid #F9F9F9;
      box-sizing: border-box;
      border-radius: 4px;

      &::placeholder {
        font-weight: normal;
        font-size: 13px;
        line-height: 16px;
        color: #A7A7A7;
      }

      &:focus {
        border: 1px solid #FFE092;
        box-sizing: border-box;
        box-shadow: 0px 0px 4px rgba(179, 136, 42, 0.05);
        border-radius: 4px;
      }
    }

    &__submit {
      color: #A7A7A7;
      text-decoration-line: none;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: .0075em;
      text-transform: uppercase;
      // background-color: #DBAE63;
      margin: 0;
      border: 0;
      padding: 0 8px;
      transition: .12s;
      height: 40px;
      width: 40px;
      position: absolute;
      right: 4px;
      z-index: 11;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      position: absolute;
      right: 31px;

      &.active {
        color:#DBAE63;
      }

    }
    &__submit_hide {
      opacity:0;
    }

    &__filter-button {
      display: none;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #DBAE63;
      outline: none;
      flex: 0 0 auto;
      margin-right: 16px;
      cursor: pointer;
      transition: .12s;
      margin-top: 4px;

      &:hover {
        border-color: #FFE092;

        .icon-filters {
          fill: #FFE092;
          stroke: #FFE092;
        }
      }

      &:active {
        border-color: #A77F36;

        .icon-filters {
          fill: #A77F36;
          stroke: #A77F36;
        }
      }

      .icon-filters {
        fill: @accent-color;
        stroke: @accent-color;
        transition: .12s
      }

      &.active {
        background-color: @accent-color;

        .icon-filters {
          fill: #fff;
          stroke: #fff
        }
      }
    }

    &__info-result {
      justify-content: space-between;
      padding: 16px 20px 16px 12px;
      height: 48px;
    }

    &__info-result-left {
      display: flex;
      align-items: center;
    }

    &__clear-link,
    &__info-result {
      display: flex;
      flex-direction: row;
      align-items: center
    }

    &__clear-link {
      position: absolute;
      right: 65px;
      box-shadow: none !important;
      top: calc(50% - 8px);
      width: 24px;
      height: 24px;
      transform: translateY(-50%);
      justify-content: center;
      opacity: 1;
      visibility: visible;
      margin-bottom: 0;
      z-index: 11;

      .icon-new-close-medium {
        fill: @text-color-grey;
        width: 8px;
        height: 8px
      }

      &.hidden {
        opacity: 0;
        visibility: hidden
      }
    }
    &__tab-tile {
      padding-right: 47px;
      padding-left: 12px;

      .icon-tiles,
      .icon-tab-list {
        width: 24px;
        height: 28px;
        fill: @text-color-grey;
        &.hide {
          display: none;
        }
      }
    }

    &__tab-tile-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
    }

    &__tab-tile-name {
      margin-bottom: 0;
      margin-left: 10px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: @text-color-grey;
      width: 55px;
    }
  }

}
