.breadcrumbs {
  padding-top: 25px;
  padding-bottom: 22px;
}

.breadcrumbs__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.breadcrumbs__item {
  font-size: 12px;
  line-height: 14px;
  padding: 0 12px 0 0;
  position: relative;

  &::after {
    content: '>';
    position: absolute;
    top: 50%;
    right: 3px;
    transform: translateY(-50%);
    color: @text-color;
  }

  &:last-child {
    padding-right: 0;

    &::after {
      content: none;
    }
  }
}

.breadcrumbs a {
  display: block;
  transition: color @transition-time;
  color: @broadcrumbs-link;

  &[href] {
    color: @accent-color;
    text-decoration: none;

    &:hover {
      color: @broadcrumbs-link;
      text-decoration: underline;
    }

    &:active {
      color: @broadcrumbs-link;
    }
  }
}
