.addable-menu {
  align-items: center;
  position: absolute;
  top: 0;

  & .new-search__toggle {
    border-color: transparent;
    margin-right: 14px;
  }


  & .new-search__toggle .new-search__toggle-icon {
    color: @slide-desc-color;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    height: 24px;
    width: 1px;
    background-color: @slide-desc-color;
    top: 50%;
    transform: translateY(-50%);
  }
}

.addable-menu__identificator {
  padding: 16px 12px 16px 16px;
  letter-spacing: 0.0025em;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: @accent-color;
  text-transform: uppercase;
}

.addable-menu__list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;

  @media (max-width: @desktop) {
    li {
      display: none;

      &:nth-child(-n+3) {
        display: block;
      }
    }
  }

  @media (max-width: @desktop-min) {
    li {
      &:nth-child(3) {
        display: none;
      }
    }
  }

  @media (max-width: @tablet) {
    display: none;
  }
}


.addable-menu__link {
  padding: 18px 12px;
  letter-spacing: 0.0025em;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  color: @addable-menu-link;
  position: relative;
  white-space: nowrap;

  &:hover {
    color: #FFE092;
  }

  &.active {
    color: @accent-color;

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(100% - 24px);
      height: 4px;
      background-color: @accent-color;
    }
  }
}

.addable-menu {
  
  &__dropdown-wrapper {
    position: relative;
    margin-left: 20px;
    display: none;

    @media (max-width: @desktop) {
      display: block;
    }

    @media (max-width: @tablet) {
      display: none;
    }
  }

  &__dropdown-btn {
    margin-bottom: 0;
    width: 22px;
    height: 8px;
    color: @bg-white;
    position: relative;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: @bg-white;
    }

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 8px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: @bg-white;
    }

    span {
      content: "";
      position: absolute;
      top: 0;
      left: 0px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: @bg-white;
    }
  }

  &__dropdown {
    position: absolute;
    right: calc(50% - 96px);
    top: 43px;
    width: 192px;
    background: @accent-bg;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12);
    border-radius: 0px 0px 4px 4px;
    padding: 10px 20px;
    display: none;

    &.show-dropdown {
      display: block;
    }
  }

  &__dropdown-list {
    padding-left: 0;
    margin-bottom: 0;
  }

  &__dropdown-list-item {
    list-style-type: none;
    padding-bottom: 8px;
    padding-top: 8px;
    border-bottom: 1px solid #444444;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &:nth-child(-n+3) {
      display: none;
    }
    @media (max-width: @desktop-min) {
      &:first-child {
        display: none;
      }
      &:nth-child(2) {
        display: none;
      }
      &:nth-child(3) {
        display: block;
      }
    }
  }

  &__dropdown-list-link {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.0025em;
    color: @bg-white;
    &.active {
      color: @accent-color;
    }
  }
}
