.filters-form {
  position: relative;
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px;
  }
  &__container-col {
    display: flex;
  }
}


.new-filter-search {

  .filters {
    // visibility: hidden;
    // opacity: 0;
    // height: 0;
    
    opacity: 1;
    visibility: visible;
    height: auto;
    flex-direction: column;
    margin-right: 20px;

    &.filters--4-per-row {
      margin-top: 1px;

      .filters__item {
        &:nth-child(4n) {
          .filters__select {

            &:after {
              display: none;
            }
          }

          .filters__select--active {
            border-radius: 0;

          }
        }
      }
    }

    &.filters--5-per-row {
      margin-top: 1px;

      .filters__item {
        &:nth-child(5n) {
          .filters__select {


            &:after {
              display: none;
            }
          }

          .filters__select--active {
            border-radius: 0;

          }
        }
      }
    }
    &__title {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      width: 100%;
      max-width: 216px;

      &-text {
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #616161;
        margin-bottom: 0;
      }

      &-icon {
        width: 24px;
        height: 24px;
      }

    }

    &__select {
      position: relative;
      border-right: 0;
      padding: 10px 24px 10px 20px;
      overflow: visible;
      max-width: 192px;

      // &:after {
      //   content: "";
      //   position: absolute;
      //   top: -1px;
      //   right: 0;
      //   height: calc(100% + 2px);
      //   width: 1px;
      //   background-color: #cf9d30;
      //   opacity: 0;
      //   visibility: hidden;
      //   transition: .12s
      // }
    }

    &__select--active {

      z-index: 3;

      &:after {
        opacity: 1;
        visibility: visible
      }

      .filters__title-select {
        color: #cf9d30
      }

      .filters__icon-indicator {
        fill: #cf9d30;
        opacity: 1
      }
    }

    &__item {
      &:first-child {
        .filters__select {
          border-radius: 4px 0px 0px 4px;
        }
      }

      &:last-child {
        .filters__select {
          border-radius: 0px 4px 4px 0px;
        }
      }
    }

    &__title-select {
      color: #616161;
      font-size: 13px;
      line-height: 20px;
      flex: 1 0 auto
    }

    &__dropdown {
      margin-top: 0;
      border-top: 0;
      box-shadow: 0 4px 5px rgba(0, 0, 0, .2), 0 3px 14px rgba(0, 0, 0, .12), 0 8px 10px rgba(0, 0, 0, .14);
      border-radius: 0 0 4px 4px;
      overflow: hidden;
      left: 192px;
      padding-right: 4px;
      width: 1000%;
      max-width: 618px;

      &:after,
      &:before {
        display: none;
      }
    }

    &__dropdown-tab {
      display: flex;
      align-items: center;
      border-bottom: 1px solid @border-color-new;
      width: calc(100% + 4px);
    }

    &__dropdown-tab-item {
      margin-bottom: 0;
      width: 50%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      cursor: pointer;
      background: @border-color-new;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      text-align: center;
      color: @date-color;

      &.active {
        background: @bg-white;
        color: @accent-color;
      }

    }

    &__result-item {
      padding: 0
    }

    &__result-inner {
      flex: 0 0 auto;
      height: 100%;
      max-height: 315px;
      scrollbar-color: #d8d8d8 #f2f2f2;
      scrollbar-width: thin;
      overflow-x: hidden;
      overflow-y: auto;
      transition: .12s;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: @border-color;
        border-radius: 4px;
      }
    }

    .result-inner-tab {
      display: none;
      &.active {
        display: block;
      }
    }

    &__search-input {
      font-size: 14px;
      line-height: 20px;
      background-color: transparent;

      &::placeholder {
        color: #b0b0b0
      }

      &::clear {
        display: none
      }
    }

    &__icon-indicator {
      fill: #616161;
      transition: .12s;
      transform: rotate(270deg);
    }
  }
}
