.archive-description {
  .thumbnail__wrapper {
    width: 304px;
  }
  
  &__wrapper-img {
    display: flex;
    flex-wrap: wrap;
  }

  &__row {
    display: flex;
    width: 100%;
  }

  &__col {
    &:last-child {
      width: 100%;
      max-width: 912px;
      margin-left: 24px;
    }
  }

  &__img {
    width: 25%;
    height: 336px;
    width: 234px;
    position: relative;
    margin: 24px;

    &:first-child {
      margin-left: 0;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }

    &:nth-child(5n) {
      margin-left: 0;
    }

    &:after {
      display: none;
      cursor: pointer;
      content: url(../images/svg/maximize.svg);
      padding-top: 5px;
      position: absolute;
      top: calc(50% - 24px);
      left: calc(50% - 24px);
      height: 48px;
      width: 48px;
      background: #DBAE63;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      &:after {
        display: flex;
      }

      .archive-description__img-item-span {
        &:before {
          background: #DBAE63;
          mix-blend-mode: inherit;
        }
      }
    }
  }
  &__img-favorite {
    position: absolute;
    top: -2px;
    left: 8px;
  }
  &__img-item {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
    cursor: pointer;
  }

  &__img-item-span {
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;


    &:before {
      position: absolute;
      content: "";
      width: 24px;
      height: 24px;
      background: #787878;
      mix-blend-mode: multiply;
      border-radius: 0px 2px;
    }

    &:after {
      content: attr(value);
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      z-index: 1;
    }
  }

  .new-card {
    width: 100%;
  }

  .card {

    &__content-wrapper {
      padding-top: 16px;
      border-top: none !important;
    }

    &__left {
      width: 100%;
      height: 440px;
      max-width: 336px;
      margin-right: 48px;
      position: relative;
      background: @border-color-new;
      border-radius: 2px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 2px;
        object-fit: cover;
      }

      p {
        margin-bottom: 0;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        color: @border-color;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, calc(-50% + 48px));
      }

      .icon-no_img_document_view {
        width: 84px;
        height: 105px;
        position: absolute;
        left: calc(50% + 2px);
        top: calc(50% - 21px);
        transform: translate(-50%, -50%);
      }
    }

    &__right {
      width: 100%;
    }
  }

  .card-table {

    &__th {
      font-size: 14px;
      line-height: 100%;
      color: #A7A7A7;
      padding: 0px;
      padding-top: 15px;
    }

    &__td {
      font-weight: normal;
      font-size: 14px;
      line-height: 100%;
      color: #616161;
      padding: 15px 0px;
    }

    &__tr {
      border-top: 1px solid #f6f6f6 !important;

      &:last-child {
        border: none;
      }

      &:first-child {
        border-top: none !important;
      }
    }
  }


  &__document-item {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    background: #FFFFFF;
    padding: 16px 32px 28px 16px;
    margin-top: 16px;

    &:first-child {
      margin-top: 0;
      border-top: 1px solid #F6F6F6;
      border-radius: 0px 0px 4px 4px;
    }

    position: relative;
  }

  &__document-item-img {
    position: absolute;
    top: 0;
    left: -214px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.14));
    border-radius: 2px;
    width: 192px;
    height: 268px;
  }

  &__document-text {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: @text-color;
  }

  &__document-item-row {
    display: flex;
  }

  &__document-item-col {
    &:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 32px;
      padding-right: 16px;
    }
  }

  &__document-item-number {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: @date-color;
  }

  &__form {
    width: 24px;
    height: 24px;
  }

  &__document-item-label {
    cursor: pointer;
    height: 24px;
    width: 24px;
    margin-bottom: 0;
    position: relative;

    input:checked+svg {
      fill: @accent-color;
      stroke: @accent-color;
    }

    svg {
      stroke: #616161;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__document-item-input {
    opacity: 0;
    height: 0;
    width: 0;
    overflow: hidden;
  }

}
