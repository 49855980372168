.quick-view-land {
  display: none;
  position: fixed;
  width: 100%;
  max-width: 936px;
  margin: 15px auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
	background-color: @bg-white;
	border-radius: 4px;

  &.active {
    display: block;
  }

	.tns-liveregion {
		display: none;
	}

  &__close-btn {
    width: 36px;
    height: 36px;
    background-color: @bg-white;
    border-radius: 50%;
    position: absolute;
    right: -48px;
    cursor: pointer;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);

    &:after {
      content: "";
      width: 16px;
      height: 2px;
      border-radius: 2px;
      background: #616161;
      position: absolute;
      top: 50%;
      left: calc(~'100% - 26px');
      transform: rotate(-45deg);
    }

    &:before {
      content: "";
      width: 16px;
      height: 2px;
      border-radius: 2px;
      background: #616161;
      position: absolute;
      top: 50%;
      left: calc(~'100% - 26px');
      transform: rotate(45deg);
    }
  }

  &__overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 10;

    &.active {
      display: block;
    }
  }

  &__nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: @bg-white;
    border-radius: 4px 4px 0px 0px;
		border-bottom: 1px solid @pagination-bullets;

    &-item {
      display: flex;
      align-items: center;

      &:last-child {
        padding-right: 16px;
      }
    }

    &-inner {
      position: relative;
      display: flex;
      align-items: center;
      padding: 15px 28px;
      max-height: 48px;
      overflow: hidden;
      cursor: pointer;

      &-svg {
        width: 24px;
        height: 24px;
        fill: #616161;
      }

      &-title {
        margin-left: 8px;
        margin-bottom: 0;
        color: #616161;
        font-weight: 500;
        font-size: 13px;
        line-height: 15px;
      }

      &:hover {
        .quick-view-land__nav-inner-title {
          color: @accent-color;
        }

        .quick-view-land__nav-inner-svg {
          fill: @accent-color  !important;
        }
      }

      &.active {
        .quick-view-land__nav-inner-svg {
          fill: @accent-color  !important;
        }

        .quick-view-land__nav-inner-title {
          color: @accent-color;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background: @accent-color;
        }
      }
    }

    &-pagination {
      &-list {
        padding-left: 0;
        margin-bottom: 0;
        display: flex;

        &-item {
          display: flex;
          align-items: center;
          justify-content: center;
          list-style-type: none;
          cursor: default;

          p {
            margin-bottom: 0;
            color: @text-color-grey;
          }

          &:first-child {
            transform: rotate(180deg);
            padding-left: 14px;
          }

          &:nth-child(2) {
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: @accent-color;
            width: 90px;
            text-align: center;
          }

          &:last-child {
            padding-left: 14px;
          }

        }

        &-link {
          display: flex;
          color: #616161;
          margin-top: 1px;

          &:hover {
            svg {
              fill: @accent-color  !important;
            }
          }

          svg {
            fill: #616161 !important;
          }
        }
      }
    }
  }

  &__link-more {
    background: #DBAE63;
    border-radius: 4px;
    border: none;

    span {
      color: #ffffff;
    }
  }

  &__tab {
    display: flex;
    justify-content: flex-end;

    &-item {
      overflow: hidden;
      position: relative;
      flex: 0 0 auto;
      display: flex;
      align-items: center;
      height: 35px;
      margin-left: -30px;
      padding: 3px 40px 3px 25px;

      &:before {
        border-radius: 3px 0 0 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -14px;
        width: 100%;
        background: #f2f2f2;
        content: "";
        transform: skew(-22deg);
        transform-origin: top right
      }

      &:last-child {
        padding: 3px 13px 3px 20px
      }

      &:last-child {
        &:before {
          background: #fff
        }
      }

      .make-starred {
        height: 20px;
      }

      svg {
        width: 22px;
        height: 20px
      }
    }
  }

  &-inner {
    z-index: 2;
    position: relative;
    line-height: 1;
    font-size: 13px;
    overflow: hidden
  }

  &__content {
    background: @bg-white;
    padding: 24px;
    box-sizing: border-box;
    border-radius: 0px 0px 4px 4px;
    position: relative;

    .col-xs-6 {
      position: static !important;
    }

    .btn--catalog {
      width: 100%;
      margin-top: 0px;
      max-width: 192px;
      margin-left: 12px;
      font-weight: 500;
      text-transform: none !important;

      span {
        color: @addable-menu-link;
      }

      svg {
        height: 24px;
        width: 24px;
      }


      .icon-new-tombstone {
        fill: @addable-menu-link  !important;
        transform: translateY(-3px);
      }

    }

    .make-starred {
      width: 100%;
      max-width: 192px;
      margin-right: 12px;

      &__text {
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.0125em;
        color: @text-color-grey;
        margin-bottom: 0;
        margin-left: 8px;
      }

      &__control {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        justify-content: center;
        border: 1px solid #D8D8D8 !important;
        box-sizing: border-box;
        border-radius: 4px;

        svg {
          transform: translateY(-2px);
          color: @addable-menu-link;
          stroke: @text-color-grey;
          width: 24px;
          height: 24px;
        }

      }

      &__v {

        &:checked~svg {
          color: @accent-color  !important;
          stroke: @accent-color;
        }
      }
    }
  }

  .card {
    &__section {
      padding: 0px;
    }

    .popup-section {
      display: none;

      &.active {
        display: block;
      }
    }
  }

  .mfp-close {
    overflow: hidden;
    top: 2px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    color: @text-color-grey;
    background-color: @bg-white;
    font-size: 28px;
    opacity: 1;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
  }

  .card {
    padding-bottom: 0;

    .btn-popup {
      width: 100%;
      max-width: 406px;
      position: absolute;
      bottom: 24px;
      right: 24px;
    }

    &__section {
      box-shadow: none;
    }

    &__row {
      &--container {
        overflow: hidden;
      }

      &--container-scroll {
        height: 200px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: @border-color;
          border-radius: 4px;
        }
      }

      &--translate {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      &--info {
        .card__row-name-container {
          padding-left: 24px;
          width: 100%;

          .card__row-name {
            width: 100%;
            border-bottom: 1px solid #F6F6F6;
            padding-left: 0;
          }

          .card__col--label {
            max-width: 120px;
          }

          .card__row {
            padding: 6px 0px 9px;
            border-top: 1px solid #F6F6F6;

            &:last-child {
              border-bottom: 1px solid #F6F6F6;
            }
          }

          .card__row--button {
            height: 24px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .card__row--button-open {
              transform: rotate(90deg);
              width: 24px;
              height: 24px;
              fill: @text-color-grey;

              &.active {
                transform: rotate(270deg);
              }
            }
          }
        }
      }
    }
  }

  &_interview {
    .card__section {
      margin-bottom: 0;
    }

    .card .btn-popup {
      position: static;
    }

    .audio-player {
      padding: 0px;
      max-width: 433px;
      width: 100%;

      &__container {
        width: 100%;
      }

      &__inner {
        display: flex;
        width: 100%;
        padding: 0px;
      }

      &__inner-info {
        padding-top: 0;
        flex-flow: initial;
        width: 100%;
      }

      &__timeline {
        max-width: 256px;
        margin-bottom: 0;
      }

      &__volume {
        padding-left: 16px;
      }

      &__timer {
        margin-right: 0;
      }
    }


  }

  &__interview-info {
    padding-bottom: 13px;
    border-bottom: 1px solid #F6F6F6;
  }

  &__interview-info-wrapper {
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 96px;
  }

  &__interview-info-item {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #787878;

    &:nth-child(-n+3) {
      margin-right: 64px;
    }
  }

  &__interview-info-title {
    font-weight: normal;
    margin-bottom: 0;
  }

  &__interview-info-title-des {
    font-weight: bold;
    margin-left: 5px;
    margin-bottom: 0;
  }

  &__informants-wrapper {
    .new-icon-women {
      width: 24px;
      height: 24px;
			fill: @text-color-grey;
      stroke: @text-color-grey;
    }
  }

  &__informants {
    display: inline-block;
    padding-bottom: 25px;
    border-bottom: 1px solid #F6F6F6;
  }

  &__informants-name {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: @date-color;
    padding-bottom: 12px;
    padding-top: 27px;
    margin-bottom: 0;
  }

  &__informants-item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__informants-title {
    display: flex;
    align-items: center;
    margin-right: 50px;
  }

  &__informants-title-name {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: @text-color-grey;
    margin-bottom: 0;
    margin-left: 4px;
  }

  &__informants-des {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: @text-color-grey;
  }

  &__informants-des-year,
  &__informants-des-location {
    margin-bottom: 0;
  }

  &__informants-des-year {
    margin-right: 5px;
  }

  &__informants-des-location {
    margin-left: 5px;
  }

  &__footer {
    padding-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .translate-section__original {
    &._f-brew {
      color: @text-color-grey;
    }
  }

  .content-tabs__content {
    padding-top: 0;
  }

  .col-xs-6 {
    padding: 0px !important;
  }
}
