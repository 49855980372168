.new-keywords {
  padding-left: 24px !important;
  padding-right: 24px !important;
  z-index: 10;
  flex-flow: row nowrap;
  transition: margin 0.1s;

  &.keywords--hidden {
    margin-right: 140px;
  }

  .keywords__counter-button {
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    border: 0;
    border-radius: 50%;
    background-color: transparent;
    transition: .12s;
    margin-top: 10px;
  }
  .keywords__counter {
    color: #b3882a;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px
  }
  .keywords__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center
  }
  .keywords__item {
    padding: 5px 36px 5px 12px;
    margin-bottom: 12px;
    //margin-bottom: 0;
    margin-right: 7px
  }
  .keywords__text {
    display: block;
    font-size: 13px;
    line-height: 100%;
    margin-right: 0
  }
  .btn--del-keywords {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    transition: .12s;
    &:hover {
      svg {
        fill: @bg-white
      }
    }
    &:active {
      svg {
        fill: @bg-white
      }
    }
    svg {
      width: 10px;
      height: 10px
    }
  }
}

.keyword-all {
  .keywords__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center
  }
  .keywords__item {
    padding: 5px 36px 5px 12px;
    margin-top: 12px;
    margin-bottom: 0;
    margin-right: 7px;
    &:nth-child(4),
    &:last-child {
      margin-right: 0
    }
  }
  .keywords__text {
    display: block;
    font-size: 14px;
    line-height: 16px;
    margin-right: 0
  }
  .btn--del-keywords {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    transition: .12s;

    &:hover {
      svg {
        fill: @bg-white
      }
    }

    &:active {
      svg {
        fill: @bg-white
      }
    }

    svg {
      width: 10px;
      height: 10px
    }

  }
}


.keyword-all {
  position: absolute;
  left: 0;
  top: 35px;
  width: calc(100% - 47px);
  background-color: #fff;
  border: 1px solid #cf9d30;
  border-radius: 0 0 4px 4px;
  padding: 0 12px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, .2), 0 3px 14px rgba(0, 0, 0, .12), 0 8px 10px rgba(0, 0, 0, .14);
  opacity: 0;
  visibility: hidden;
  z-index: 12;

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-top: 1px solid @border-color;
    padding: 7px 0 0;
    &:first-child {
      &.hidden+.keyword-all__row {
        border-top: 0
      }
    }
  }
  &__category {
    display: block;
    color: @text-color-grey;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin: 0 10px 7px 0
  }
  &__list {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style-type: none
  }
  &__item {
    margin: 0 7px 7px 0
  }
}


.keywords {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  z-index: 3;
  position: relative;
  margin-top: 10px;

	&.keywords-tags {
		.keywords__item {
			margin-top: 0 !important;
			margin-bottom: 12px !important;
		}
		.keywords__list {
			padding-bottom: 0 !important;
		}
	}

  &--tags {
    min-height: 32px
  }

  &__item {
    position: relative;
    padding: 5px 36px 5px 12px;
    margin: 0 5px 10px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    border-radius: 2px;
    background-color: @accent-color;
    color: @color-white;
    font-size: 13px !important;
    line-height: 100% !important;
    user-select: none;
    border-radius: 20px;

    svg {
      transition: .12s
    }
  }
  &__item--input {
    background: @accent-color;
    border-radius: 20px;
    &:hover {
      background: @accent-color  !important
    }
    svg {
      &:hover {
        fill: @bg-white  !important
      }
    }
  }
  &__category {
    color: #d8d8d8
  }
  &__text {
    margin-right: .5rem
  }
  &__link,
  &__text {
    display: inline-block;
    vertical-align: middle
  }
  &__link {
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    &:hover {
      color: #616161
    }
    &:active {
      color: #333
    }
    &+.keywords__link {
      margin-left: .5rem
    }
  }
}




.btn--del-keywords {
  position: absolute;
  top: 2px;
  right: 0;
  font-size: 0;
  padding: 1px;
  margin-left: 1px
}

.btn--del-keywords svg {
  display: block;
  width: 16px;
  height: 16px;
  fill: #fff
}

.btn--del-keywords:active svg {
  fill: #333
}
