.new-search {
  margin-left: auto;

	&__catalog-search {
		position: absolute;
    background: #ffffff;
		z-index: 6;
    width: 100%;
		display: none;
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 14%);
		&._active {
			display: block;
		}
		.new-search__dropdown-hide-item {
			margin-top: 20px;
		}
		.new-search__dropdown-hide-item {
			display: block;
		}
	}

  &__toggle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    // border: 1px solid @slide-desc-color;
    border: none;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    fill: @accent-color;
    // transition-property: color, background-color;
    // transition-duration: @transition-time;
    cursor: pointer;
    
    svg {
      width: 18px;
      height: 18px;
    }

    .new-search--open & {
      background-color: @accent-color;
      color: @text-color;
    }

    &:focus {
      outline: none;
    }
  }

  &__form {
    right: 56px;
    height: 80px;
    width: 100%;
    display: none;
    max-width: 290px;
    position: absolute;
    // top: calc(~"100% + 24px");
    // left: 50%;
    // transform: translateX(-50%);

		.icon-preloader-new {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 80px;
      top: 27px;
      z-index: 10;
      animation: circle 1s linear infinite;
      display: none;
      &.show {
        display: block;
      }
    }

    @keyframes circle {
      0% {
        transform: rotate(0);
      }

      100% {
        transform: rotate(-360deg);
      }
    }

    &.new-search--open {
      display: flex;
      align-items: center;
    }

    @media (min-width: @tablet) {
      max-width: 865px;
    }
  }

  &__input {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0 64px 0 24px;
    //box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2), 0px 6px 30px rgba(0, 0, 0, 0.12), 0px 16px 24px rgba(0, 0, 0, 0.14);
    border-radius: 36px;
    border: 1px solid #FFE092;
    background-color: #fff;

    &::placeholder {
      font-size: 13px;
      line-height: 16px;
      color: @date-color;
      opacity: 0.5;
    }

    &:focus {
      outline: none;
    }
  }

  &__clear {
    position: absolute;
    right: 48px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    margin-bottom: 0;

    &:before {
      content: "";
      position: absolute;
      top: 11px;
      right: 6px;
      width: 10px;
      height: 2px;
      border-radius: 4px;
      background: @text-color-grey;
      transform: rotate(45deg)
    }

    &:after {
      content: "";
      position: absolute;
      top: 11px;
      right: 6px;
      width: 10px;
      height: 2px;
      border-radius: 4px;
      background: @text-color-grey;
      transform: rotate(-45deg)
    }
  }

  &__submit {
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: none;
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    color: @accent-color;
  }

  &__dropdown {
    position: absolute;
    top: 79px;
    width: 100%;
    background: @bg-white;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.14);
    border-radius: 0px 0px 4px 4px;
    border-top: 4px solid @border-color-new;
    display: none;

    &.show-dropdown {
      display: block;
    }

    &.open-hide {
      border-radius: 0px;

      .new-search__dropdown-hide {
        display: block;
      }

    }
  }

  &__dropdown-wrapper {
    display: flex;
    align-items: center;
    height: 56px;
    padding: 20px 24px;
  }

  &__dropdown-title {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: @date-color;
  }

  &__dropdown-list {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
  }

  &__dropdown-list-item {
    list-style-type: none;
    margin-left: 35px;
    position: relative;
    display: flex;
    align-items: center;

    &.active {
      .new-search__dropdown-list-title {
        color: @accent-color;
      }

      .new-search__dropdown-list-checkbox {
        color: @accent-color;
        border-color: @accent-color;

        &:after,
        &:before {
          background: @accent-color;
        }
      }

      .new-search__dropdown-arrow {

        &:after,
        &:before {
          background: @accent-color;
        }

        &:after {
          transform: rotate(45deg);
        }

        &:before {
          transform: rotate(-45deg);
        }
      }
    }

  }

  &__dropdown-list-label {
    margin-bottom: 0;
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    padding-left: 26px;


    input {
      &:checked+span {

        &:before,
        &:after {
          display: block;
        }
      }
    }

  }

  &__dropdown-list-input {
    width: 0;
    height: 0;
    visibility: hidden;
    overflow: hidden;
  }

  &__dropdown-list-title {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: @text-color-grey;

		.new-search__dropdown-list-title-span {
			font-weight: 400;
    	font-size: 14px;
    	line-height: 16px;
		}
  }

	&__dropdown-list-category {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 13px;
		line-height: 16px;
    color: @date-color;
  }

  &__dropdown-list-checkbox {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 16px;
    height: 16px;
    border: 2px solid @text-color-grey;
    border-radius: 2px;

    &:after {
      display: none;
      content: "";
      position: absolute;
      top: 6px;
      left: 1px;
      width: 5px;
      height: 2px;
      background: @text-color-grey;
      transform: rotate(45deg);
    }

    &:before {
      display: none;
      content: "";
      position: absolute;
      top: 5px;
      left: 3px;
      width: 9px;
      height: 2px;
      background: @text-color-grey;
      transform: rotate(-45deg);
    }
  }

  &__dropdown-arrow {
    position: relative;
    width: 24px;
    height: 24px;
    margin-bottom: 0;
    margin-left: 4px;
    cursor: pointer;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 10px;
      width: 8px;
      height: 2px;
      background: @text-color-grey;
      border-radius: 4px;
    }

    &:after {
      transform: rotate(-45deg);
      right: 5px;
    }

    &:before {
      transform: rotate(45deg);
      right: 10px;
    }

  }

  &__dropdown-hide {
    z-index: 1;
    position: absolute;
    width: 100%;
    left: 0;
    top: 100%;
    background: @bg-white;
    border-radius: 4px;
    display: none;
  }

  &__dropdown-hide-list {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 0;
    min-height: 48px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &_find-des {
      border-top: 4px solid @border-color-new;
      border-bottom: 4px solid @border-color-new;
    }

    &_filter {

      padding-left: 0;
      padding-right: 0;
			position: relative;

			&:not(:last-child) {
				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					height: 3px;
					width: 100%;
					background-color: @border-color-new;
				}
			}

      li {
				display: flex;
				align-items: center;
				justify-content: space-between;
        padding: 12px 20px 12px 48px;
        border-top: 1px solid @border-color-new;
        border-bottom: 1px solid @border-color-new;

        .new-search__dropdown-list-title-span {
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
					//text-transform: lowercase;
        }
				.new-search__dropdown-list-title {
					.new-search__dropdown-list-title-span {
						font-size: 14px;
						line-height: 16px;
					}
				}

        &:first-child {
          padding-left: 20px;

          .new-search__dropdown-list-title-span {
            font-weight: 400;
						//text-transform: lowercase;
          }
        }
      }
    }

  }

  &__dropdown-hide-list-item {
    list-style-type: none;
  }

	&__dropdown-hide-item {
		display: none;
		&._active {
			display: block;
		}
	}

  &__dropdown-list-title-span {
    font-weight: 400;
    margin-left: 5px;
		//text-transform: lowercase;
  }
}
