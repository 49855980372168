.table {
  .icon-melody {
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 20px
  }

  .icon-pause,
  .icon-play {
    display: block;
    width: 24px;
    height: 25px;
    fill: transparent
  }
  .icon-pause {
    fill: #b3882a
  }

  .icon-angle-down {
    display: none;
    width: 15px;
    height: 8px;
    fill: #616161
  }

  .icon-star {
    display: inline-block;
    width: 22px;
    height: 20px;
    fill: #616161
  }


  .icon-eye {
    display: inline-block;
    width: 22px;
    height: 15px;
    fill: #616161
  }

  .icon-camera {
    display: block;
    margin: 0 auto;
    width: 26px;
    height: 21px;
    fill: #616161
  }

  .icon-info {
    width: 24px;
    fill: transparent
  }

  .icon-book,
  .icon-info {
    display: block;
    margin: 0 auto;
    height: 24px
  }

  .icon-book {
      width: 18px
  }
  .icon-add-image {
    display: block;
    margin: 0 auto;
    width: 26px;
    height: 21px
  }

  .archive,
  .icon-image {
    width: 32px;
    height: 32px
  }

  .archive {
    fill: currentColor
  }

}
