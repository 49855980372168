.archive-view {
  .cemetery-tabs__title {
    margin-bottom: 32px;
  }
}


.archive {
  .popup {
    &__content-left {
      margin-right: 45px;
      width: 100%;
      max-width: 432px;

      form {
        svg {
          transform: translateY(-1px);
        }
      }
    }
  }

  &.new-popup {
    &.popup {
      min-height: 608px;
    }

    .card {
      padding-bottom: 0;
      &__content-wrapper {
        padding-bottom: 23px;
      }
    }
    .gallery__action-name {
      margin-top: 12px;
    }
    .thumbnail {
      max-width: 100%;
    }
  }

  .quick-view-land &__link-more {
    height: 40px;
    width: 100%;
    max-width: 192px;
    margin-left: 12px;
  }


  .popup__content-left form,
  .popup__content-right form {
    height: 40px;
    width: 201px;
    margin-right: 12px;
  }

  .make-starred {
    user-select: none;
    background: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;

    &__control {
      display: flex;
      margin: 0;
      position: relative;
      cursor: pointer;

      p {
        margin-left: 8px;
        line-height: 14px;
        font-size: 14px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.0125em;
        color: #616161;
      }
    }

    &__v {

      &:checked {
        &~svg {
          color: @broadcrumbs-link;
          stroke: @broadcrumbs-link;
        }
      }

      &~svg {
        fill: currentColor !important;
        color: #ffffff;
        stroke: #616161;
        stroke-width: 1.5px;
        width: 24px;
        height: 24px;
      }
    }

  }

}
