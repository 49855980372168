.profile {

  &__questionnaire {
    padding-top: 24px;
  }

  &__questionnaire-wrapper {
    padding: 17px 24px;
  }

  .h2 {
    margin-top: 0 !important;
    padding-bottom: 4px !important;
    font-weight: bold;
    font-size: 16px !important;
    line-height: 19px !important;
    color: @text-color-grey;
  }

  &__form-container-input {
    display: flex;
    flex-wrap: wrap;

    .profile__form-item {
      width: 50%;

      &:nth-child(even) {
        padding-left: 12px;
      }

      &:nth-child(odd) {
        padding-right: 12px;
      }

      &:last-child {
        width: 100%;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__form-item {
    margin-top: 22px;

    &_bottom {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__form-label {
    width: 100%;
    margin-bottom: 0;
    position: relative;

    &_checkbox {
      cursor: pointer;

      input:checked+.profile__form-checkbox-span {
        background: @accent-color;

        &:after,
        &:before {
          display: block;
        }
      }
    }

    &.error {
      .profile__form-label-header-error {
        display: block;
      }

      .profile__form-input {
        border-color: @font-error;
      }

      .select2-container--default .select2-selection--single {
		    border-color: @font-error !important;
		  }
    }
  }

  &__form-label-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
  }

  &__form-label-header-title,
  &__form-label-header-error {
    margin-bottom: 0;
    font-size: 12px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.0025em;
    font-weight: normal;
  }

  &__form-label-header-title {
    color: @date-color;
  }

  &__form-label-header-error {
    color: @font-error;
    display: none;
  }

  &__form-input {
    width: 100%;
    padding: 12px 16px 14px;
    background: @bg-white;
    border: 1px solid @border-color-new;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.0025em;
    color: @text-color-grey;
    outline: none;

    &_checkbox {
      width: 0;
      height: 0;
      visibility: hidden;
      overflow: hidden;
    }

    &_textarea {
      resize: vertical;
      height: 112px;
      position: relative;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: @bg-white;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #E5E5E5;
        border-radius: 4px;
        outline: none;
      }

      &::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.0025em;
        color: @date-color;
      }
    }
  }

  &__form-item-count {
    position: absolute;
    bottom: 1px;
    right: 24px;
  }

  &__form-item-resize {
    height: 0px;
    width: 0px;
    border-top: 9px solid #fff;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    transform: rotate(-45deg);
    position: absolute;
    bottom: 0px;
    right: -4px;
    pointer-events: none;
    z-index: 1;
    border-radius: 4px;

    &:after {
      content: "";
      height: 0;
      width: 0;
      border-top: 6px solid @date-color;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      transform: rotate(0deg);
      position: absolute;
      bottom: 9px;
      right: -6px;
      pointer-events: none;
      z-index: 2;
      border-radius: 4px;
    }
  }

  &__form-item-count-text {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    text-align: right;
    letter-spacing: 0.0025em;
    color: @date-color;
    background: @bg-white;
    padding: 0px 8px;
  }


  &__form-checkbox-label {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: @text-color-grey;
    max-width: 328px;
    padding-left: 31px;
  }

  &__form-checkbox-span {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    height: 16px;
    width: 16px;
    border: 2px solid @accent-color;
    border-radius: 2px;

    &:after {
      display: none;
      content: "";
      position: absolute;
      top: 5px;
      left: 3px;
      width: 10px;
      height: 2px;
      border-radius: 2px;
      background: @bg-white;
      transform: rotate(-55deg);
    }

    &:before {
      display: none;
      content: "";
      position: absolute;
      top: 6px;
      left: 1px;
      width: 6px;
      height: 2px;
      border-radius: 2px;
      background: @bg-white;
      transform: rotate(45deg);
    }
  }

  &__form-item-send {
    padding-left: 16px;
  }

  &__form-item-send-btn {
    background: @accent-color;
    border-radius: 4px;
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
  }

  &__form-item-send-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.0025em;
    color: @addable-menu-link;
    margin-bottom: 0;
  }

  .select2 {
    width: 100% !important;
  }

  .select2-container--default .select2-selection--single {
    border: 1px solid @border-color-new  !important;
    box-sizing: border-box;
    border-radius: 4px !important;
    height: 40px;
    display: flex;
    align-items: center;
  }

  .select2-container--default .select2-selection--single .select2-selection__arrow b {
    display: none;
  }

  .select2-container--default .select2-selection--single .select2-selection__rendered {
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    display: flex;
    align-items: center;
    letter-spacing: 0.0025em;
    color: @text-color-grey;
  }
}

.select2-dropdown {
  background: @bg-white  !important;
  border: 1px solid @accent-color  !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  transform: translateY(-40px);
}

.select2-search--dropdown {
  padding: 4px 0px 0px !important;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  outline: none !important;
  border: none !important;
  border-bottom: 1px solid @accent-color  !important;
}

.select2-results__option--selectable {
  color: @text-color-grey  !important;
  border-radius: 4px;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: @bg-white  !important;
  color: @accent-color  !important;
}

.select2-results {
  max-height: 100px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: @bg-white;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #E5E5E5;
    border-radius: 4px;
    outline: none;
  }
}

.select2-container--default .select2-results__option--selected {
  background: @bg-white  !important;
  color: @broadcrumbs-link  !important;
}
