.slider {
	&__cemetery-item-menu {
		border: none !important;
	}
	&__cemetery-next-btn,
	&__cemetery-prev-btn {
		background: #ffffff !important;
		border: 2px solid #ffffff !important;
	}
	&__cemetery-next-btn:hover,
	&__cemetery-prev-btn:hover {
		background: @text-color !important;
		border: 2px solid @text-color !important;
	}
	&__cemetery-next-btn:hover,
	&__cemetery-prev-btn:hover {
	    border: 2px solid @text-color !important;
	}

	&__cemetery-next-btn:hover .icon-arrow-left,
	&__cemetery-next-btn:hover .icon-arrow-right,
	&__cemetery-prev-btn:hover .icon-arrow-left,
	&__cemetery-prev-btn:hover .icon-arrow-right {
	    stroke: #fff !important;
	}

	&__cemetery-next-btn .icon-arrow-left,
	&__cemetery-next-btn .icon-arrow-right,
	&__cemetery-prev-btn .icon-arrow-left,
	&__cemetery-prev-btn .icon-arrow-right {
	    flex: 0 1 6px  !important;
	    width: 6px !important;
	    height: 10px !important;
	    stroke: @text-color !important;
	    fill: transparent;
	}
}