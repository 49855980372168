.social {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &--footer {
    grid-area: social;
    justify-content: flex-start;
    align-self: flex-start;
    margin-top:5px;
  }
}

.social__header {
  padding: 0;
  margin: 0 8px 0 0;
  color: @accent-color-dark;
  font-size: 1.3rem;
  line-height: 1.8rem;
}

.social__list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.social__item {
  margin: 0 10px 0 0;
}

.social__link {
  display: block;
}
