.vue-filter {

	&__search-wrapper {
		position: relative;
	}
	
	&__search-input {
		outline: none;
		border: none;
		width: 100%;
		height: 48px;
		padding: 13px 77px 11px 16px;

		&:placeholder {
			font-weight: @font-normal;
			font-size: 13px;
			line-height: 16px;
			color: @date-color;
		}
	}

	&__search-icon {
		width: 18px;
		height: 18px;
		position: absolute;
		right: 19px;
		top: 16px;
		fill: @bg-black-light;
	}
}