.new-table {
  width: 100%;
  // max-width: 912px;
  &__container {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 4px;
    background: #FFFFFF;
    overflow: hidden;
  }
  .table__thead {
    th {
      border-bottom: 1px solid #F9F9F9;
    }
  }

  .table__tbody {
    .table__tr {
      &:hover {
        background-color: #ffffff;
      }
    }
  }

  .table__svg {
    fill: @broadcrumbs-link;
  }

  ._vm {
    vertical-align: middle
  }

  ._vt {
    vertical-align: top
  }

  ._tc {
    text-align: center
  }

  ._nopadding {
    padding: 0
  }

  .muted,
  .name-card__subtitle {
    color: gray;
  }

  ._gray {
    color: #bdbdbd
  }

  .make-starred {
    user-select: none;
    &__control {
      display: block;
      margin: 0;
      position: relative;
      cursor: pointer
    }
    &__v {
      opacity: 0;
      position: absolute;
      z-index: -1;
      width: 1px;
      height: 1px;
      clip: rect(0, 0, 0, 0);
      &~svg {
        fill: currentColor !important;
        color: @border-color;
      }
      &:checked~svg {
        color: @broadcrumbs-link;
      }
      &--border-bottom1 {
        border-bottom: 1px solid #d8d8d8
      }
    }
  }
}

