.map {
  padding: 60px 0 0;
}

.map .container {
  position: relative;
  @media (min-width: @desktop) {
    position: static;
  }
}
.map__header {
  margin: 0 0 34px;
}
.map__content {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
  position: relative;
  &-header {
    position: absolute;
    top: 0;
    left: 0;
    height: 56px;
    width: 100%;
    //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    z-index: 10;
  }
  @media (min-width: @desktop) {
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-columns: 360px 1fr;
    grid-column-gap: 0px;
    grid-template-areas: "search filters" "map map";
  }
}
.map__wrapper {
  //height: 336px;
	height: 768px;
	z-index: 1;
  grid-area: map;
  div {
    border: none !important;
  }
  &:focus {
    outline: none;
  }
  &.full-map {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
  .gm-style .gm-style-iw-c {
    padding: 0 !important;
  }
  .gm-style-iw-d {
    overflow: auto !important;
  }
  button.gm-ui-hover-effect {
    display: none !important;
  }
  * {
    &:focus {
      outline: none;
    }
  }
  @media (min-width: @tablet) {
    height: 384px;
  }
  @media (min-width: @desktop) {
    height: 624px;
  }
}
.map-pdf {
  &__img {
    position: absolute;
    z-index: 1;
    top: 101px;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.map-pdf-svg {
  &__wrapper {
    position: absolute;
    top: 0;
    left: 0;
    background: @bg-white;
    z-index: 10;
  }
}

.map.full-map {
  position:relative;
  z-index:10000;
}
.map .container.full-map {
  position:fixed;
  width:100%;
  height:100%;
  top:0px;
  left:0px;
  z-index:1000;
  max-width: initial;
  padding: 0px;
}
.map .container.full-map .map__header {
  display:none;
}
.map.full-map .map__wrapper {
  height:calc(100vh - 56px);
}
