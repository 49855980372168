.table {
  &__tbody--group {
    &:hover {
      background: @bg-white !important;
      td {background: @bg-white !important;}
    }
  }
  &__thead {
   .table__tr {
      position: relative;

			.table__td--fast-see {
				display: none !important;
			}
      
      &:hover { box-shadow: none;}
    }
  }

  &__th {
    padding: 15px 16px;
    background: @bg-white;
    text-transform: none;
    border: 2px solid @bg-white;
    height: 52px;
    white-space: nowrap;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: @text-color-grey;
  }
  &__td {
    position: relative;
    padding: 15px 16px;
    font-size: 14px;
    color: @text-color-grey;
    vertical-align: top !important;
    border: 0;
    line-height: 1.3;
  }
  &__tr {
    position: relative;
    &:nth-child(20n) {
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: rgb(219, 174, 99, 55%);
      }
    }
    &:last-child {
      &:after {display: none;}
    }

  
    &:hover { 
      box-shadow: 0px 0px 5px 0px rgb(219, 174, 99, 55%);
      
      .table__td {
        background: @bg-white;
      }
      .name-card-fast-see {
        display: flex;
      }
      .table__td--fast-see {
        background: @bg-white;
        box-shadow: 2px 2px 6px rgba(128, 95, 23, 0.1);
        border-radius: 0px 4px 4px 0px;
      }
    }
  }
  &__th-text--location-and-year {
    font-weight: normal;
    padding-left: 19px;
    span {
      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-left: 5px;
      }
    }
  }
  .table--separated .table__td {
    border-bottom: 1px solid #F6F6F6;
  }

  .table--separated .table__thead .table__th {
    border-bottom: 1px solid #F6F6F6;
  }

  .table--separated {
    border-top: 1px solid #F6F6F6;
  }

 
  &__td--fast-see {
    cursor: pointer;
    position: absolute;
    overflow: hidden;
    height: 0px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
  }
  &__td--text-align-center {
    text-align: center
  }

  &__td--popover {
    position: relative
  }
  ._tl {
  text-align: left;
}

}

// .storage-table__row {
//     height: 55px;
//     max-height: 55px;
//     cursor: pointer;
//     overflow: hidden;
// }

.storage-table__row:hover .table__hidden-icon {
    fill: @broadcrumbs-link !important
}

.storage-table__cell-name {
    padding: 0 !important
}

.storage-table__cell-name-link {
    box-sizing: border-box;
    width: 350px;
    // height: 44px;
    padding: 11px 16px;
    overflow: hidden;
    // max-height: 55px;
    display: block;
}

.storage-table__cell-more {
    padding: 0 !important
}

.storage-table__open-popup-btn {
    display: inline-block;
    padding: 10px 10px 10px 5px;
    margin-right: 19px
}