.translate-section {
  &--full {
    overflow: initial;
    max-height: none;
    padding-bottom: 24px;
  }

  &__translated {
    flex: 1 1;
    order: 2;
    line-height: 24px;
    max-width: 372px;
    font-size: 14px;
    line-height: 24px;
  }

  &__original {
    flex: 1 1 130px;
    max-width: 180px;
    text-align: right;
    color: #363636;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }

  &__row {
    &+.card__row {
      margin-top: 7px;
    }
  }

  &__item {
    counter-increment: none;

    &:not(&--heading) {
      counter-increment: a;
    }

    //&--no-counter {
    //  &::before {
    //    opacity: 0 !important;
    //  }
    //}

    &--heading {
      color: gray;
      font-size: 14px;

      &::before {
        opacity: 0 !important;
      }

      span {
        text-align: center;
        display: block;
        width: 280px;
        font-style: italic;
      }

      .translate-section__original {
        color: @date-color;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        font-weight: 500;
      }
    }

    .translate-section__translated {
      color: #616161;
    }
  }

  &__heading {
    .translate-section__translated {
      color: @date-color  !important;
      font-weight: 500 !important;
    }
  }
}
