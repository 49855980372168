.filter-horizontal {
  .vue-filter {
		position: relative;
		top: 0;

		&__filter-wrapper {
			overflow-x: hidden;
			position: absolute;
			z-index: 1;
			width: 100%;
			background-color: @color-white;
    	display: none;
			box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
			border-radius: 0px 0px 4px 4px;
		}

    &.show {
    	display: block;
    }
    &__form-wrapper {
    	position: relative;
    }
    &__form {
      width: 100%;
      display: flex;
      //justify-content: space-between;
      border: 1px solid @border-color-new;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
      border-radius: 0px 0px 4px 4px;
    }

    &__item {
      min-width: 282px;
    }

    &__list-wrapper {
      max-height: 456px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: @bg-white;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: @bg-scroll;
        border-radius: 4px;
        outline: none;
      }
    }

    &__title {
      font-weight: @font-medium;
      font-size: 13px;
      line-height: 20px;
      color: @text-color-grey;
      padding: 10px 24px;
      border-bottom: 1px solid @border-color-new;
      margin-bottom: 0;
    }

    &__more-btn {
			cursor: pointer;
    	position: absolute;
    	right: 24px;
    	top: 10px;
    	font-weight: 500;
			font-size: 13px;
			line-height: 20px;
			color: @accent-color;
			border: none;
			background: none;
			outline: none;
			padding-right: 28px;

			&.active {
				padding-left: 28px;
				padding-right: 0;

				&:before,
				&:after {
					right: auto;
					left: 8px;
				}
				&:before {
					transform: rotate(-45deg);
				}
				&:after {
					transform: rotate(45deg);
				}
			}

			&:before,
			&:after {
				content: '';
				position: absolute;
				right: 8px;
				width: 8px;
				height: 2px;
				border-radius: 50%;
				background-color: @bg-accent;
			}

			&:before {
				top: 6px;
				transform: rotate(45deg);
			}
			&:after {
				top: 11px;
				transform: rotate(-45deg);
			}
    }

    &__daterange-wrapper {
      padding-top: 20px;
    }

    &__daterange-input {
      width: 105px;
    }

    &__daterange-container {
      display: flex;
      justify-content: center;
    }
  }
}
